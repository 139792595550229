import { useState, useEffect } from 'react';
import { api } from '../services/api';
import { useUserStore } from '../store/userStore';
import { toast } from 'react-toastify';

interface UseStoreResult {
    rewards: any[];
    rewardHistory: any[];
    loading: boolean;
    currentPage: number;
    totalPages: number;
    showModal: boolean;
    lessPoints: boolean;
    selectedRewardId: number;
    tab: string;
    selected: number;
    setShowModal: (showModal: boolean) => void;
    setLessPoints: (lessPoints: boolean) => void;
    setLessPointError: (lessPointError: string) => void;
    handleRedeemReward: (rewardId: number) => void;
    showModalPopup: (rewardId: number) => void;
    handlePageChange: (page: number) => void;
    setTab: (tab: string) => void;
    setSelected: (user: any) => void;
    user: any;
    rewardHistoryLoading: boolean;
    street: string;
    setStreet: (street: string) => void;
    addedAddresses: { id: number; address: string }[];
    showInputAddress: boolean;
    addressId: number;
    addressRelatedError: string;
    setAddressRelatedError: (error: string) => void;
    setAddressId: (addressId: number) => void;
    setAddedAddresses: (addedAddresses: { id: number; address: string }[]) => void;
    setShowInputAddress: (showInputAddress: boolean) => void;
    setCity: (city: string) => void;
    setCountry: (country: string) => void;
    setState: (state: string) => void;
    setZip: (zip: string) => void;
    city: string;
    country: string;
    state: string;
    zip: string;
}

const useStore = (): UseStoreResult => {
    const [tab, setTab] = useState('available')
    const [rewards, setRewards] = useState([])
    const [rewardHistory, setRewardHistory] = useState([])
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [selectedRewardId, setSelectedRewardId] = useState(-1)
    const [lessPoints, setLessPoints] = useState(false)
    const [lessPointError, setLessPointError] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [rewardHistoryLoading, setRewardHistoryLoading] = useState(false)
    const [street, setStreet] = useState<string>('');
    const [addedAddresses, setAddedAddresses] = useState<{ id: number; address: string }[]>([]);
    const [showInputAddress, setShowInputAddress] = useState<boolean>(true);
    const [addressId, setAddressId] = useState<number>(-1);
    const [addressRelatedError, setAddressRelatedError] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [state, setState] = useState<string>('');
    const [country, setCountry] = useState<string>('United States');
    const [zip, setZip] = useState<string>('');

    const [selected, setSelected] = useState<number>(-1);
    const itemsPerPage = 8;

    const { user, setUser } = useUserStore();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            setRewardHistoryLoading(true)
            try {
                await Promise.all([fetchRewards(), fetchRedemptionHistory(), fetchUserAddresses()]);
                setTotalPages(Math.ceil(rewardHistory.length / itemsPerPage))
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            finally {
                setLoading(false)
                setRewardHistoryLoading(false)
            }

        };
        fetchData();

    }, []);

    useEffect(() => {
        fetchRedemptionHistory();
    }, [currentPage]);

    useEffect(() => {
        if (selected !== -1) {
            setLoading(true)
            fetchRedemptionHistory();
            // setTab('history')
        }
    }, [selected]);


    const fetchRewards = async () => {
        try {
            const response = await api.rewards.getRewards()
            setRewards(response)
        } catch (error) {
            console.error('Error fetching rewards:', error)
        } 
    }

    const fetchUserAddresses = async () => {
        try {
            const response = await api.rewards.getUserAddresses()
            let addresses = response.map((address: any) => parseAddress(address))
            setAddedAddresses(addresses)
            if (addresses.length > 0) {
                setShowInputAddress(false)
                setAddressId(response[0].id)
            }
        } catch (error) {
            console.error('Error fetching user addresses:', error)
        }
    }

    const parseAddress = (address: any) => {
        return {
            id: address.id, address: `${address.street}, ${address.city}, ${address.state}, ${address.country}`
        };
    }

    const fetchRedemptionHistory = async () => {
        try {
            const response = await api.rewards.getRedemptionHistory(currentPage, itemsPerPage, selected)
            setRewardHistory(response.results)
            setTotalPages(Math.ceil(response.count / itemsPerPage))
        } catch (error) {
            console.error('Error fetching redemption history:', error)
        } finally {
            setLoading(false)
        }
    }


    const handleRedeemReward = async (rewardId: number) => {
        setLoading(true)
        setAddressRelatedError(``)
        try {
            let address_id = addressId
            if ((address_id == -1) && (street.trim() == '' || city.trim() == '' || state.trim() == '' || country.trim() == '' || zip.trim() == '')) {
                console.log("ADDRESS ID", address_id)
                setAddressRelatedError('Please fill in all the fields.')
                toast.error('Please fill in all the fields.')
                return
            }

            if (isNaN(parseInt(zip.trim(), 10))) {
                toast.error('Zip code should be a number.')
                return
            }

            setShowModal(false)
            if (address_id == -1) {
                // latitude and longitude are set to 0
                const response = await api.rewards.addAddress(street, city, state, zip, country, 0, 0, "shipping")
                address_id = response.id
                setAddressId(address_id)
            }
            await api.rewards.redeemReward(rewardId, address_id)
            fetchRewards()
            fetchRedemptionHistory()
            fetchUserAddresses()
            const reward: any = rewards.find((reward: any) => reward.id === rewardId)
            setUser({ ...user, balance: user.balance - reward.points })
            toast.success('Reward redeemed successfully')
        } catch (error) {
            toast.error('An error occurred while redeeming the reward. Please try again.')
            console.error('Error redeeming reward:', error)
        }
        finally {
            setLoading(false)
        }
    }

    const showModalPopup = (rewardId: number) => {
        setSelectedRewardId(rewardId)

        const reward: any = rewards.find((reward: any) => reward.id === rewardId)
        if (user.balance < reward.points) {
            setLessPoints(true)
            toast.error(`You do not have enough points to redeem this reward. You need ${reward.points - user.balance} more points to redeem this reward.`)
        } else {
            setLessPoints(false)
        }
        setShowModal(true)
    }

    const handlePageChange = (page: number) => {
        setCurrentPage(page)
    }

    return {
        rewards,
        rewardHistory,
        loading,
        currentPage,
        lessPoints,
        totalPages,
        showModal,
        selectedRewardId,
        tab,
        handleRedeemReward,
        showModalPopup,
        handlePageChange,
        setTab,
        setShowModal,
        setLessPoints,
        setLessPointError,
        user,
        rewardHistoryLoading,
        setStreet,
        addedAddresses,
        showInputAddress,
        street,
        setAddedAddresses,
        setShowInputAddress,
        addressId,
        setAddressId,
        addressRelatedError,
        setAddressRelatedError,
        selected,
        setSelected,
        setCity,
        setCountry,
        setState,
        setZip,
        city,
        country,
        state,
        zip
    }

}

export default useStore;
