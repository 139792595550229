import React from 'react';
import ModalComp from '../../components/ModalComp';
import InputComp from '../../components/InputComp';
import ButtonComp from '../../components/ButtonComp';

interface AddCodeProps {
    isModalOpen: boolean;
    setIsModalOpen: (isOpen: boolean) => void;
    cigarCode: string;
    setCigarCode: (code: string) => void;
    loader: boolean;
    next: (skip: boolean) => void;
}

const AddCode: React.FC<AddCodeProps> = ({
    isModalOpen,
    setIsModalOpen,
    cigarCode,
    setCigarCode,
    loader,
    next
}) => {

  return (
    <>
      <ModalComp
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title='Enter Cigar Code'
      >
        {/* Cigar Code */}
        <InputComp
          label='Cigar Code'
          placeholder='Enter your cigar code'
          value={cigarCode}
          onChange={setCigarCode}
        />
        {/* Buttons */}
        <div className='block mt-4'>
          <ButtonComp onClick={() => next(false)} loading={loader}>Enter Code</ButtonComp>
        </div>
        <button
          className='block mt-4 text-secondary-gray ml-4'
          onClick={() => { next(true) }}
        >
          Or Log Non-Stoic Cigar
        </button>
      </ModalComp>
    </>
  );
};

export default AddCode;
