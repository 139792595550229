import React from 'react';
import { Link } from 'react-router-dom';

const Privacy: React.FC = () => {
    return (
        <div className="w-full min-h-screen relative bg-primary-blue-80 overflow-hidden flex flex-col items-center justify-center px-4 py-8 md:py-16 text-white font-text-md-medium1">
            <img
                className="absolute md:top-0 mt-auto !mx-auto object-cover opacity-[0.02] z-0"
                alt=""
                src="/logo/logomark.svg"
            />
            <div className="w-full max-w-4xl flex flex-col items-center justify-start gap-8 z-10">
                <Link to="/" className="cursor-pointer">
                    <img
                        className="w-16 h-16 md:w-24 md:h-24"
                        loading="lazy"
                        alt="Home"
                        src="/logo/logomark-1.svg"
                    />
                </Link>
                <h1 className="text-3xl md:text-5xl font-medium mb-6">Privacy Policy</h1>

                <div className="w-full bg-primary-blue-90 rounded-2xl p-6 text-sm md:text-base">
                    <h2 className="text-xl font-semibold mb-4">1. Information Collection</h2>
                    <p className="mb-4 text-greyscale-301">
                        We collect personal information that you provide directly to us when using our services.
                    </p>

                    <h2 className="text-xl font-semibold mb-4">2. Use of Information</h2>
                    <p className="mb-4 text-greyscale-301">
                        We use the information we collect to provide, maintain, and improve our services, as well as to communicate with you.
                    </p>

                    <h2 className="text-xl font-semibold mb-4">3. Information Sharing</h2>
                    <p className="mb-4 text-greyscale-301">
                        We do not sell or share your personal information with third parties except as described in this policy.
                    </p>

                    <h2 className="text-xl font-semibold mb-4">4. Data Security</h2>
                    <p className="mb-4 text-greyscale-301">
                        We implement appropriate security measures to protect your personal information from unauthorized access or disclosure.
                    </p>

                    <h2 className="text-xl font-semibold mb-4">5. Your Rights</h2>
                    <p className="mb-4 text-greyscale-301">
                        You have the right to access, correct, or delete your personal information. Contact us for any privacy-related requests.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Privacy;
