import React from 'react';
import ModalComp from './ModalComp';
import ButtonComp from './ButtonComp';

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  title?: string;
  message?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  loading?: boolean;
  children?: React.ReactNode;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title = 'Confirm Action',
  message = 'Are you sure you want to perform this action?',
  confirmButtonText = null,
  cancelButtonText = null,
  loading = false,
  children = null
}) => {
  return (
    <ModalComp title={title} isOpen={isOpen} onClose={onClose}>
      <p className="text-white mb-4">{message}</p>
      {children}
      <div className="flex justify-end gap-4">
        {cancelButtonText && (
          <ButtonComp onClick={onClose} loading={loading}>
            {cancelButtonText}
          </ButtonComp>
        )}
        {confirmButtonText && (
          <ButtonComp onClick={onConfirm} loading={loading}>
            {confirmButtonText}
          </ButtonComp>
        )}
      </div>
    </ModalComp>
  );
};

export default ConfirmationModal;