import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Ban } from 'lucide-react';
import ButtonComp from '../../components/ButtonComp';

interface NotFoundProps {
    title: string;
    description?: string;
}

const NotFound: React.FC<NotFoundProps> = ({ title, description }) => {
    const navigate = useNavigate();

    return (
        <div className="w-full min-h-screen relative bg-primary-blue-80 overflow-hidden flex flex-col items-center justify-center px-4 py-8 md:py-16 text-center text-white font-text-md-medium1">
            <img
                className="absolute md:top-0 mt-auto !mx-auto object-cover opacity-[0.02] z-0"
                alt=""
                src="/logo/logomark.svg"
            />
            <div className="w-full max-w-md flex flex-col items-center justify-start gap-8 z-10">
                <img
                    className="w-16 h-16 md:w-24 md:h-24"
                    loading="lazy"
                    alt=""
                    src="/logo/logomark-1.svg"
                />
                <div className="flex flex-col items-center justify-start gap-4 w-full">
                    <h1 className="text-3xl md:text-5xl font-medium">
                        Find your way home
                    </h1>
                    <p className="text-sm md:text-base text-greyscale-301">
                        The page you're looking for doesn't exist or has been moved.
                    </p>
                </div>
                <div className="w-full flex flex-col items-center justify-start gap-4">
                    <ButtonComp onClick={() => navigate('/')}>Go back Home</ButtonComp>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
