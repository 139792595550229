import React from 'react'
import { Plus } from 'lucide-react'
import ButtonComp from './ButtonComp'
import InputComp from './InputComp'

interface MemberHeaderType {
    title?: string
    showNavigation?: boolean
    children?: any,
    onAddPress?: any,
    onSearch?: any
}

const MemberHeader: React.FC<MemberHeaderType> = ({
    title = 'My Account',
    showNavigation = true,
    children,
    onAddPress,
    onSearch
}) => {
    const [seachValue, setSearchValue] = React.useState('')
    const handleSearch = () => {
        if (onSearch) {
            onSearch(seachValue)
        }
    }
    return (
        <header className='bg-primary-blue-80 mb-4'>
            <div className='p-4'>
                {/* Mobile layout */}
                <div className='sm:hidden space-y-4'>
                    <div className='flex items-center justify-between'>
                        <div className='flex items-center space-x-2'>
                            <img
                                src='/icons/cigar.svg'
                                alt='Cigar Icon'
                                className='w-12 h-12'
                            />
                            <h1 className='text-xl text-white font-semibold'>{title}</h1>
                        </div>
                        <div className='hidden md:block'>
                            <ButtonComp
                                fullWidthOnMobile={false}
                                backgroundColor='bg-transparent text-secondary-gray border-1 border-secondary-gray'
                                textColor='text-secondary-gray'
                                onClick={onAddPress}
                            >
                                <Plus size={16} /> <span className='ml-1'>Add Cigar</span>
                            </ButtonComp>
                        </div>
                        {/* Circle button */}
                        <button className='border border-secondary-gray w-10 h-10 rounded-full flex items-center justify-center' onClick={onAddPress}>
                            <Plus size={16} className='text-secondary-gray' />
                        </button>
                    </div>
                    <div className='flex items-center space-x-4'>
                        {children}
                        {showNavigation && (
                            <InputComp
                                placeholder='Search'
                                onEnter={handleSearch}
                                onChange={setSearchValue}
                            />
                        )}
                    </div>
                </div>

                {/* Desktop layout */}
                <div className='hidden sm:flex items-center justify-between'>
                    <div className='flex-1'>
                        <h1 className='text-xl text-left text-white font-semibold'>
                            {title}
                        </h1>
                    </div>
                    <div className='flex items-center space-x-4 justify-end'>
                        {children}
                        {showNavigation && (
                            <InputComp
                                placeholder='Search'
                                onEnter={handleSearch}
                                onChange={setSearchValue}
                            />
                        )}
                        <div>
                            <ButtonComp
                                fullWidthOnMobile={false}
                                backgroundColor='bg-secondary-gray'
                                textColor='text-primary-blue-80'
                                onClick={onAddPress}
                            >
                                <Plus /> <span className='ml-2'>Log Cigar</span>
                            </ButtonComp>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default MemberHeader
