import React from 'react';

interface JournalEntry {
  id: number;
  title: string;
  points: number;
  created_at: string;
  picture: string;
  experience_note?: string;
  description: string;
}

interface TimelineProps {
  entries: JournalEntry[];
}
const TimelineComp: React.FC<TimelineProps> = ({ entries }) => {
  console.log("Timeline Entries", entries);
  return (
    <div className="p-4 max-w-4xl mx-auto">
      <div className="relative border-l border-gray-200">
        {entries.map((entry, index) => (
          <div key={entry.id} className="mb-8 ml-4">
            <div className="absolute -left-1.5 w-3 h-3 bg-[#94BECC] rounded-full"></div>
            <div className="flex items-start space-x-4">
              <img
                src={entry.picture}
                alt={entry.title}
                className="w-16 h-16 rounded-lg object-cover"
              />
              <div>
                <h3 className="text-lg font-bold">{entry.title}</h3>
                <p className="text-sm text-gray-500">

                  {entry.experience_note ? "By" : ""} {entry.description}</p>
                <p className="text-sm text-gray-500">{new Date(entry.created_at).toLocaleDateString()}</p>
                <p className="mt-1 text-sm">{entry.experience_note}</p>
                <span className="inline-block bg-[#94BECC] text-blue-800 text-xs font-medium mt-2 px-2 py-1 rounded-full">
                  Points: {entry.experience_note ? entry.points : -entry.points}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TimelineComp
