import React, { useState, ReactNode } from 'react'

interface ModalType {
  title?: string
  isOpen: boolean
  onClose: () => void
  children: ReactNode
}

const ModalComp: React.FC<ModalType> = ({ title, isOpen, onClose, children }) => {
  if (!isOpen) return null

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 grid place-items-center z-[500] overflow-y-auto'>
      <div className='bg-primary-blue-80 rounded-2xl p-8 md:w-1/2 w-full m-4'>
        <div className='flex justify-between items-center'>
          <h1 className='text-white text-2xl font-semibold'>{title}</h1>
          <button
            onClick={onClose}
            className='text-gray-500 hover:text-gray-700 transition-colors'
          >
            <svg
              className='w-6 h-6'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M6 18L18 6M6 6l12 12'
              />
            </svg>
          </button>
        </div>
        <div className='mt-4'>{children}</div>
      </div>
    </div>
  )
}

export default ModalComp
