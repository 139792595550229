import React, { useState } from 'react';

const RangeSelectionComp: React.FC<{
  disabled?: boolean;
  onChange: (value: number) => void;
  defaultValue?: number;
}> = ({ disabled = false, onChange, defaultValue = 3 }) => {
  const [value, setValue] = useState(defaultValue);

  const handleClick = (num: number) => {
    if (!disabled) {
      setValue(num);
      onChange(num);
    }
  };

  return (
    <div className="w-full max-w-md mx-auto p-2 bg-primary-blue-80 rounded-lg">
      <div className="flex justify-between mb-2">
        {[1, 2, 3, 4, 5].map((num) => (
          <button
            key={num}
            onClick={() => handleClick(num)}
            className={`w-8 h-8 flex items-center justify-center rounded-2xl ${
              value === num ? 'bg-secondary-gold text-gray-800' : 'bg-white text-gray-800'
            }`}
          >
            {num}
          </button>
        ))}
      </div>
      {/* <div className="flex justify-between mt-2">
        <span className="text-white">Very dissatisfied</span>
        <span className="text-white">Neutral</span>
        <span className="text-white">Very satisfied</span>
      </div> */}
    </div>
  );
};

export default RangeSelectionComp;
