import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../services/api';
import { useUserStore } from '../store/userStore';
import { getErrorMessage } from '../utils/error';
import { toast } from 'react-toastify';
import { Address } from "../types/address";

const useAuth = () => {
  const [loading, setLoading] = useState(false);
  const [resetCodeSent, setResetCodeSent] = useState(false);
  const [codeValid, setCodeValid] = useState(false);
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const { setUser } = useUserStore();

  const redirectToDashboard = () => {
    const addCigar = sessionStorage.getItem('addcigar');
    if (addCigar) {
      sessionStorage.removeItem('addcigar');
      navigate('/dashboard?addcigar=true');
    } else {
      navigate('/dashboard');
    }
  }
  const login = async (credentials: any) => {
    setLoading(true);

    try {
      const response: any = await api.auth.login(credentials);
      setUser(response.user);
      redirectToDashboard();
    } catch (err: any) {
        toast.error(getErrorMessage(err)); 
    } finally {
      setLoading(false);
    }
  };

  const register = async (first_name: string, last_name: string, email: string, password: string, location: Address) => {
    try {
      const credentials = {
        first_name: first_name,
        last_name: last_name,
        email: email,
        password: password,
        location: {
          street: location?.street,
          city: location?.city,
          state: location?.state,
          zip_code: location?.zip_code,
          country: location?.country,
          latitude: location?.latitude,
          longitude: location?.longitude,
          type: "user",
        }
      }
      setLoading(true);
      const response = await api.auth.register(credentials);
      navigate('/login'); 
    } catch (err: any) {
        toast.error(getErrorMessage(err)); 
    } finally {
      setLoading(false);
    }
  };

  const sendResetPasswordCode = async (email: string) => {
    setLoading(true);

    try {
      await api.auth.sendOTP({email});
      setResetCodeSent(true);
      setStep(2);
    } catch (err: any) {
        toast.error(getErrorMessage(err));
    } finally {
      setLoading(false);
    }
  };

  const validateResetPasswordCode = async (data: any) => {
    setLoading(true);

    try {
      await api.auth.validateOTP(data);
      setCodeValid(true);
        setStep(3);
    } catch (err: any) {
      toast.error(getErrorMessage(err));
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async (data: any) => {
    setLoading(true);

    try {
      await api.auth.updatePassword(data);
      navigate('/login');
    } catch (err: any) {
      toast.error(getErrorMessage(err));
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    setLoading(true);

    try {
      await api.auth.logout();
      setUser(null);
      navigate('/login');
    } catch (err: any) {
      toast.error(getErrorMessage(err));
    } finally {
      setLoading(false);
    }
  };

  const googleSignIn = async (token: any) => {
    setLoading(true);

    try {
      const response: any = await api.auth.googleSignIn(token);
      console.log('Google Sign-In response:', response);
      setUser(response.data.user);
      console.log('User set in store:', response);
      redirectToDashboard();
    } catch (err: any) {
      console.error('Google Sign-In error:', err);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    login,
    register,
    sendResetPasswordCode,
    validateResetPasswordCode,
    resetPassword,
    logout,
    loading,
    resetCodeSent,
    codeValid,
    step,
    googleSignIn,
    setLoading,
  };
};

export default useAuth;
