import CigarItem from "../Dashboard/CigarItem";
import { Album, X } from "lucide-react";
import React from "react";


interface CigarListProp {
    title?: string
    user: any
    topCigars: any[]
    onCigarSelect: (id: number) => void
    onClose?: () => void;
    isMobile?: boolean
}


const CigarList = ({ title = 'Top Cigars', user, topCigars, onCigarSelect, isMobile = false, onClose, }: CigarListProp) => {
    const [cigarSelected, setCigarSelected] = React.useState(0)
    if (topCigars?.length == 0) {
        return null
    }

    return (
        <div className={`${isMobile ? 'p-4 z-50 bg-primary-blue-90 overflow-y-auto z-10 pt-10 pr-4' : ''} text-white`}>

            <div className='flex items-center justify-between'>
                <h2 className='text-md font-semibold text-left text-gray-light p-4'>
                    {title}
                </h2>
                <div className='flex items-center space-x-2'>
                    <span className='text-md font-semibold text-gray-light'>{user?.user_cigars.length}</span>
                    <Album className='w-4 h-4 text-gray-light' />

                </div>
            </div>
            <div className='block mt-2 p-2'>
                {user?.user_cigars?.length > 0 && user?.user_cigars?.map((cigar: any, index: any) => (
                    <CigarItem
                        key={index}
                        {...cigar}
                        index={index}
                        length={topCigars.length}
                        selected={cigarSelected}
                        onActive={() => { onCigarSelect(cigar.id) }}
                        count={1}
                    />
                ))}
            </div>
        </div>
    )

}

export default CigarList;

