import React, { useState } from 'react'
import DashboardLayout from '../../../components/layouts/DashboardLayout'
import InputComp from '../../../components/InputComp'
import ButtonComp from '../../../components/ButtonComp'
import AccountHeader from '../../../components/Account/AccountHeader'
import FormField from '../../../components/Dashboard/Account/FormField'
import useAccount from '../../../hooks/useAccount'
import { useNavigate } from 'react-router-dom';
import { useModalStore } from '../../../store/useModalStore'
import HomeModals from '../Modals/HomeModals'

const AccountPassword: React.FC = () => {
  const {   
    loading,
    currentPassword,
    setCurrentPassword,
    newPassword,
    setNewPassword,
    repeatPassword,
    setRepeatPassword,
    handlePasswordChange } = useAccount();
  const navigate = useNavigate();
  const { modalStates, updateModalState } = useModalStore();

  return (
    <DashboardLayout title='My Account' description=''>
      <div className='mb-48 md:mb-12'>
        {/* Add cigar */}
        <AccountHeader
          title='My Account'
          onAddPress={() => updateModalState('isModalOpen', true)}
          menuSelected='password'
        />
        <HomeModals
          {...modalStates}
          setIsModalOpen={(value) => updateModalState('isModalOpen', value)}
          setIsCigarModalOpen={(value) => updateModalState('isCigarModalOpen', value)}
        />
        <div className='border border-primary-blue-90 rounded-2xl p-4'>
          <div className='block md:grid md:grid-cols-3 gap-6'>
            <FormField label="Current Password" description="Short description">
              <InputComp
                placeholder='Enter current password'
                value={currentPassword}
                onChange={setCurrentPassword}
                type='password'
              />
            </FormField>

            
            <FormField label="New Password" description="Short description">
              <InputComp
                placeholder='Enter new password'
                value={newPassword}
                onChange={setNewPassword}
                type='password'
              />
            </FormField>

            <FormField label="Repeat Password" description="Short description">
              <InputComp
                placeholder='Repeat new password'
                value={repeatPassword}
                onChange={setRepeatPassword}
                type='password'
              />
            </FormField>
          </div>
        </div>
        <div className='w-full flex justify-end md:fixed md:bottom-0 md:right-0 p-4 md:p-6'>
          <div className='w-full md:w-auto md:mr-12'>
            <ButtonComp fullWidthOnMobile={true} onClick={handlePasswordChange} loading={loading}>
              <span className="text-center">Save Changes</span>
            </ButtonComp>
          </div>
        </div>
      </div>
    </DashboardLayout>
  )
}

export default AccountPassword
