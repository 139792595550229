import React from 'react'
import { Trophy } from 'lucide-react'

interface Stat {
  label: string
  value: string
  onClick?: (index: number) => void;
}

interface StatsCardProps {
  title: string
  stats: Stat[]
  memberSince?: string,
  ranking?: number,
  onClick?: () => void
}
function getOrdinalNumber(number: number) {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const value = number % 100;
  return number + (suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0]);
}

function UserRank({ ranking }: { ranking: number }) {
  const ordinalRank = ranking ? getOrdinalNumber(ranking) : '';

  return (
    <div className='flex gap-2'>
      <Trophy size={18} className='text-yellow-500 mt-2' />
      <p className='mt-1.5'>
        {ordinalRank}
      </p>  
    </div>
  );
}
const StatsCard: React.FC<StatsCardProps> = ({ title, stats, memberSince, ranking, onClick }) => {
  return (
    <div className='bg-primary-blue-90 rounded-2xl p-4 col-span-3 min-h-auto md:h-32'>
      <div className='grid grid-cols-3 gap-4'>
        <h2 className='text-lg font-semibold'>{title}</h2>
        {memberSince && ranking && (
          <>
            <div className='flex gap-2'>
              <UserRank ranking={ranking} />
            </div>
          <p className='text-xs bg-primary-blue-80 rounded-xl pl-2 pr-2 p-1 text-gray-400'>
            Member since <span className='text-secondary-gray'>{memberSince}</span>
          </p>
          </>
        )}
      </div>
      <div className='grid grid-cols-3 gap-4'>
        {stats.map((stat, index) => (
          <div
            key={index}
            onClick={() => stat?.onClick?.(index)}
            className='cursor-pointer'
          >
            <p className='text-sm text-gray-400 text-left'>{stat.label}</p>
            <p className='text-2xl font-bold text-left'>{stat.value}</p>
            {/* <p
              className={`text-xs flex text-left ${
                stat.isPositive ? 'text-green-500' : 'text-red-500'
              }`}
            >
              {stat.change === 0 ? (
                <Minus size={14} />
              ) : stat.isPositive ? (
                <ChevronUp size={14} />
              ) : (
                <ChevronDown size={14} />
              )}{' '}
              {stat.change}%
            </p> */}
          </div>
        ))}
      </div>
    </div>
  )
}

export default StatsCard
