import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useModalStore } from '../store/useModalStore'
import useFollower from '../hooks/useFollower'
import api from '../services/api'

const useMember = () => {
    const [members, setMembers] = useState<any>([])
    const [selectedUser, setSelectedUser] = useState<any>(0)
    const [selected, setSelected] = useState<any>({})
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [loading_, setLoading_] = useState(true)
    const [searchValue, setSearchValue] = useState('')
    const itemPerPage = 10
    const fetchMembers = async () => {
        setLoading_(true)
        try {
            const response = await api.users.listAllMembers(currentPage, itemPerPage, searchValue, null)
            setMembers(response.results)
            setTotalPages(Math.ceil(response.count / itemPerPage))
            console.log("members", response)
        }
        catch (error) {
            console.log(error)
        }
        finally {
            setLoading_(false)
        }
    }

    useEffect(() => {
        fetchMembers()
    }, [])
    useEffect(() => {
        fetchMembers()
    }, [currentPage, searchValue])
    const navigate = useNavigate();
    const { modalStates, updateModalState } = useModalStore();

    const refreshData = () => {
        setSelectedUser(0)
        fetchMembers()
    }

    const onSelectUser = (id: number) => {
        setSelectedUser(members.find((member: any) => member.id === id))
    }
    const { handleAddFollowing, handleRemoveFollowing, loading } = useFollower(refreshData

    )

    const handlePageChange = (page: number) => {
        setCurrentPage(page)
    }

    return {
        members,
        selectedUser,
        selected,
        currentPage,
        totalPages,
        loading_,
        searchValue,
        fetchMembers,
        onSelectUser,
        handleAddFollowing,
        handleRemoveFollowing,
        loading,
        handlePageChange,
        setSearchValue,
        refreshData,
        updateModalState,
        modalStates,
        setSelectedUser
    }
}

export default useMember;