import React, { useState, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu, X, LogOut, BookMarked, AlertTriangle, Plus, User } from 'lucide-react';
import { useUserStore } from '../../store/userStore';
import { api } from '../../services/api';
import SpinnerComp from '../SpinnerComp';
import { Helmet } from "react-helmet";
import ToastComp from '../ToastComp';
import { NotebookPen, Users } from 'lucide-react';
import HomeModals from '../../pages/Dashboard/Modals/HomeModals'
import { useModalStore } from '../../store/useModalStore'
import ProfileUserIcon from '../../assets/profile-user.svg';

interface DashboardLayoutType {
  title?: string;
  description?: string;
  children: ReactNode;
  topBarContent?: ReactNode
}

const DashboardLayout: React.FC<DashboardLayoutType> = ({ title, description, children, topBarContent }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useUserStore(state => state.user);
  const currentPath = useLocation();
  const navigate = useNavigate();

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const toggleUserDropdown = () => setIsUserDropdownOpen(!isUserDropdownOpen);

  const handleLogout = async () => {
    setLoading(true)
    try {
      await api.auth.logout();
    } catch (_) { }
    finally {
      navigate('/login');
    }
  };

  // Add this state to control the visibility of the maintenance banner
  const [showMaintenanceBanner, setShowMaintenanceBanner] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { modalStates, updateModalState } = useModalStore();

  const handleSaveCigar = async () => {
  }

  return (
    <>
      <Helmet>
        <title>{title + ' - Stoic Cigar'}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="flex h-screen bg-gray-100">
        {/* Sidebar */}
        <div className={`bg-primary-blue-80 text-white w-24 flex flex-col justify-between py-7 px-2 absolute inset-y-0 left-0 transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} md:relative md:translate-x-0 transition duration-200 ease-in-out`}>
          <nav className="hidden md:flex flex-col">
            <Link to="/dashboard">
              <img src="/icons/cigar.svg" className="-mt-4 w-12 mx-auto" alt="MySync Logo" />
            </Link>
            <span onClick={() => updateModalState('isModalOpen', true)} className={`cursor-pointer flex mt-4 py-2.5 px-4 rounded-full w-12 h-12 ml-4 border-2 border-secondary-gray ${currentPath.pathname === '/dashboard' ? 'bg-[#1C2326] text-black' : 'bg-[#1C2326] text-black'}`}>
              <img src="/icons/plus.svg" className="w-14" alt="MySync Logo" />
            </span>
            <Link to="/dashboard" className={`flex mt-4 py-2.5 px-4 rounded-full w-12 h-12 ml-4 ${currentPath.pathname === '/dashboard' ? 'bg-secondary-gray' : ''}`}>
              <img src="/icons/home.svg" className="w-14" alt="MySync Logo" />
            </Link>
            <Link to="/dashboard/journal" className={`flex mt-2 py-2.5 px-4 rounded-full w-12 h-12 ml-4 ${currentPath.pathname === '/dashboard/journal' ? 'bg-secondary-gray' : ''}`}>
              {/* <img
                src="/icons/journal.png"
                className="w-14 filter invert"
                alt="MySync Logo"
              /> */}
              <NotebookPen className="w-12 text-[#475B61]" />
            </Link>
            <Link to="/dashboard/store" className={`flex mt-2 py-2.5 px-4  rounded-full w-12 h-12 ml-4 ${currentPath.pathname === '/dashboard/store' ? 'bg-secondary-gray' : ''}`}>
              <img src="/icons/store.svg" className="w-12" alt="MySync Logo" />
            </Link>
            <Link to="/dashboard/members" className={`flex mt-2 py-2.5 px-4 rounded-full w-12 h-12 ml-4 ${currentPath.pathname === '/dashboard/members' ? 'bg-secondary-gray' : ''}`}>
              <Users className="w-12 text-[#475B61]" />
            </Link>

          </nav>

          {/* User section */}
          <div className="relative hidden md:block">
            <Link to='/dashboard/account' className={`cursor-pointer flex  w-10 h-10 ml-4 ${currentPath.pathname === '/dashboard' ? 'bg-[#1C2326] text-black' : 'bg-[#1C2326] text-black'}`}>
              <img src={user?.profile_picture || ProfileUserIcon} className="!w-12 rounded-full" alt="User Icon" />
            </Link>
            {/* <Link to='/dashboard/account' className="flex mt-2 py-2.5 px-4 rounded-full w-12 h-12 ml-4 border-2 border-secondary-gray rounded-full">
              <User className="w-14 text-white" />
            </Link> */}
            <span className="flex flex-col items-center justify-center rounded-full w-16 h-16 cursor-pointer">
              {
                !loading ? (<LogOut className="w-12 text-[#475B61]" onClick={handleLogout} />) : <SpinnerComp />
              }
            </span>
          </div>
        </div>


        {/* Bottom bar for mobile */}
        <div className="md:hidden fixed bottom-0 left-0 right-0 bg-primary-blue-90 text-white flex justify-around py-1.5 z-10">
          <Link to="/dashboard" className={`flex flex-col items-center justify-center rounded-full w-16 h-16 ${currentPath.pathname === '/dashboard' ? 'bg-secondary-gray' : ''}`}>
            <img src="/icons/home.svg" className="w-8 mt-1" alt="MySync Logo" />
          </Link>
          <Link to="/dashboard/journal" className={`flex flex-col items-center justify-center rounded-full w-16 h-16 ${currentPath.pathname === '/dashboard/journal' ? 'bg-secondary-gray' : ''}`}>
            <BookMarked className="w-12 text-[#475B61]" />
          </Link>
          <Link to="/dashboard/store" className={`flex flex-col items-center justify-center rounded-full w-16 h-16 ${currentPath.pathname === '/dashboard/store' ? 'bg-secondary-gray' : ''}`}>
            <img src="/icons/store.svg" className="w-8" alt="Cart" />
          </Link>
          <Link to="/dashboard/members" className={`flex flex-col items-center justify-center rounded-full w-16 h-16 ${currentPath.pathname === '/dashboard/members' ? 'bg-secondary-gray' : ''}`}>
            <Users className="w-10 text-[#475B61]" />
          </Link>
          <Link to='/dashboard/account' className={`flex flex-col items-center justify-center  w-10 h-10 mt-[0.8rem]`}>
            <img src={user?.profile_picture || ProfileUserIcon} className="!w-12 rounded-full" alt="User Icon" />
          </Link>
          <span className="flex flex-col items-center justify-center rounded-full w-16 h-16 cursor-pointer">
            {
              !loading ? (<LogOut className="w-12" onClick={handleLogout} />) : <SpinnerComp />
            }
          </span>
        </div>

        {/* Main content */}
        <div className="flex-1 flex flex-col overflow-hidden">
          {/* Page content */}
          <main className="bg-primary-blue-70">
            <section className="flex-1 overflow-x-hidden overflow-y-auto bg-primary-blue-80 h-screen p-4">
              <ToastComp />

              {/* Maintenance Banner */}
              {showMaintenanceBanner && (
                <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4 relative">
                  <div className="flex items-center">
                    <AlertTriangle className="w-6 h-6 mr-2" />
                    <p className="font-bold">Maintenance Notice</p>
                  </div>
                  <p className="mt-2">Our system will be undergoing maintenance on [Date]. Some features may be temporarily unavailable.</p>
                  <button
                    onClick={() => setShowMaintenanceBanner(false)}
                    className="absolute top-2 right-2 text-yellow-700 hover:text-yellow-900"
                  >
                    <X size={20} />
                  </button>
                </div>
              )}

              {children}
            </section>
          </main>
        </div>
      </div>

      <HomeModals
        {...modalStates}
        setIsModalOpen={(value) => updateModalState('isModalOpen', value)}
        setIsCigarModalOpen={(value) => updateModalState('isCigarModalOpen', value)}
        onSave={handleSaveCigar}
      />
    </>
  );
};

export default DashboardLayout;
