const cigars = [
    { label: 'Cigar 1', value: 'cigar1' },
    { label: 'Cigar 2', value: 'cigar2' },
    { label: 'Cigar 3', value: 'cigar3' },
    { label: 'Cigar 4', value: 'cigar4' },
    { label: 'Non-Stoic Cigar', value: 'non-stoic' }
]


const initialData = [
    {
      name: 'Phoenix Baker',
      cigars: 435,
      location: 'AZ',
      rank: 1,
      progress: 100,
      arrow: 'up'
    },
    {
      name: 'Monica Tucci',
      cigars: 422,
      location: 'CA',
      rank: 2,
      progress: 90,
      arrow: 'up'
    },
    {
      name: 'Johnatan Woods',
      cigars: 399,
      location: 'NY',
      rank: 3,
      progress: 75,
      arrow: 'straight'
    },
    {
      name: 'Stanislav Drull',
      cigars: 283,
      location: 'AL',
      rank: 4,
      progress: 50,
      arrow: 'down'
    },
    {
      name: 'Luana Rolfes',
      cigars: 243,
      location: 'NV',
      rank: 5,
      progress: 25,
      arrow: 'down'
    }
]

const last90Days = [
    {
      name: 'Dominic Luum',
      cigars: 435,
      location: 'AZ',
      days: 45,
      progress: 50,
      arrow: 'up'
    },
    {
      name: 'Monica Tucci',
      cigars: 422,
      location: 'CA',
      days: 109,
      progress: 75,
      arrow: 'up'
    },
    {
      name: 'Johnatan Woods',
      cigars: 399,
      location: 'NY',
      days: 67,
      progress: 50,
      arrow: 'straight'
    },
    {
      name: 'Stanislav Drull',
      cigars: 283,
      location: 'AL',
      days: 3,
      progress: 10,
      arrow: 'down'
    },
    {
      name: 'Luana Rolfes',
      cigars: 243,
      location: 'NV',
      days: 78,
      progress: 90,
      arrow: 'up'
    }
]

const topCigars = [
  {
    id: 1,
    name: 'Stoic Cigar No.5',
    timestamp: 'Just now',
    count: 5,
    location: 'Glendale, CA',
    rating: 4,
    icon: '/icons/journal.svg'
  },
  {
    id: 2,
    name: 'Stoic Cigar No.5',
    timestamp: 'Just now',
    count: 5,
    location: 'Glendale, CA',
    rating: 4,
    icon: '/icons/journal.svg'
  },
  {
    id: 3,
    name: 'Stoic Cigar No.5',
    timestamp: 'Just now',
    count: 5,
    location: 'Glendale, CA',
    rating: 4,
    icon: '/icons/journal.svg'
  },
  {
    id: 4,
    name: 'Stoic Cigar No.5',
    timestamp: 'Just now',
    count: 5,
    location: 'Glendale, CA',
    rating: 4,
    icon: '/icons/journal.svg'
  },
  {
    id: 5,
    name: 'Stoic Cigar No.5',
    timestamp: 'Just now',
    count: 5,
    location: 'Glendale, CA',
    rating: 4,
    icon: '/icons/journal.svg'
  }
]

export { cigars, initialData, last90Days, topCigars }
