import { Address } from "../types/address";

export const parseAddress = (address: any): string => {
    if (!address) {
        return "";
    }
    const { city, state } = address;
    if (city && state) {
        return `${city}, ${state}`;
    } else if (city) {
        return city;
    } else if (state) {
        return state;
    }
    return "";
};

export interface LocationType {
    name: string;
    latitude: number;
    longitude: number;
    picture?: string;
    id?: number;
}
// return list of locations
export const parseAddressesForMap = (cigars: any[]): LocationType[] => {
    console.log("CIGGG ", cigars);
    return cigars.map((cigar, index) => ({
        name: parseAddress(cigar.location),
        latitude: cigar.location.latitude,
        longitude: cigar.location.longitude,
        picture: cigar.user_profile_picture,
        id: cigar.id || index
    }));
};