import React from "react";

const TooltipComp = ({ children, text }: { children: React.ReactNode; text: string }) => {
  return (
    <div className="relative group">
      {children}
      <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-full mb-2 hidden group-hover:flex px-2 py-1 bg-gray-800 text-white text-sm rounded shadow-lg z-10 whitespace-nowrap">
        {text}
      </div>
    </div>
  );
};

export default TooltipComp;
