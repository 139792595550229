// import React from 'react'
// import ButtonComp from '../components/ButtonComp'
// import InputComp from '../components/InputComp'
// import { Link } from 'react-router-dom'

// const HomePage = () => {
//   const [firstName, setFirstName] = React.useState('')
//   const [email, setEmail] = React.useState('')
//   const [city, setCity] = React.useState('')

//   return (
//     <div className='w-full relative bg-primary-blue-80 overflow-hidden flex flex-col items-end justify-start pt-5 px-0 pb-64 box-border gap-[71px] leading-normal tracking-normal sm:gap-5 md:gap-9'>
//       {/* Header */}
//       <header className='self-stretch flex flex-row items-start justify-end py-0 px-8 box-border max-w-full text-center text-sm text-greyscale-301 font-heading-h3-medium'>
//         <div className='flex-1 flex flex-row items-start justify-between max-w-full gap-5 flex-wrap'>
//           <div className='w-full md:w-[574px] flex flex-col items-start justify-start pt-1.5 px-0 pb-0 box-border max-w-full'>
//             <div className='self-stretch flex flex-row items-start justify-between gap-5 flex-wrap'>
//               {/* Logo */}
//               <div className='overflow-hidden flex flex-row items-start justify-start gap-[4.2px]'>
//                 <div className='flex items-center'>
//                   <img
//                     className='self-stretch h-[25.2px] relative max-w-full overflow-hidden shrink-0'
//                     alt=''
//                     src='/logo/logo.svg'
//                   />
//                   <div className='flex flex-col items-center justify-center text-white -mt-1'>
//                     <div className='px-0 pb-0'>STOIC</div>
//                     <div className='px-0 pb-0'>CIGARS</div>
//                   </div>
//                 </div>
//               </div>
//               {/* Navigation */}
//               <nav className='m-0 w-full flex flex-col items-center justify-center top-0 fixed mt-8 text-gray-light'>
//                 <nav className='m-0 self-stretch h-5 relative text-center text-sm text-greyscale-301 font-heading-h3-medium'>
//                   <a className='block md:inline-block mb-2 md:mb-0 md:mr-4 text-inherit'>
//                     Our Products
//                   </a>
//                   <a className='block md:inline-block mb-2 md:mb-0 md:mr-4 text-inherit'>
//                     The Story
//                   </a>
//                   <a className='block md:inline-block text-inherit'>
//                     Contact Us
//                   </a>
//                 </nav>
//               </nav>
//             </div>
//           </div>
//           <div className='flex flex-row items-center justify-start gap-6'>
//             <Link className='text-inherit !text-gray-light' to='/register'>Login</Link>
//             <Link to="/signup">
//             <ButtonComp>
//               <span className='text-sm leading-5 font-medium text-gray'>
//                 Join Now
//               </span>
//             </ButtonComp>
//             </Link>
//           </div>
//         </div>
//       </header>

//       <main className='w-full mx-auto flex flex-col items-center justify-center py-0 px-5 box-border gap-[103.7px] sm:gap-7'>
//         {/* Hero Section */}
//         <section className='flex flex-col items-start justify-start pt-0 pb-4 px-0 box-border gap-16 max-w-full text-left text-5xl md:text-6xl text-white font-heading-h3-medium sm:gap-4 lg:gap-8'>
//           <h1 className='m-0 relative text-inherit leading-tight font-medium font-inherit max-w-full z-[1] sm:text-4xl sm:leading-normal lg:text-5xl lg:leading-tight'>
//             <p className='mb-16 sm:mb-8'>Explore a new way of</p>
//             <p className='m-0 text-secondary-gold'>Smoking Cigars</p>
//           </h1>
//           <div className='self-stretch flex flex-row items-start justify-start relative max-w-full'>
//             <img
//               className='h-[533.3px] w-full rounded-xl max-w-full object-cover z-[1]'
//               alt=''
//               src='/images/home-hero.svg'
//             />
//             <div className='h-[1000px] w-[775px] absolute top-[-257px] right-[-320px] hidden lg:block'>
//               <img
//                 className='absolute h-full top-0 left-0 max-h-full opacity-[0.05] overflow-hidden'
//                 alt=''
//                 src='/logo/logomark.svg'
//               />
//               <img
//                 className='absolute top-[918.3px] left-[397px] w-6 h-6 overflow-hidden z-[1]'
//                 alt=''
//                 src='/iconchevronleft.svg'
//               />
//               <img
//                 className='absolute top-[918.3px] left-[431px] w-6 h-6 overflow-hidden z-[1]'
//                 alt=''
//                 src='/iconchevronright.svg'
//               />
//             </div>
//           </div>
//         </section>

//         {/* Product Section */}
//         <section className='md:ml-[17%] flex flex-col items-start justify-start pt-0 pb-4 px-0 box-border gap-16 max-w-full text-left text-5xl md:text-6xl text-white font-heading-h3-medium sm:gap-4 lg:gap-8'>
//           <h1 className='m-0 w-full max-w-[733px] relative text-inherit leading-tight font-medium font-inherit sm:text-xl sm:leading-normal lg:text-2xl lg:leading-normal text-secondary-gold'>
//             Our Products
//           </h1>
//           <div className='w-full max-w-[1140px] overflow-x-auto flex flex-row items-start justify-start py-0 pr-5 box-border gap-5 z-[1] text-sm text-white'>
//             {[...Array(4)].map((_, index) => (
//               <div
//                 key={index}
//                 className='w-[253px] rounded-xl border-primary-blue-80 border border-solid box-border overflow-hidden shrink-0 flex flex-col items-start justify-start pt-[173px] pb-[18px] px-5 gap-2'
//               >
//                 <div className='self-stretch h-[139px] relative bg-primary-blue-100 overflow-hidden shrink-0 hidden' />
//                 <h3 className='m-0 self-stretch relative text-xl leading-7 font-medium font-inherit sm:text-lg sm:leading-6'>
//                   Product Name
//                 </h3>
//                 <p className='m-0 relative leading-5 text-greyscale-301'>
//                   Aenean a faucibus dui. Proin ligula risus, rhoncus vel maximus
//                   ut, tempor eget tellus.
//                 </p>
//                 <span className='self-stretch relative leading-5 font-medium text-primary-blue-10'>
//                   View More
//                 </span>
//               </div>
//             ))}
//           </div>
//         </section>

//         {/* Story Section */}
//         <section className='md:ml-[29%] flex flex-col items-start justify-start pt-0 pb-4 px-0 box-border gap-16 max-w-full text-left text-5xl md:text-6xl text-white font-heading-h3-medium sm:gap-4 lg:gap-8'>
//           <h1 className='m-0 self-stretch relative text-inherit leading-tight font-medium font-inherit sm:text-xl sm:leading-normal lg:text-2xl lg:leading-normal text-secondary-gold'>
//             The Story
//           </h1>
//           <div className='self-stretch flex flex-row items-start justify-start gap-10 max-w-full text-base text-greyscale-301 sm:gap-5 md:flex-wrap'>
//             <div className='flex-1 flex flex-col items-start justify-start gap-6 min-w-[330px] max-w-full'>
//               <h2 className='m-0 w-full max-w-[312px] relative text-2xl leading-8 font-medium font-inherit text-white sm:text-lg sm:leading-7'>
//                 This is a nice title that lays on 2 rows
//               </h2>
//               <p className='m-0 relative leading-6 text-gray-light'>
//                 Pellentesque pulvinar sed urna at gravida. Donec sit amet
//                 faucibus leo. Cras in mauris tristique, viverra ligula non,
//                 posuere ligula. Donec et mi in metus blandit tincidunt. Praesent
//                 non dolor nec augue suscipit luctus vel quis mi.
//               </p>
//               <p className='m-0 relative leading-6 text-gray-light'>
//                 Pellentesque pulvinar sed urna at gravida. Donec sit amet
//                 faucibus leo. Cras in mauris tristique, viverra ligula non,
//                 posuere ligula. Donec et mi in metus blandit tincidunt. Praesent
//                 non dolor nec augue suscipit luctus vel quis mi.
//               </p>
//             </div>
//             <img
//               className='w-12 relative rounded-xl object-cover md:flex-1'
//               alt=''
//               src='/images/story.svg'
//             />
//           </div>
//         </section>
//       </main>

//       <section className='flex flex-col items-center justify-center w-full max-w-[900px] mx-auto'>
//         <h1 className='mb-6 text-left left-0 text-xl font-medium text-secondary-gold'>
//           Contact Us
//         </h1>
//         <div className='w-full'>
//           <div className='rounded-xl border-primary-blue-80 border border-solid box-border flex flex-col-reverse md:flex-row items-center justify-center w-full'>
//             <img
//               className='w-48 relative overflow-hidden shrink-0 md:order-last opacity-[0.05]'
//               alt=''
//               src='/logo/logomark-1.svg'
//             />
//             <div className='flex-1 flex flex-col items-start justify-start w-full md:ml-[10%]'>
//               {['Full Name', 'Email Address', 'City'].map((label, index) => (
//                 <div key={index} className='w-[70%]'>
//                   <label className='m-0 relative text-sm font-medium text-white'>
//                     {label}
//                   </label>
//                   <InputComp
//                     value={
//                       label === 'Full Name'
//                         ? firstName
//                         : label === 'Email Address'
//                         ? email
//                         : city
//                     }
//                     onChange={(val: any) => {
//                       if (label === 'Full Name') {
//                         setFirstName(val)
//                       } else if (label === 'Email Address') {
//                         setEmail(val)
//                       } else {
//                         setCity(val)
//                       }
//                     }}
//                     type={label === 'Email Address' ? 'email' : 'text'}
//                     placeholder={`Enter your ${label.toLowerCase()}`}
//                   />
//                 </div>
//               ))}
//               <div className='w-[70%] mt-4'>
//                 <ButtonComp>Submit Message</ButtonComp>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   )
// }

// export default HomePage

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Home: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/login');
  }, []);

  return <div>Redirecting...</div>;
};

export default Home;


