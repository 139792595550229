import React from 'react';
import TimelineComp from './TimelineComp';
import { X } from 'lucide-react'
import PaginationComp from '../../PaginationComp';
import EmptyScreenComp from '../../EmptyScreenComp';

interface TimelineRightSideProps {
  timelineData: any[];
  isMobile: boolean;
  onClose: () => void;
  timelineTotalRecords: number;
  selectedTimelinePage: number;
  onPageChangeTimeline: any,
}

const TimelineRightSide: React.FC<TimelineRightSideProps> = ({
  timelineData,
  isMobile,
  onClose,
  timelineTotalRecords,
  selectedTimelinePage,
  onPageChangeTimeline
}) => {



  const content = (
    <div
      className={`border border-primary-blue-90 rounded-2xl ${isMobile ? 'text-white' : 'col-span-2 row-span-2'
        }`}
    >
      <div className="flex justify-between items-center p-4">
        <h2 className="text-lg font-semibold whitespace-nowrap">Reward History</h2>
        <div className='w-full flex justify-end'>
          <X className='w-6 h-6 cursor-pointer !text-white' onClick={onClose} />
        </div>
      </div>

      {timelineData.length === 0 && (
        <EmptyScreenComp
          title="No entries available"
        />
      )}
      <TimelineComp entries={timelineData} />

      <div className="mt-2 mb-4">
        {timelineData?.length >= 5 ? <PaginationComp
          currentPage={selectedTimelinePage}
          totalPages={timelineTotalRecords}
          onPageChange={onPageChangeTimeline}
          maxVisiblePages={5}
        /> : null}
      </div>
    </div>
  );

  // Render different layouts for mobile and desktop views
  return isMobile ? (
    <div className='block md:hidden fixed inset-0 z-50 bg-primary-blue-90 overflow-y-auto'>
      {content}
    </div>
  ) : (
    <div className='hidden md:contents'>{content}</div>
  )
};

export default TimelineRightSide;
