import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Coming from './pages/Coming';
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';
import Reset from './pages/Auth/Reset';
import Dashboard from './pages/Dashboard/Home';
import Account from './pages/Dashboard/Account/Account';
import AccountPassword from './pages/Dashboard/Account/Password';
import AccountFollowers from './pages/Dashboard/Account/Followers';
import AccountFollowing from './pages/Dashboard/Account/Following';
import Store from './pages/Dashboard/Store';
import Journal from './pages/Dashboard/Journal';
import SetupAccount from './pages/Dashboard/Account/SetupAccount';
import SpinnerComp from './components/SpinnerComp';
import ProtectedRoutes from './components/Route/ProtectedRoute';
import { useUserStore } from './store/userStore';
import api from './services/api';
import NotFound from './pages/Utility/NotFound';
import ServerDown from './pages/Utility/ServerDown';
import Terms from './pages/Utility/Terms';
import Privacy from './pages/Utility/Privacy';
import AllMembers from './pages/Dashboard/Members';

function AppContent() {
  const setUser = useUserStore(state => state.setUser);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const checkUser = async () => {
      try {
        const response = await api.auth.me();
        setUser(response);
        if (response.location == null) {
          navigate('/dashboard/setup');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    if (location.pathname.includes('/dashboard')) {
      checkUser();
    } else {
      setIsLoading(false);
    }
  }, [setUser, location.pathname]);

  if (isLoading) {
    return <div className="bg-primary-blue-90 flex items-center justify-center h-screen"><SpinnerComp /></div>;
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/coming" element={<Coming />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<Reset />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/dashboard/setup" element={<SetupAccount />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/store" element={<Store />} />
          <Route path="/dashboard/journal" element={<Journal />} />
          <Route path="/dashboard/account" element={<Account />} />
          <Route path="/dashboard/account/password" element={<AccountPassword />} />
          <Route path="/dashboard/account/followers" element={<AccountFollowers />} />
          <Route path="/dashboard/account/following" element={<AccountFollowing />} />
        </Route>
        <Route path="/server-error" element={<ServerDown />} />
        <Route path="*" element={<NotFound title="Page not found" description="The page you are looking for does not exist." />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/dashboard/members" element={<AllMembers />} />
      </Routes>
    </div>
  );
}

export default AppContent;
