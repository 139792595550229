const getErrorMessage = (error: any): string => {
  return (
    error?.response?.data?.email?.[0] ||
    error?.response?.data?.email ||
    error?.response?.data?.code?.[0] ||
    error?.response?.data?.code ||
    error?.response?.data?.new_password?.[0] ||
    error?.response?.data?.new_password ||
    error?.response?.data?.old_password ||
    error?.response?.data?.non_field_errors?.[0] ||
    error?.response?.data?.detail ||
    // error?.response?.data[0] ||
    error.message ||
    'An unknown error occurred.'
  );
};

export { getErrorMessage };
