import React, { useState, useEffect, useCallback } from 'react'
import DashboardLayout from '../../components/layouts/DashboardLayout'
import AccountHeader from '../../components/Account/AccountHeader'
import PaginationComp from '../../components/PaginationComp'
import JournalItem from '../../components/Dashboard/Journal/JournalItem'
import JournalDetailView from '../../components/Dashboard/Journal//JournalDetailView'
import JournalTabButton from '../../components/Dashboard/Journal/JournalTabButton'
import SpinnerComp from '../../components/SpinnerComp'
import GoogleMapComp from '../../components/Google/GoogleMapComp'
import { parseAddressesForMap } from '../../utils/parseAddress'
import { LocationType } from '../../utils/parseAddress';
import EmptyScreenComp from '../../components/EmptyScreenComp'
import ConfirmationModal from '../../components/ConfirmationModalComp'
import CigarModal from '../../components/Cigar/CigarModal'
import useCigar from '../../hooks/useCigar';
import useHomeModel from '../../hooks/useHomeModal';
import { useNavigate } from 'react-router-dom';
import HomeModals from './Modals/HomeModals'
import { useModalStore } from '../../store/useModalStore'
import AddCigarModal from '../../components/Cigar/AddCigarModal'

const Journal: React.FC = () => {
  const {
    tab,
    setTab,
    selected,
    ratings,
    loading,
    currentPage,
    totalPages,
    locations,
    showModal,
    onActive,
    handlePageChange,
    handleDeleteItem,
    handleConfirmDelete,
    handleCancelDelete,
    setSelected,
    setSort,
    fetchRatings,
    handleMarkerClick
  } = useCigar();


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingCigar, setEditingCigar] = useState<any>(null);
  const homeModelProps = useHomeModel(isModalOpen, setIsModalOpen, isModalOpen, setIsModalOpen);
  const navigate = useNavigate();
  const { modalStates, updateModalState } = useModalStore();
  const [isExperienceModalOpen, setIsExperienceModalOpen] = useState(false);

  const handleEditCigar = useCallback((cigarId: number) => {
    const cigarToEdit = ratings.find(rating => rating.id === cigarId);
    setEditingCigar(cigarToEdit);
    // delay 5 seconds.
    setTimeout(() => {
      setIsModalOpen(true);
    }, 100);
  }, [ratings]);

  useEffect(() => {
    if (isModalOpen && editingCigar) {
      const cigarToEdit: any = ratings.find(rating => rating.id === editingCigar?.id);
      setEditingCigar(cigarToEdit);
    }
  }, [isModalOpen]);

  const handleSaveCigar = async () => {
    console.log("Handle Save Journal Save");
    // setIsModalOpen(false);
    setIsExperienceModalOpen(false);
    console.log ("Handle Save Journal Save")
    await fetchRatings(1);
    console.log ("Handle Save Journal fetched")
  };

  const handleExperienceModal = (id: number) => {
    const _cigar: any = ratings.find(rating => rating.id === id);
    console.log("Cigar", _cigar)
    setEditingCigar(_cigar);
    // delay 5 seconds.
    setTimeout(() => {
      setIsExperienceModalOpen(true);
    }, 100);
  }

  return (
    <DashboardLayout title='Journal' description=''>
      <div className='mb-48 md:mb-12'>
        {/* Add cigar */}
        <AccountHeader title='My Cigar Journal' showNavigation={true} navigation="journal"
          onMenuChange={setSort}
          onAddPress={() => updateModalState('isModalOpen', true)}>
          <div className={`rounded-2xl flex ml-1 gap-2`}>
            <JournalTabButton tabName="list" currentTab={tab} onClick={() => setTab('list')} />
            <JournalTabButton tabName="map" currentTab={tab} onClick={() => setTab('map')} />
          </div>
        </AccountHeader>
        <HomeModals
          {...modalStates}
          setIsModalOpen={(value) => updateModalState('isModalOpen', value)}
          setIsCigarModalOpen={(value) => updateModalState('isCigarModalOpen', value)}
          onSave={handleSaveCigar}
        />
        {loading && (

          <SpinnerComp />
        )}
        <div className='grid grid-cols-1 md:grid-cols-12 lg:grid-cols-12 gap-4 mt-4 md:mt-0'>

          {ratings.length > 0 && tab === 'list' && (
            <div className={`border border-primary-blue-90 rounded-2xl p-4 mb-4 ${selected !== 0 ? 'md:col-span-9' : 'md:col-span-12'}`}>

              {
                ratings.map((reward: any, index: number) => (
                  <JournalItem
                    key={reward.id}
                    {...reward}
                    index={index}
                    length={ratings.length}
                    selected={selected}
                    onActive={onActive}
                    onDelete={handleDeleteItem}
                    onEdit={handleEditCigar}
                    onInProgressClick={handleExperienceModal}
                  />
                ))
              }
            </div>
          )}

          {locations.length > 0 && tab == 'map' && (
            <div className={`rounded-2xl p-4 mb-4 h-64 ${selected !== 0 ? 'md:col-span-9' : 'md:col-span-12'}`}>
              <GoogleMapComp
                locations={parseAddressesForMap(locations)}
                onMarkerClick={handleMarkerClick}
              />
            </div>
          )}

          {/* Mobile version */}
          <div className='md:hidden'>
            <JournalDetailView
              selected={selected}
              ratings={ratings}
              onClose={() => setSelected(0)}
              isMobile={true}
            />
          </div>

          {/* Desktop Version */}
          {selected !== 0 && (
            <div className='hidden md:block col-span-3'>
              <JournalDetailView
                selected={selected}
                ratings={ratings}
                onClose={() => setSelected(0)}
                isMobile={false}
              />
            </div>
          )}
        </div>
        {totalPages > 1 && tab === 'list' && !loading && (
          <PaginationComp
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}


        {/* Confirmation Modal */}
        <ConfirmationModal
          isOpen={showModal}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
          title="Confirm Delete"
          message="Are you sure you want to delete this item?"
          confirmButtonText="Delete"
          cancelButtonText="Cancel"
          loading={loading}
        />

        {/* Empty screen component */}
        {ratings.length === 0 && !loading && (
          <EmptyScreenComp
            title="No entires found"
            description="You have not logged any cigars yet. Log a cigar now."
          />
        )}
      </div>


      {/* Used for editing cigar*/}
      <CigarModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        cigar={editingCigar}
        onSave={handleSaveCigar}
        useHomeModel={() => homeModelProps}
      />
      {/* Use for inprogress */}
      <AddCigarModal
        isOpen={isExperienceModalOpen}
        onClose={() => setIsExperienceModalOpen(false)}
        cigar={editingCigar}
        onSave={handleSaveCigar}
        useHomeModel={() => homeModelProps}
        defaultStep={3}
      />
    </DashboardLayout>
  )
}

export default Journal
