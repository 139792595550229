import React from 'react';
import HistoryItem from './HistoryItem';
import PaginationComp from '../../PaginationComp';
import { Award } from 'lucide-react';
import EmptyScreenComp from '../../EmptyScreenComp';

interface RewardHistoryProps {
    rewardHistory: any[];
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
    loading?: boolean;
}

const RewardHistory: React.FC<RewardHistoryProps> = ({
    rewardHistory,
    currentPage,
    totalPages,
    onPageChange,
    loading
}) => {
    return (
        <div>
            <div className='flex items-center justify-between mt-4 p-4'>
                <h2 className='text-md font-semibold text-left text-gray-light'>
                    Rewards History
                </h2>

                <div className='flex items-center space-x-2'>
                    <span className='text-md font-semibold text-gray-light'>{rewardHistory.length}</span>
                    <Award className='w-4 h-4 text-gray-light' />
                </div>

            </div>
            {rewardHistory.length === 0 && !loading && (
                <EmptyScreenComp
                    title='No rewards redeemed yet'
                    description='You haven’t redeemed any rewards yet. Explore and start earning now!'
                />
            )}

            {rewardHistory.map((history: any, index) => (
                <HistoryItem
                    key={history.id}
                    id={history.id}
                    name={history.reward_name}
                    points={history.points}
                    acquiredOn={history.aquired_on}
                    index={index}
                    length={rewardHistory.length}
                    picture={history.picture}
                />
            ))}

            {totalPages > 1 && (
                <PaginationComp
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                />
            )}
        </div>
    );
};

export default RewardHistory;