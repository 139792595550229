import React, { ReactNode } from 'react';

interface FormFieldProps {
  label: string;
  description: string;
  children: ReactNode;
  colSpan?: number;
}

const FormField: React.FC<FormFieldProps> = ({ label, description, children, colSpan = 2 }) => (
  <>
    <div className="md:col-span-1 mt-4 md:mt-0 mb-4 md:mb-0">
      <label className="block text-sm font-medium text-gray-300 text-left">
        {label}
      </label>
      <p className="text-xs text-gray-400 text-left mb-2 md:mb-0">
        {description}
      </p>
    </div>
    <div className={`md:col-span-${colSpan}`}>
      {children}
    </div>
    <div className="col-span-1"></div>
  </>
);

export default FormField;
