import React, { useState } from 'react'
import ButtonComp from '../../components/ButtonComp'
import InputComp from '../../components/InputComp'
import { Link } from 'react-router-dom'
import AuthLayout from '../../components/layouts/AuthLayout'
import useAuth from '../../hooks/useAuth'
import { GoogleLogin } from '@react-oauth/google'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { toast } from 'react-toastify';

const Login = () => {
  const { login, loading, googleSignIn, setLoading } = useAuth();

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = async () => {
    await login({ email, password });
  };

  const handleGoogleSignIn = async (credentialResponse: any) => {
    setLoading(true)
    try {
      await googleSignIn(credentialResponse.credential);
    } catch (error: any) {
      toast.error("Fail to continue with google")
    } finally {
      setLoading(false)
    }
  };
  const clientId: string = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ""

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <AuthLayout
        title="Login to your account"
        subtitle="Welcome Back"
        description=''
        bottomLink={
          <>
            Don't have an account? <Link to="/register" className="text-secondary-gray font-semibold">Sign Up</Link>
          </>
        }
      >
        <div className='w-full flex flex-col items-start justify-start gap-4'>
          <label className='self-start text-sm md:text-base'>
            Email Address
          </label>
          <div className='w-full rounded-2xl overflow-hidden'>
            <InputComp
              value={email}
              type='email'
              placeholder='Enter your email address'
              onChange={setEmail}
            />
          </div>
          <label className='self-start text-sm md:text-base'>Password</label>
          <div className='w-full rounded-2xl overflow-hidden'>
            <InputComp
              value={password}
              placeholder='Enter your password'
              type='password'
              onChange={setPassword}
            />
          </div>
          <div className='w-full flex justify-between items-center'>
            <label></label>
            <Link
              to='/forgot-password'
              className='text-xs md:text-sm text-secondary-gray'
            >
              Forgot Password?
            </Link>
          </div>
          <ButtonComp onClick={handleSubmit} loading={loading}>Sign In</ButtonComp>

          {/* <button className="cursor-pointer border-[#3C3D4F] border-[1.5px] border-solid py-2 w-full bg-[transparent] h-11 rounded-2xl box-border overflow-hidden shrink-0 flex flex-row items-center justify-center gap-spacing-lg hover:bg-dimgray-200 hover:border-dimgray-100 hover:border-[1.5px] hover:border-solid hover:box-border mq450:pl-5 mq450:pr-5 mq450:box-border">
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0"
            alt=""
            src="/icons/google.svg"
          />
          <div className="relative text-sm leading-[20px] font-heading-h1-medium text-white text-left ml-2">
            Sign in with Google
          </div>
        </button> */}
        {
          !loading && (
              <div className="rounded-2xl overflow-hidden border-transparant">
            <GoogleLogin
                  onSuccess={handleGoogleSignIn}
                  onError={() => {
                    toast.error("Fail to sign in with google")
                  }}
                  useOneTap
                />
              </div>
          )
        }
        </div>
      </AuthLayout>
    </GoogleOAuthProvider>
  )
}

export default Login
