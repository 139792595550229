import React, { useEffect, useRef, useState } from 'react';
import { LocationType } from '../../utils/parseAddress';
import ProfileUserIcon from '../../assets/profile-user.svg';

interface GoogleMapCompProps {
    locations: LocationType[];
    optionalComponent?: React.ReactNode;
    onMarkerClick?: (index: number) => void;
}

// Extend the Window interface to include google and initMap
declare global {
    interface Window {
        google: typeof google;
        initMap?: () => void;
    }
}

const GoogleMapComp: React.FC<GoogleMapCompProps> = ({ locations, optionalComponent, onMarkerClick }) => {
    const mapRef = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map | null>(null);

    useEffect(() => {
        console.log("locations", locations);
        const loadGoogleMapsScript = (): Promise<void> => {
            return new Promise((resolve, reject) => {
                // Check if the script is already added
                if (document.querySelector(`script[src^="https://maps.googleapis.com/maps/api/js?key="]`)) {
                    resolve();
                    return;
                }

                const script = document.createElement('script');
                script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&callback=initMap&v=weekly&libraries=places`;
                script.async = true;
                script.defer = true;
                script.onload = () => resolve();
                script.onerror = (error) => reject(error);
                document.head.appendChild(script);
            });
        };

        // Initialize the map when the script is loaded
        const initializeMap = () => {
            if (mapRef.current && window.google) {
                const newMap = new window.google.maps.Map(mapRef.current, {
                    center: { lat: 39.8283, lng: -98.5795 },
                    zoom: 4,
                    restriction: {
                        latLngBounds: {
                            north: 49.38,
                            south: 25.82,
                            west: -124.39,
                            east: -66.94,
                        },
                        strictBounds: true,
                    },
                    zoomControl: true,
                    mapTypeControl: true,
                    scaleControl: true,
                    streetViewControl: true,
                    rotateControl: true,
                    fullscreenControl: true,
                });
                setMap(newMap);
            }
        };

        // Load the script and initialize the map
        loadGoogleMapsScript()
            .then(() => {
                if (window.google) {
                    window.initMap = initializeMap;
                    initializeMap(); // Call the function directly
                }
            })
            .catch((error) => console.error('Error loading Google Maps script:', error));

        return () => {
            // Clean up the global initMap function
            window.initMap = undefined;
        };
    }, []);

    useEffect(() => {
        if (map && locations.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();

            locations.forEach((location, index) => {
                // if latitute and longitude are not provided, skip this location
                if (!location.latitude || !location.longitude) {
                    return;
                }

                const position = { lat: location.latitude, lng: location.longitude };
                bounds.extend(position);

                // Create a custom HTML marker
                const markerDiv = document.createElement('div');
                markerDiv.className = 'custom-marker';
                markerDiv.style.width = '50px';
                markerDiv.style.height = '50px';
                markerDiv.style.borderRadius = '50%';
                markerDiv.style.overflow = 'hidden';
                markerDiv.style.backgroundColor = '#fff';
                markerDiv.style.cursor = 'pointer';

                const image = document.createElement('img');
                image.src = location.picture || ProfileUserIcon;
                image.style.width = '100%';
                image.style.height = '100%';
                image.style.objectFit = 'cover';
                markerDiv.appendChild(image);

                const markerOverlay = new window.google.maps.OverlayView();
                markerOverlay.onAdd = () => {
                    const panes = markerOverlay.getPanes();
                    if (panes) {
                        panes.overlayMouseTarget.appendChild(markerDiv);
                    }

                    markerDiv.addEventListener('click', () => {
                        if (onMarkerClick && location.id !== undefined) {
                            onMarkerClick(location.id);
                        }
                    });
                };
                markerOverlay.onRemove = () => {
                    if (markerDiv.parentNode) {
                        markerDiv.parentNode.removeChild(markerDiv);
                    }
                };
                markerOverlay.draw = () => {
                    const projection = markerOverlay.getProjection();
                    if (projection) {
                        const point = projection.fromLatLngToDivPixel(new window.google.maps.LatLng(location.latitude, location.longitude));
                        if (point) {
                            markerDiv.style.left = `${point.x - 25}px`;
                            markerDiv.style.top = `${point.y - 25}px`;
                            markerDiv.style.position = 'absolute';
                        }
                    }
                };

                markerOverlay.setMap(map);
            });

            //map.fitBounds(bounds);
        }
    }, [map, locations]);

    return (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <div
                className="rounded-2xl h-full min-h-[500px] md-min-h-[300px]"
                ref={mapRef}
            />
            {/* Top right banner */}
            <div className="absolute top-3 right-16 z-10">
                <div className="bg-primary-blue-90 rounded-lg px-2 shadow-md text-white">
                    Where Are Stoic Cigars Being Smoked?
                </div>
            </div>

            {optionalComponent && (
                <div
                    style={{
                        position: 'absolute',
                        bottom: '20px',
                        left: '20px',
                        zIndex: 1,
                    }}
                >
                    {optionalComponent}
                </div>
            )}
        </div>
    );

};

export default GoogleMapComp;
