import React, { useState, useEffect } from "react";
import { api } from "../services/api";
import { useUserStore } from "../store/userStore";
import { toast } from 'react-toastify';
import { Address } from "../types/address";

const useLocation = () => {
  const [user, setUser] = useState(useUserStore.getState().user);
  const [isLocationPublic, setIsLocationPublic] = useState(false);
  const [locationError, setLocationError] = useState("");
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [locationLoading, setLocationLoading] = useState(false);
  const [showPlacesSearch, setShowPlacesSearch] = useState(false);
  const [showTryAgain, setShowTryAgain] = useState(false);

  const [showLocation, setShowLocation] = useState(true);
  const [location, setlocation] = useState<Address | null>(null);
  const [useCurrentLocation, setUseCurrentLocation] = useState(false);
  const [homeLocation, setHomeLocation] = useState(false);
  const [placeSearch, setPlaceSearch] = useState(false);

  // Function to set the location mode
  const setLocationMode = (mode: any) => {
    setUseCurrentLocation(mode === 'current');
    setHomeLocation(mode === 'home');
    setPlaceSearch(mode === 'search');
  };

  useEffect(() => {
    if (showLocation && homeLocation) {
      handleHomeLocationClick();
    }
  }, [showLocation]);

  useEffect(() => {
    if (user && user?.location) {
      setlocation(user.location);
      setLatitude(user.location.latitude);
      setLongitude(user.location.longitude);
      setLocationMode('home'); // Set mode to 'home'
    }
  }, [user?.location]);

  useEffect(() => {
    if (homeLocation) {
      setlocation(user.location);
      const updateLocation = async () => {
        setLocationLoading(true);
        await getHomeLocation();
        if (!location) {
          setShowPlacesSearch(true);
          setLocationMode('search'); // Switch to 'search' mode if home location is not available
        }
        setLocationLoading(false);
      };
      updateLocation();
    }
  }, [homeLocation]);

  useEffect(() => {
    const fetchlocation = async () => {
      if (useCurrentLocation) {
        setLocationLoading(true);
        try {
          setlocation(null);
          setLocationError("");
          await getlocation();
        } catch (error) {
          console.error("Error getting current location:", error);
          setShowPlacesSearch(true);
          setLocationMode('search'); // Switch to 'search' mode if current location fails
        } finally {
          setLocationLoading(false);
        }
      }
    };

    fetchlocation();
  }, [useCurrentLocation]);

  const handlelocationClick = () => {
    if (locationLoading) return;
    if (!useCurrentLocation) {
      setLocationMode('current');
    } else {
      setLocationMode('home');
    }
  };

  const handleHomeLocationClick = () => {
    if (locationLoading) return;
    if (!homeLocation) {
      setLocationMode('home');
    } else {
      setLocationMode('current');
    }
  };

  const getlocation = () => {
    setLocationMode('current'); // Set mode to 'current'
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          getPlaceName(position.coords.latitude, position.coords.longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
          setLocationError("Unable to get current location. Please try again.");
          toast.error("Unable to get current location. Please try again.");
          setShowPlacesSearch(true);
          setLocationMode('search'); // Switch to 'search' mode on error
        }
      );
    } else {
      setLocationError("Geolocation is not supported by your browser.");
      toast.error("Geolocation is not supported by your browser.");
      setShowPlacesSearch(true);
      setLocationMode('search'); // Switch to 'search' mode if geolocation is not supported
    }
  };

  const getPlaceName = async (latitude: number, longitude: number) => {
    if (locationLoading) return;
    try {
      setLocationLoading(true);
      const response = await api.googlePlaces.getPlaceName(latitude, longitude);
      console.log("API response:", response);
      const placeDetails = response.place_details;
      if (!placeDetails) {
        throw new Error("Place details are undefined");
      }
      const address: Address = {
        street: placeDetails.street || "",
        latitude,
        longitude,
        zip_code: placeDetails.zip_code || "",
        city: placeDetails.city || "",
        state: placeDetails.state || "",
        country: placeDetails.country || "",
        type: "user"
      };
      setlocation(address);
    } catch (error) {
      console.error("Error getting place name:", error);
      setlocation(null);
      toast.error("Error getting place name. Please try again.");
      setLocationMode('search'); // Switch to 'search' mode on error
    } finally {
      setLocationLoading(false);
    }
  };

  const handlePlaceSelect = (place: any) => {
    setlocation(place);
    setShowPlacesSearch(false);
    setLocationMode('search'); // Set mode to 'search'
  };

  const getHomeLocation = async () => {
    setLocationMode('home'); // Set mode to 'home'
    if ((user?.location?.latitude === 0 && user?.location?.longitude === 0)) {
      setLocationError("You have not setup your home location from account settings.");
      toast.error("You have not setup your home location from account settings.");
      setShowPlacesSearch(true);
      setLocationMode('search'); // Switch to 'search' mode if home location is not set
      return;
    }
    setLatitude(user?.location?.latitude);
    setLongitude(user?.location?.longitude);
    setlocation(user?.location);
    setShowPlacesSearch(false);
    setLocationLoading(false);
  };

  return {
    isLocationPublic,
    setIsLocationPublic,
    showLocation,
    setShowLocation,
    useCurrentLocation,
    setUseCurrentLocation,
    location,
    setlocation,
    latitude,
    setLatitude,
    longitude,
    setLongitude,
    locationLoading,
    setLocationLoading,
    getlocation,
    getPlaceName,
    locationError,
    homeLocation,
    setHomeLocation,
    setShowPlacesSearch,
    handlelocationClick,
    showPlacesSearch,
    handlePlaceSelect,
    setShowTryAgain,
    showTryAgain,
    getHomeLocation,
    handleHomeLocationClick,
    placeSearch,
    setPlaceSearch
  };
};

export default useLocation;
