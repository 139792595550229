import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import api from '../../services/api'
import { useNavigate } from 'react-router-dom'
import SpinnerComp from '../../components/SpinnerComp'
import { Helmet } from "react-helmet";
import ToastComp from '../ToastComp';
import { useLocation } from 'react-router-dom';

interface AuthLayoutType {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
  bottomLink?: React.ReactNode;
  description?: string;
}

const AuthLayout: React.FC<AuthLayoutType> = ({ children, title, subtitle, bottomLink, description }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const navigation = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkUser = async () => {
      try {
        const response = await api.auth.me();
        if (response && location.pathname !== '/dashboard/setup') {
          navigation('/dashboard');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    checkUser();
  }, []);

  return (
    <>
      <Helmet>
        <title>{title + ' - Stoic Cigar'}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content="" />
      </Helmet>
      <section className="bg-primary-blue-80 h-screen">

        <ToastComp />
        <div className='w-full pt-16 md:pt-0 md:h-screen relative overflow-hidden flex flex-col items-center justify-center px-2 py-2 md:py-16 text-center text-white font-text-md-medium1'>
          <img
            className='hidden md:block absolute md:top-0 mt-auto !mx-auto object-cover opacity-[0.02] z-0'
            alt=''
            src='/logo/logomark.svg'
          />
          <div className='w-full max-w-md flex flex-col items-center justify-start gap-8 z-10'>
            <img
              className='w-16 h-16 md:w-24 md:h-24'
              loading='lazy'
              alt=''
              src='/logo/logomark-1.svg'
            />
            {isLoading && <SpinnerComp />}
            {!isLoading && (
              <>
                <p className='text-sm md:text-base opacity-70 -mb-8'>{subtitle}</p>
                <div className='flex flex-col items-center justify-start gap-4 w-full'>
                  <h1 className='text-xl md:text-2xl font-medium'>
                    {title}
                  </h1>
                </div>
                {children}
                {bottomLink && (
                  <div className="text-secondary-gray fixed bottom-12">
                    {bottomLink}
                  </div>

                )}
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default AuthLayout
