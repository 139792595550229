import { LocationType } from '../utils/parseAddress';
import React, { useState, useEffect } from "react";
import { initialData } from "../consts/home";
import { api } from "../services/api";
import { toast } from 'react-toastify';

const useHome = () => {
  const [data, setData] = useState(initialData);
  const [selected, setSelected] = useState(0);
  const [cigarSelected, setCigarSelected] = useState(0);
  const [stats, setStats] = useState<any>(null);
  const [loader, setLoder] = useState(false);
  const [topCigars, setTopCigars] = useState<any>(null);
  const [last90Days, setLast90Days] = useState<any>(null);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [locations, setLocations] = useState<LocationType[]>([]);
  const [allCigarData, setAllCigarData] = useState<any[]>([]);
  const [filter, setFilter] = React.useState('all')
  const [selectedFromMarker, setSelectedFromMarker] = useState<number>(0);
  const [timelineData, setTimelineData] = useState<any[]>([]);
  const [timelineTotalRecords, setTimelineTotalRecords] = useState<number>(0);
  const [selectedTimelinePage, setSelectedTimelinePage] = useState<number>(1);
  const [showTimelineView, setShowTimelineView] = useState<boolean>(false);

  const filterOptions = [
    { value: 'all', label: 'All Cigars' },
    { value: 'user', label: 'User Cigars' }
  ]


  const handleFilterChange = (value: string) => {
    setFilter(value)
    console.log('Filter:', value)
  }


  useEffect(() => {
    const fetchData = async () => {
      setLoder(true);
      try {
        const [
          statsResponse,
          topCigarsResponse,
          ninetyDaysResponse,
          cigarsResponse,
        ] = await Promise.all([
          api.stats.getStats(),
          api.stats.getTopCigars(),
          api.stats.get90DayStats(),
          api.cigars.listCigars(undefined, undefined, undefined, filter)
        ]);
        console.log("STAT triggered", statsResponse);
        setStats(statsResponse);
        setTopCigars(topCigarsResponse);
        setLast90Days(ninetyDaysResponse);
        // Store all data fetched from the server
        setAllCigarData(cigarsResponse);

        console.log("Cigars Response", cigarsResponse);

        // Extract locations from the cigar data
        const cigarsWithLocation = cigarsResponse
          .filter((cigar: any) => cigar.location)
          .map((cigar: any) => ({ ...cigar }));

        setLocations(cigarsWithLocation);

      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("An error occurred while fetching data. Please try again.");
      } finally {
        setLoder(false);
      }
    };

    fetchData();
  }, [filter]);

  const handleMarkerClick = (id: number) => {
    console.log("Marker clicked", id);
    setSelected(0);
    setCigarSelected(0);
    setSelectedFromMarker(id);
  };

  const toggleDataDisplay = () => {
    setData((prev) =>
      prev.length > 5 ? initialData : [...prev, ...initialData]
    );
  };

  const onSelectUser = (id: number) => {
    let user = topCigars.find((user: any) => user.id === id);
    console.log("User on Selected", user, id, topCigars);
    setSelectedUser(user);
    setSelected(id);
  };

  const refreshData = async () => {
    setLoder(true);
    setSelected(0);
    try {
      const [topCigarsResponse, ninetyDaysResponse] = await Promise.all([
        api.stats.getTopCigars(),
        api.stats.get90DayStats(),
      ]);
      setTopCigars(topCigarsResponse);
      setLast90Days(ninetyDaysResponse);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("An error occurred while fetching data. Please try again.");
    } finally {
      setLoder(false);
    }
  };

  const getTimelineData = async (page = 1) => {
    const itemPerPage = 5;
    setLoder(true)
    setTimelineData([]);
    setSelectedTimelinePage(page);
    try {
      const response = await api.stats.getTimeline(selectedTimelinePage, itemPerPage);
      console.log("Timeline Response", response);
      const transformedData = response.results.map((entry: any) => ({
        id: entry.id,
        title: entry.product_name,
        points: entry.product_points,
        created_at: entry.created_at,
        picture: entry.product_picture,
        experience_note: entry.experience_note,
        description: entry.user_name,
      }));

      setTimelineData(transformedData);
      setTimelineTotalRecords(Math.ceil(response.count / itemPerPage));
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("An error occurred while fetching data. Please try again.");
    } finally {
      setLoder(false);
    }
  }

  const getTimelineRewardHistoryData = async (page = 1) => {
    const itemsPerPage = 5;
    setLoder(true);
    setTimelineData([]);
    setSelectedTimelinePage(page);

    try {
      const response = await api.rewards.getRedemptionHistory(page, itemsPerPage);
      console.log("Reward History Response", response);
      const transformedData = response.results.map((entry: any) => ({
        id: entry.id,
        title: entry.reward_name,
        points: entry.points,
        created_at: entry.aquired_on,
        picture: entry.picture,
        description: entry.description,
      }));

      setTimelineData(transformedData);
      setTimelineTotalRecords(Math.ceil(response.count / itemsPerPage));
    } catch (error) {
      console.error("Error fetching reward history data:", error);
      toast.error("An error occurred while fetching reward history. Please try again.");
    } finally {
      setLoder(false);
    }
  };

  return {
    data,
    selected,
    cigarSelected,
    stats,
    loader,
    topCigars,
    last90Days,
    selectedUser,
    locations,
    toggleDataDisplay,
    onSelectUser,
    setSelected,
    refreshData,
    filter,
    filterOptions,
    handleFilterChange,
    handleMarkerClick,
    selectedFromMarker,
    setSelectedFromMarker,
    allCigarData,
    getTimelineData,
    timelineData,
    setTimelineData,
    timelineTotalRecords,
    selectedTimelinePage,
    getTimelineRewardHistoryData,
    showTimelineView,
    setShowTimelineView
  };
};

export default useHome;
