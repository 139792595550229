import React from 'react';
import { Link } from 'react-router-dom';

const Terms: React.FC = () => {
    return (
        <div className="w-full min-h-screen relative bg-primary-blue-80 overflow-hidden flex flex-col items-center justify-center px-4 py-8 md:py-16 text-white font-text-md-medium1">
            <img
                className="absolute md:top-0 mt-auto !mx-auto object-cover opacity-[0.02] z-0"
                alt=""
                src="/logo/logomark.svg"
            />
            <div className="w-full max-w-4xl flex flex-col items-center justify-start gap-8 z-10">
                <Link to="/" aria-label="Go to home page">
                    <img
                        className="w-16 h-16 md:w-24 md:h-24"
                        loading="lazy"
                        alt="Company logo"
                        src="/logo/logomark-1.svg"
                    />
                </Link>
                <h1 className="text-3xl md:text-5xl font-medium mb-6">Terms and Conditions</h1>

                <div className="w-full bg-primary-blue-90 rounded-2xl p-6 text-sm md:text-base">
                    <h2 className="text-xl font-semibold mb-4">1. Acceptance of Terms</h2>
                    <p className="mb-4 text-greyscale-301">
                        By accessing and using this website, you agree to be bound by these Terms and Conditions.
                    </p>

                    <h2 className="text-xl font-semibold mb-4">2. Use of the Service</h2>
                    <p className="mb-4 text-greyscale-301">
                        You agree to use our service for lawful purposes only and in a way that does not infringe upon the rights of others.
                    </p>

                    <h2 className="text-xl font-semibold mb-4">3. Privacy Policy</h2>
                    <p className="mb-4 text-greyscale-301">
                        Your use of our service is also governed by our Privacy Policy, which can be found on our website.
                    </p>

                    <h2 className="text-xl font-semibold mb-4">4. Intellectual Property</h2>
                    <p className="mb-4 text-greyscale-301">
                        All content on this website is the property of our company and is protected by copyright laws.
                    </p>

                    <h2 className="text-xl font-semibold mb-4">5. Limitation of Liability</h2>
                    <p className="mb-4 text-greyscale-301">
                        We are not liable for any damages arising from your use of our service or any content provided on our website.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Terms;
