import React from 'react';

interface ProgressBarType {
  percentage: number;
  backgroundColor?: string;
  fillColor?: string;
  height?: string;
  rounded?: boolean;
  showPercentage?: boolean;
}

const ProgressBar: React.FC<ProgressBarType> = ({
  percentage,
  backgroundColor = 'bg-primary-blue-90',
  fillColor = 'bg-secondary-gold',
  height = 'h-1',
  rounded = true,
  showPercentage = false,
}) => {
  const clampedPercentage = Math.min(100, Math.max(0, percentage));

  return (
    <div className="w-full flex items-center">
      <div 
        className={`w-full ${backgroundColor} ${height} ${rounded ? 'rounded-full' : ''} overflow-hidden`}
      >
        <div
          className={`${fillColor} ${height} ${rounded ? 'rounded-full' : ''} transition-all duration-300 ease-in-out`}
          style={{ width: `${clampedPercentage}%` }}
        />
      </div>
      {showPercentage && (
        <span className="ml-2 text-sm font-medium text-gray-700">
          {clampedPercentage}%
        </span>
      )}
    </div>
  );
};

export default ProgressBar;
