import React, { useState } from 'react'
import { Trash, MessageSquareQuote, Edit } from 'lucide-react'
import RatingComp from '../../RatingComp'
import { formatDate } from '../../../utils/date'
import { Address } from '../../../types/address'
import { parseAddress } from '../../../utils/parseAddress'
import CigarIcon from "../../../assets/cigaricon.svg";

interface JournalItemTyoe {
  id: number
  product_name: string
  product_short_description: string
  location: Address
  created_at: string
  experience_note: string
  rating: number
  product_picture: string
  index: number
  length: number
  selected: number
  onActive?: any,
  onDelete?: (id: number) => void;
  onEdit?: (id: number) => void;
  disabled?: boolean,
  in_progress?: boolean
  onInProgressClick?: (id: number) => void;
}

const JournalItem: React.FC<JournalItemTyoe> = ({
  id,
  product_name,
  product_short_description,
  location,
  created_at,
  experience_note,
  rating,
  product_picture,
  index,
  length,
  selected,
  onActive,
  onDelete,
  onEdit,
  disabled = true,
  in_progress = false,
  onInProgressClick
}) => {
  const [currentRating, setCurrentRating] = useState(rating)
  const [active, setActive] = useState(false)
  const handleRatingChange = (newRating: any) => {
    setCurrentRating(newRating)
  }

  const handleDelete = () => {
    if (onDelete) {
      onDelete(id);
    }
  };

  const handleEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onEdit) {
      onEdit(id);
    }
  };

  const isShow = selected === id
  return (
    <div
      key={id}
      className={`cursor-pointer rounded-2xl p-4 mb-4 last:mb-0 relative ${isShow ? 'bg-primary-blue-90' : ''
        }`}
      onClick={() => onActive(id)}
    >
      {' '}
      <div className='flex flex-row md:flex-row items-start md:items-center'>
        <div className='relative'>
          <div
            className={`w-14 h-14 rounded-full flex items-center justify-center`}
          >
            {product_picture !== '' ? (
              <img src={product_picture} alt={product_name} className='w-16 h-16' />
            ) : (
              <img src={CigarIcon} alt={product_name} className="w-12 h-12 text-primary-blue-80" />
            )
            }
          </div>
          {index !== length - 1 && (
            <div className='absolute top-14 left-1/2 w-0.5 h-[600%] md:h-[300%] bg-primary-blue-90 -translate-x-1/2'></div>
          )}
        </div>
        <div className='flex-grow ml-4 md:ml-4'>
          <div className='flex justify-between mb-2'>
            <h3 className='text-white text-lg font-semibold text-left'>
              {product_name}

              {
                in_progress && onInProgressClick && (
                  <label className='ml-2 p-2 bg-[#4E5D66] text-white text-sm' onClick={() => onInProgressClick(id)}>
                    <span className='text-white'>In Progress</span>
                  </label>
                )
              }
            </h3>
            <div className='flex space-x-2'>
              <button className='text-[#4E5D66] text-sm' onClick={handleEdit}>
                <Edit className='text-blue-500 cursor-pointer' />
              </button>
              <button className='text-[#4E5D66] text-sm' onClick={handleDelete}>
                <Trash className='text-red-500 cursor-pointer' />
              </button>
            </div>
          </div>
          <p className='text-gray-light text-sm text-left'>
            {formatDate(created_at)} - {parseAddress(location)}
          </p>
          <p className='text-gray-light text-sm mt-2 text-left'>{product_short_description}</p>
          <div className={`block md:flex md:justify-between md:items-center p-6 text-white mt-2  rounded-2xl
                ${isShow ? 'bg-primary-blue-80' : 'bg-primary-blue-90'}`}>

            <div className='block md:flex md:items-center md:space-x-2'>
              <MessageSquareQuote className='text-left text-[#536A72] w-12' />
              <span className='text-left'>{experience_note}</span>
            </div>
            <div className='mt-4 md:mt-0'>
              <RatingComp disabled={disabled} onChange={handleRatingChange} defaultRating={rating} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JournalItem
