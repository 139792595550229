import React from 'react';

interface RewardPointsProps {
  current: number;
  total: number;
}

const RewardPoints: React.FC<RewardPointsProps> = ({ current, total }) => (
  <div className='p-8 bg-primary-blue-90 rounded-2xl relative'>
    <h2 className='text-md font-semibold text-left text-gray-light mb-4 -mt-4'>
      Reward Points
    </h2>
    <p className='text-white text-4xl'>
      <span className='font-semibold'>{current}</span>
      <span className='opacity-60'>/{total}</span>
    </p>
  </div>
);

export default RewardPoints;
