import React from 'react';

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
    maxVisiblePages?: number;
}

const PaginationComp: React.FC<PaginationProps> = ({
    currentPage,
    totalPages,
    onPageChange,
    maxVisiblePages = 5
}) => {
    const getPageNumbers = () => {
        const pageNumbers = [];
        const halfVisible = Math.floor(maxVisiblePages / 2);
        let startPage = Math.max(currentPage - halfVisible, 1);
        let endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

        if (endPage - startPage + 1 < maxVisiblePages) {
            startPage = Math.max(endPage - maxVisiblePages + 1, 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;
    };

    return (
        <nav className="flex justify-center mt-4">
            <ul className="flex space-x-2">
                {currentPage > 1 && (
                    <li>
                        <button
                            onClick={() => onPageChange(currentPage - 1)}
                            className="px-3 py-1 rounded-md bg-primary-blue-90 text-white hover:bg-primary-blue-80"
                        >
                            Previous
                        </button>
                    </li>
                )}
                {getPageNumbers().map((number) => (
                    <li key={number}>
                        <button
                            onClick={() => onPageChange(number)}
                            className={`px-3 py-1 rounded-md ${number === currentPage
                                    ? 'bg-secondary-gold text-primary-blue-90'
                                    : 'bg-primary-blue-90 text-white hover:bg-primary-blue-80'
                                }`}
                        >
                            {number}
                        </button>
                    </li>
                ))}
                {currentPage < totalPages && (
                    <li>
                        <button
                            onClick={() => onPageChange(currentPage + 1)}
                            className="px-3 py-1 rounded-md bg-primary-blue-90 text-white hover:bg-primary-blue-80"
                        >
                            Next
                        </button>
                    </li>
                )}
            </ul>
        </nav>
    );
};

export default PaginationComp;