import React from 'react';

interface SwitchType {
  isOn: boolean;
  handleToggle?: () => void;
  onColor?: string;
}

const SwitchComp: React.FC<SwitchType> = ({ isOn, handleToggle, onColor = 'bg-primary-blue-60' }) => {
  return (
    <div 
      onClick={handleToggle}
      className={`flex w-14 h-8 rounded-full p-1 cursor-pointer transition-colors ease-in-out duration-200 ${
        isOn ? onColor : 'bg-primary-blue-80'
      }`}
    >
      <div
        className={`bg-secondary-gray w-6 h-6 rounded-full shadow-md transform transition-transform ease-in-out duration-200 ${
          isOn ? 'translate-x-6' : 'translate-x-0'
        }`}
      />
    </div>
  );
};

export default SwitchComp;
