import React, { useEffect, useRef, useState } from 'react';
import { Search } from 'lucide-react';
import states from '@/src/consts/states';

interface PlaceType {
  name: string;
  latitude: number;
  longitude: number;
  address: string;
  addressComponents: google.maps.GeocoderAddressComponent[];
  street: string;
  city: string;
  state: string;
  zipCode: string;
}

interface PlacesSearchCompProps {
  onPlaceSelect: (place: PlaceType) => void;
}

const PlacesSearchComp: React.FC<PlacesSearchCompProps> = ({ onPlaceSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const autoCompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      if (!document.querySelector(`script[src^="https://maps.googleapis.com/maps/api/js?key="]`)) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = () => setIsScriptLoaded(true);
        document.head.appendChild(script);
      }
    };

    if (!window.google) {
      loadGoogleMapsScript();
    } else {
      setIsScriptLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (isScriptLoaded && inputRef.current && !autoCompleteRef.current) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        { types: ['geocode'] }
      );

      autoCompleteRef.current.addListener('place_changed', handlePlaceSelect);
    }
  }, [isScriptLoaded]);

  const handlePlaceSelect = () => {
    const place = autoCompleteRef.current?.getPlace();
    if (place && place.geometry && place.geometry.location) {
      const selectedPlace = {
        name: place.name || '',
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        address: place.formatted_address || '',
        addressComponents: place.address_components || [],
        street: place.address_components?.find((component) => component.types.includes('route'))?.long_name || '',
        city: place.address_components?.find((component) => component.types.includes('locality'))?.long_name || '',
        state: place.address_components?.find((component) => component.types.includes('administrative_area_level_1'))?.short_name || '',
        zipCode: place.address_components?.find((component) => component.types.includes('postal_code'))?.long_name || '',
      };
      setSearchTerm(selectedPlace.address);
      onPlaceSelect(selectedPlace);
    }
  };


  return (
    <div className="relative w-full z-[100]">
      <input
        ref={inputRef}
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search for a place"
        className="w-full bg-primary-blue-90 border-none outline-none bg-primary-blue-90 flex flex-col items-start justify-start py-3 px-5 box-border font-text-md-medium text-sm text-white rounded-2xl"
      />
      <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-secondary-gold" />
    </div>
  );
};

export default PlacesSearchComp;