import React, { useState } from 'react'
import ButtonComp from '../../components/ButtonComp'
import InputComp from '../../components/InputComp'
import { Link } from 'react-router-dom'
import AuthLayout from '../../components/layouts/AuthLayout'
import useAuth from '../../hooks/useAuth'
import { shouldNotEmpty } from '../../utils/validator'
import { GoogleLogin } from '@react-oauth/google'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { toast } from 'react-toastify';
import useLocation from '../../hooks/useLocation';
import PlacesSearchComp from '../../components/Google/PlacesSearchComp';

const Register = () => {
  const { register, loading, googleSignIn, setLoading } = useAuth();
  const {
    location,
    handlePlaceSelect,
  } = useLocation()

  const [first_name, setFirstName] = useState('')
  const [last_name, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [age, setAge] = useState(0)
  const [step, setStep] = useState(1);

  const next = () => {
    if (step == 2) {
      if (password !== confirmPassword) {
        toast.error('Passwords do not match.');
        return;
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        toast.error('Invalid email address.');
        return;
      }
      if (!age) {
        toast.error('Please accept the terms and conditions.');
        return;
      }
      if (!location) {
        toast.error('Please select your home location.');
        return;
      }
    }
    if (step == 1) {
      if (!shouldNotEmpty(first_name) && !shouldNotEmpty(last_name)) {
        setStep(2);
      } else {
        toast.error('All fields are required.');
      }
    } else {
      handleSubmit();
    }
  }
  const handleSubmit = async () => {
    await register(first_name, last_name, email, password, location!);
  }

  const handleGoogleSignIn = async (credentialResponse: any) => {
    setLoading(true)
    try {
      await googleSignIn(credentialResponse.credential);
    } catch (error) {
      toast.error("Fail to continue with google")
    } finally {
      setLoading(false)
    }
  };

  const clientId: string = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ""

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <AuthLayout
        title="Create an account"
        subtitle="Hello stranger!"
        description=''
        bottomLink={
          <>
            Already have an account? <Link to="/login" className="text-secondary-gray font-semibold">Login</Link>
          </>
        }
      >
        <div className='w-full flex flex-col items-start justify-start gap-4'>
          {
            step == 1 && (
              <>
                <label className='self-start text-sm md:text-base'>
                  First Name
                </label>
                <div className='w-full rounded-2xl overflow-hidden'>
                  <InputComp
                    value={first_name}
                    type='text'
                    placeholder='Enter your first name'
                    onChange={setFirstName}
                  />
                </div>
                <label className='self-start text-sm md:text-base'>
                  Last Name
                </label>
                <div className='w-full rounded-2xl overflow-hidden'>
                  <InputComp
                    value={last_name}
                    type='text'
                    placeholder='Enter your last name'
                    onChange={setLastName}
                  />
                </div>
              </>
            )
          }
          {
            step == 2 && (
              <>
                <label className='self-start text-sm md:text-base'>
                  Email Address
                </label>
                <div className='w-full rounded-2xl overflow-hidden'>
                  <InputComp
                    value={email}
                    type='email'
                    placeholder='Enter your email address'
                    onChange={setEmail}
                  />
                </div>
                <label className='self-start text-sm md:text-base'>Password</label>
                <div className='w-full rounded-2xl overflow-hidden'>
                  <InputComp
                    value={password}
                    placeholder='Enter your password'
                    type='password'
                    onChange={setPassword}
                  />
                </div>
                <label className='self-start text-sm md:text-base'>Re-Type</label>
                <div className='w-full rounded-2xl overflow-hidden'>
                  <InputComp
                    value={confirmPassword}
                    placeholder='Enter your password again'
                    type='password'
                    onChange={setConfirmPassword}
                  />
                </div>
                <label className='self-start text-sm md:text-base'>Home Location</label>
                <div className='w-full rounded-2xl overflow-hidden'>
                  <PlacesSearchComp onPlaceSelect={handlePlaceSelect} />
                </div>
                <div className='w-full overflow-hidden flex items-start'>
                  <input type="checkbox" className="mr-2" onChange={(e) => setAge(e.target.checked ? 1 : 0)} />
                  <span className="text-left self-start text-sm md:text-base -mt-1">
                    I accept the <Link to="https://stoiccigars.com/terms" target="_blank" rel="noopener noreferrer" className="text-secondary-gray font-semibold">Terms and Conditions</Link> and <Link to="https://stoiccigars.com/privacy" target="_blank" rel="noopener noreferrer" className="text-secondary-gray font-semibold">Privacy Policy</Link>.
                  </span>
                </div>
                <div className='w-full overflow-hidden flex items-start'>
                  <input type="checkbox" className="mr-2" onChange={(e) => setAge(e.target.checked ? 1 : 0)} />
                  <span className="text-left self-start text-sm md:text-base -mt-1">
                    I acknowledge that I am over 21 years old.
                  </span>
                </div>
              </>
            )
          }
          <ButtonComp
            onClick={next}
            loading={loading}
          >
            {
              step == 1 ? 'Next' : 'Sign Up'
            }
          </ButtonComp>

          {
            !loading && (
              <div className="rounded-2xl overflow-hidden border-transparant">
                <GoogleLogin
                  onSuccess={handleGoogleSignIn}
                  onError={() => {
                    toast.error("Fail to sign up with google")
                  }}
                  useOneTap
                />
              </div>
            )
          }
        </div>
      </AuthLayout>
    </GoogleOAuthProvider>
  )
}

export default Register
