import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../components/layouts/DashboardLayout'
import MemberHeaders from '../../components/MemberHeader'
import FollowerItem from '../../components/Dashboard/Account/FollowerItem'
import SpinnerComp from '../../components/SpinnerComp'
import EmptyScreenComp from '../../components/EmptyScreenComp'
import HomeModals from '../Dashboard/Modals/HomeModals'
import RightSide from '../../components/Dashboard/RightSide'
import PaginationComp from '../../components/PaginationComp'
import useMember from '../../hooks/useMember'

const AllMembers: React.FC = () => {
    const { members, selectedUser, selected, currentPage, totalPages, loading_, searchValue, setSearchValue, handlePageChange, onSelectUser, handleAddFollowing, handleRemoveFollowing, refreshData, loading, updateModalState, modalStates, setSelectedUser } = useMember()

    return (
        <DashboardLayout title='Following' description=''>
            <div className='mb-48 md:mb-12'>
                {/* Add cigar */}
                <MemberHeaders
                    title="Members"
                    onAddPress={() => updateModalState('isModalOpen', true)}
                    onSearch={(value: any) => setSearchValue(value)} />
                <HomeModals
                    {...modalStates}
                    setIsModalOpen={(value) => updateModalState('isModalOpen', value)}
                    setIsCigarModalOpen={(value) => updateModalState('isCigarModalOpen', value)}
                />

                {/* Content Layout for Right Panel (Desktop) */}
                <div className="flex flex-col lg:flex-row lg:gap-4">
                    {/* Main Content (left side on desktop) */}
                    <div className={`${selectedUser ? 'lg:w-3/4' : 'lg:w-full'} lg:min-h-screen overflow-x-auto`}>
                        {loading_ ? (
                            <SpinnerComp />
                        ) : (
                                <div className="border border-primary-blue-90 rounded-2xl p-4 mb-4 overflow-x-auto min-w-[800px]">
                                    {members.map((user: any, index: any) => (
                                    <React.Fragment key={user?.id}>
                                        <FollowerItem
                                            {...user}
                                            showRemoveButton={false}
                                            allMembersPage={true}
                                            onRemove={() => handleRemoveFollowing(user.id)}
                                            // onCigarClick={() => { onSelectUser(user.id) }}
                                            isFollower={user.is_follower}
                                            onFollow={() => handleAddFollowing(user.id)}
                                            onUnfollow={() => handleRemoveFollowing(user.id)}
                                            onClick={() => onSelectUser(user.id)}
                                            loading={loading}

                                        />
                                            {index !== members.length - 1 && (
                                            <hr className='border-t border-primary-blue-90 mt-4 mb-4' />
                                            )}
                                    </React.Fragment>
                                ))}
                                {members.length === 0 && (
                                    <EmptyScreenComp
                                            title='No Members Found'
                                    />
                                )}
                            </div>
                        )}
                        {totalPages > 1 && !loading_ && (
                            <PaginationComp
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}
                            />
                        )}
                    </div>

                    {/* Right Panel (Desktop, hidden on mobile) */}
                    {selectedUser ? (
                        <div className="lg:block lg:w-1/4 lg:ml-auto lg:sticky lg:top-0">
                            <RightSide
                                selected={selected}
                                user={selectedUser}
                                setSelected={setSelectedUser}
                                topCigars={selectedUser.user_cigars}
                                isMobile={false}
                                refreshData={refreshData}
                            />
                        </div>
                    ) : null}
                    {/* Right Panel Content (visible on mobile) */}
                    {selectedUser ? (
                        <div className="lg:hidden order-2 mb-4">
                            <RightSide
                                selected={selected}
                                user={selectedUser}
                                setSelected={setSelectedUser}
                                topCigars={members}
                                isMobile={true}
                                title='User Cigars'
                                refreshData={refreshData}
                            />
                        </div>
                    ) : null}

                </div>
            </div>
        </DashboardLayout>
    )
}

export default AllMembers
