import React, { useState } from 'react'
import SwitchComp from '../SwitchComp'

interface InfoSectionProps {
  icon: React.ReactNode;
  text: string;
  isOn: boolean;
  onToggle: () => void;
}

const InfoSection: React.FC<InfoSectionProps> = ({ icon, text, isOn, onToggle }) => (
  <div className='flex justify-between items-center bg-primary-blue-90 p-2 rounded-2xl'>
    <div className='flex items-center space-x-4 text-white'>
      {icon}
      <span>{text}</span>
    </div>
    <SwitchComp isOn={isOn} handleToggle={onToggle} />
  </div>
);

export default InfoSection;
