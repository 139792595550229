import React, { useEffect } from 'react';
import ModalComp from '../ModalComp';
import ButtonComp from '../ButtonComp';
import SpinnerComp from '../SpinnerComp';
import ProductDetail from '../JournalEntry/ProductDetail';
import JournalDetail from '../JournalEntry/JournalDetail';

interface CigarModalProps {
    isOpen: boolean;
    onClose: any;
    cigar: any;
    onSave: any;
    useHomeModel: any;
    defaultStep?: number;
}

const AddCigarModal: React.FC<CigarModalProps> = ({ isOpen, onClose, cigar, onSave, useHomeModel, defaultStep }) => {
    const {
        cigarCode,
        setCigarCode,
        selectedCigar,
        setSelectedCigar,
        experience,
        setExperience,
        isLocationPublic,
        setIsLocationPublic,
        flavourRating,
        setFlavourRating,
        aromaRating,
        setAromaRating,
        overallRating,
        setOverallRating,
        drawRating,
        setDrawRating,
        burnRating,
        setBurnRating,
        product,
        success,
        showLocation,
        setShowLocation,
        uselocation,
        location,
        loader,
        description,
        setDescription,
        picturePreview,
        fileInputRef,
        handleFileChange,
        handleUploadClick,
        handleDeletePicture,
        showTryAgain,
        setlocation,
        setLatitude,
        setLongitude,
        name,
        setName,
        appearanceRating,
        setAppearanceRating,
        smokingTime,
        setSmokingTime,
        smokeThisAgain,
        setSmokeThisAgain,
        recommendToFriend,
        setRecommendToFriend,
        buyInBox,
        setBuyInBox,
        journalImages,
        setJournalImages,
        handleJournalImageUpload,
        handleJournalImageDelete,
        brandName,
        setBrandName,
        vitola,
        setVitola,
        showPlacesSearch,
        setShowPlacesSearch,
        handlelocationClick,
        setUselocation,
        handleHomeLocationClick,
        addCigar,
        locationLoading,
        homeLocation,
        handlePlaceSelect,
        setProduct,
        back,
        locationError,
        setPicturePreview,
        createdCigar,
        step,
        setStep,
        useSearchLocation,
        useCurrentLocation,
        placeSearch,
        setUseCurrentLocation,
        setPlaceSearch
    } = useHomeModel(isOpen, onClose, !!cigar);

    // if defaultStep exists set the step to it
    useEffect(() => {
        if (defaultStep) {
            setStep(defaultStep);
        }
    }, [defaultStep]);

    const [isStoicCigar, setIsStoicCigar] = React.useState(true);

    useEffect(() => {
        if (cigar) {
            let non_stoic = cigar.product_is_non_stoic ?? true
            setIsStoicCigar(non_stoic)
        }
    }, [isOpen, cigar]);

    const handleSave = async () => {
        console.log ("Handle Save", cigar, createdCigar)
        if (cigar || createdCigar) {
            let id = cigar ? cigar.id : createdCigar.id;
            await addCigar(id);
            setStep(3);
        } else {
            await addCigar();
        }
        console.log ("Handle Save", (onSave && typeof onSave === 'function'), " onSave", onSave )
        try {
            onSave();
            console.log ("Handle Save Done")
        } catch (_) {
            console.log ("Handle Save Error")
        }
    };

    return (
        <ModalComp
            isOpen={isOpen}
            onClose={() => onClose(false)}
            title={cigar ? 'Save Experience' : !cigarCode ? 'Cigar Journal Entry' : 'Add Stoic Cigar Entry'}
        >
            {/* Statues */}

            {
                step == 2 && (
                    <ProductDetail
                        cigarCode={cigarCode}
                        selectedCigar={selectedCigar}
                        setSelectedCigar={setSelectedCigar}
                        product={product}
                        description={description}
                        setDescription={setDescription}
                        name={name}
                        setName={setName}
                        brandName={brandName}
                        setBrandName={setBrandName}
                        vitola={vitola}
                        setVitola={setVitola}
                        isStoicCigar={isStoicCigar}
                    />
                )
            }

            {
                step == 3 && (
                    <JournalDetail
                        experience={experience}
                        setExperience={setExperience}
                        flavourRating={flavourRating}
                        setFlavourRating={setFlavourRating}
                        aromaRating={aromaRating}
                        setAromaRating={setAromaRating}
                        overallRating={overallRating}
                        setOverallRating={setOverallRating}
                        drawRating={drawRating}
                        setDrawRating={setDrawRating}
                        burnRating={burnRating}
                        setBurnRating={setBurnRating}
                        appearanceRating={appearanceRating}
                        setAppearanceRating={setAppearanceRating}
                        smokingTime={smokingTime}
                        setSmokingTime={setSmokingTime}
                        smokeThisAgain={smokeThisAgain}
                        setSmokeThisAgain={setSmokeThisAgain}
                        recommendToFriend={recommendToFriend}
                        setRecommendToFriend={setRecommendToFriend}
                        buyInBox={buyInBox}
                        setBuyInBox={setBuyInBox}
                        journalImages={journalImages}
                        handleJournalImageUpload={handleJournalImageUpload}
                        handleJournalImageDelete={handleJournalImageDelete}
                        locationLoading={locationLoading}
                        showLocation={showLocation}
                        setShowLocation={setShowLocation}
                        location={location}
                        handlePlaceSelect={handlePlaceSelect}
                        showPlacesSearch={showPlacesSearch}
                        handlelocationClick={handlelocationClick}
                        handleHomeLocationClick={handleHomeLocationClick}
                        homeLocation={homeLocation}
                        brandName={brandName}
                        name={name}
                        vitola={vitola}
                        isInProgress={cigar ? cigar.in_progress : false}
                        placeSearch={placeSearch}
                        useCurrentLocation={useCurrentLocation}
                    />
                )
            }

            {/* Loader and Buttons */}
            <div className='flex gap-6 mt-4'>
                {
                    loader && (
                        <SpinnerComp />
                    )
                }
                {
                    !success && !loader && (
                        <>
                            {
                                step == 2 && (
                                    <ButtonComp
                                        onClick={() => back()}
                                        backgroundColor='bg-transparent'
                                        textColor='text-secondary-gray'
                                    >
                                        Back
                                    </ButtonComp>
                                )}
                            {
                                !cigar && step == 3 && (
                                    <ButtonComp
                                        onClick={() => back(true)}
                                        backgroundColor='bg-transparent'
                                        textColor='text-secondary-gray'
                                    >
                                        Exit
                                    </ButtonComp>
                                )}

                            <ButtonComp onClick={handleSave} loading={locationLoading}>
                                {
                                    step == 2 ? "Add Entry" : 'Save Entry'
                                }
                            </ButtonComp>
                        </>
                    )
                }
            </div>
        </ModalComp>
    );
};

export default AddCigarModal;
