import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/layouts/DashboardLayout';
import AccountHeader from '../../../components/Account/AccountHeader';
import FollowerItem from '../../../components/Dashboard/Account/FollowerItem';
import SpinnerComp from '../../../components/SpinnerComp';
import useFollower from '../../../hooks/useFollower'
import EmptyScreenComp from '../../../components/EmptyScreenComp'
import { useNavigate } from 'react-router-dom';
import { useModalStore } from '../../../store/useModalStore'
import HomeModals from '../Modals/HomeModals'
import RightSide from '../../../components/Dashboard/RightSide'

const AccountFollowers: React.FC = () => {
  const { followers, loading, fetchFollowers,
    setSelected,
    onSelectUser,
    selectedUser,
    setSelectedUser,
    cigarSelected,
    setCigarSelected,
    cigar,
    setCigar,
    selected,
    onSelectUserFollower
  } = useFollower()

  useEffect(() => {
    fetchFollowers();
  }, []);
  const navigate = useNavigate();
  const { modalStates, updateModalState } = useModalStore();

  return (
    <DashboardLayout title='Followers' description=''>
      <div className="mb-48 md:mb-12">
        {/* Add cigar */}
        <AccountHeader
          title="Followers"
          onAddPress={() => updateModalState('isModalOpen', true)}
          menuSelected='followers'
        />
        <HomeModals
          {...modalStates}
          setIsModalOpen={(value) => updateModalState('isModalOpen', value)}
          setIsCigarModalOpen={(value) => updateModalState('isCigarModalOpen', value)}
        />
        {/* Content Layout for Right Panel (Desktop) */}
        <div className="flex flex-col lg:flex-row">
          {/* Main Content (left side on desktop) */}
          <div className={`${selectedUser ? 'lg:w-3/4' : 'lg:w-full'} lg:min-h-screen overflow-x-auto`}>
        {loading ? (
          <SpinnerComp />
        ) : (
                <div className="border border-primary-blue-90 rounded-2xl p-4 mb-4  overflow-x-auto min-w-[800px]">
            {followers.map((follower:any, index) => (
              <React.Fragment key={follower.id}>
                <FollowerItem
                  {...follower}
                  profile_picture={follower.picture}
                  onCigarClick={() => { onSelectUserFollower(follower.id) }}
                />
                {index !== followers.length - 1 && (
                  <hr className="border-t border-primary-blue-90 mt-4 mb-4" />
                )}
              </React.Fragment>
            ))}
            {followers.length === 0 && (
              <EmptyScreenComp title="No Followers" />
            )}
          </div>
        )}
      </div>

          {selectedUser ? (
            <>
              {/* Right Panel Content (visible on large screens) */}
              <div className="lg:block lg:w-1/4 lg:ml-auto lg:sticky lg:top-0">
                <RightSide
                  selected={selected}
                  user={selectedUser}
                  setSelected={setSelectedUser}
                  topCigars={cigar}
                  isMobile={false}
                  isFollowerPage={true}
                />
              </div>

              {/* Right Panel Content (visible on mobile) */}
              <div className="lg:hidden order-2 mb-4">
                <RightSide
                  selected={selected}
                  user={selectedUser}
                  setSelected={setSelectedUser}
                  topCigars={cigar}
                  isMobile={true}
                  title="User Cigars"
                  isFollowerPage={true}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AccountFollowers;
