import React, { InputHTMLAttributes, useState } from 'react'
import { Eye, EyeOff } from 'lucide-react'

interface InputType {
  value?: string
  onChange?: any
  placeholder: string
  type?: string
  [key: string]: any
  onEnter?: any
}

const InputComp: React.FC<InputType> = ({
  value,
  onChange,
  placeholder,
  type = 'text',
  onEnter,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const isPassword = type === 'password'

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div className='relative'>
      <input
        className='w-full bg-primary-blue-90 border-none outline-none bg-primary-blue-90 rounded-2xl flex flex-col items-start justify-start py-3 px-5 box-border font-text-md-medium text-sm text-white'
        placeholder={placeholder}
        type={isPassword && showPassword ? 'text' : type}
        value={value}
        onChange={e => onChange(e.target.value)}
        {...props}
        onKeyDown={e => {
          if (e.key === 'Enter' && onEnter) {
            onEnter();
          }
        }}
      />
      {isPassword && (
        <button
          type='button'
          onClick={togglePasswordVisibility}
          className='absolute right-3 top-1/2 transform -translate-y-1/2 text-white'
        >
          {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
        </button>
      )}
    </div>
  )
}

export default InputComp
