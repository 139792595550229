import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useUserStore } from '../../store/userStore';

const ProtectedRoutes = () => {
  const user = useUserStore(state => state.user);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has('addcigar')) {
      sessionStorage.setItem('addcigar', params.get('addcigar')!);
    }
  }, [location]);

  return user ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;
