import React, { useState, useEffect } from 'react';
import { Star } from 'lucide-react';


const RatingComp: React.FC<any> = ({ disabled = false, onChange, showTotal = false, defaultRating = 0 }) => {
  const [rating, setRating] = useState(defaultRating);

  const handleRating = (value: any) => {
    if (!disabled) {
      setRating(value);
    }
  };

  useEffect(() => {
    if (onChange) {
      onChange(rating);
    }
  }, [rating, onChange]);

  return (
    <div className="flex items-center space-x-1">
      {[1, 2, 3, 4, 5].map((value) => (
        <button
          key={value}
          onClick={() => handleRating(value)}
          disabled={disabled}
          className={`focus:outline-none ${disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
        >
          <Star
            size={24}
            fill={value <= rating ? 'gold' : 'none'}
            stroke={value <= rating ? 'gold' : 'white'}
            className={`${disabled ? '' : 'hover:fill-gold hover:stroke-gold'} transition-colors duration-200`}
          />
        </button>
      ))}
      {
        showTotal && (<span className="ml-2 text-sm">{rating}/5</span>)
      }
    </div>
  );
};

export default RatingComp;
