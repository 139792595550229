import React, { useState } from "react";
import ButtonComp from "../components/ButtonComp";
import InputComp from "../components/InputComp";

const Coming = () => {
  const [email, setEmail] = useState("");

  return (
    <div className="w-full min-h-screen relative bg-primary-blue-80 overflow-hidden flex flex-col items-center justify-center px-4 py-8 md:py-16 text-center text-white font-text-md-medium1">
      <img
        className="absolute md:top-0 mt-auto     !mx-auto object-cover opacity-[0.02] z-0"
        alt=""
        src="/logo/logomark.svg"
      />
      <div className="w-full max-w-md flex flex-col items-center justify-start gap-8 z-10">
        <img
          className="w-16 h-16 md:w-24 md:h-24"
          loading="lazy"
          alt=""
          src="/logo/logomark-1.svg"
        />
        <div className="flex flex-col items-center justify-start gap-4 w-full">
          <h1 className="text-3xl md:text-5xl font-medium">
            Coming Soon
          </h1>
          <p className="text-sm md:text-base text-greyscale-301">
            In the meantime sign up to our Waiting list so you'll be one of
            the first to enjoy our product.
          </p>
        </div>
        <div className="w-full flex flex-col items-start justify-start gap-4">
          <label className="self-start text-sm md:text-base">
            Email Address
          </label>
          <div className="w-full bg-primary-blue-90 rounded-2xl overflow-hidden">
            <InputComp
              value={email}
              type="email"
              placeholder="Enter your email address"
            />
          </div>
          <ButtonComp>Join Waitlist</ButtonComp>
        </div>
      </div>
    </div>
  );
};

export default Coming;
