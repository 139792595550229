import React, { useEffect } from 'react';
import InputComp from '../InputComp';
import TextareaComp from '../TextareaComp';
import RatingComp from '../RatingComp';
import SwitchComp from '../SwitchComp';
import { Locate, Upload, X } from 'lucide-react';
import PlacesSearchComp from '../Google/PlacesSearchComp';
import RangeSliderComp from '../RangeSliderComp';
import { Address } from '../../types/address';
import { parseAddress } from '../../utils/parseAddress';

interface JournalDetailProps {
    experience: string,
    setExperience: (experience: string) => void,
    flavourRating: number,
    setFlavourRating: (flavourRating: number) => void,
    aromaRating: number,
    setAromaRating: (aromaRating: number) => void,
    overallRating: number,
    setOverallRating: (overallRating: number) => void,
    drawRating: number,
    setDrawRating: (drawRating: number) => void,
    burnRating: number,
    setBurnRating: (burnRating: number) => void,
    showLocation: boolean,
    setShowLocation: (showLocation: boolean) => void,
    location: Address,
    appearanceRating: number,
    setAppearanceRating: (appearanceRating: number) => void,
    smokingTime: any,
    setSmokingTime: (smokingTime: any) => void,
    smokeThisAgain: number,
    setSmokeThisAgain: (smokeThisAgain: number) => void,
    recommendToFriend: number,
    setRecommendToFriend: (recommendToFriend: number) => void,
    buyInBox: number,
    setBuyInBox: (buyInBox: number) => void,
    journalImages: any[],
    handleJournalImageUpload: () => void,
    handleJournalImageDelete: (index: number) => void,
    showPlacesSearch: boolean,
    handlelocationClick: () => void,
    handleHomeLocationClick: () => void,
    locationLoading: boolean,
    homeLocation: boolean,
    handlePlaceSelect: (place: any) => void,
    brandName: string,
    name: string,
    vitola: string,
    isInProgress?: boolean
    useCurrentLocation: boolean,
    placeSearch: boolean,
    setUseCurrentLocation?: (useCurrentLocation: boolean) => void,
    setPlaceSearch?: (placeSearch: boolean) => void,
}

const JournalDetail: React.FC<JournalDetailProps> = ({ experience,
    setExperience,
    flavourRating,
    setFlavourRating,
    aromaRating,
    setAromaRating,
    overallRating,
    setOverallRating,
    drawRating,
    setDrawRating,
    burnRating,
    setBurnRating,
    showLocation,
    setShowLocation,
    useCurrentLocation,
    location,
    appearanceRating,
    setAppearanceRating,
    smokingTime,
    setSmokingTime,
    smokeThisAgain,
    setSmokeThisAgain,
    recommendToFriend,
    setRecommendToFriend,
    buyInBox,
    setBuyInBox,
    journalImages,
    handleJournalImageUpload,
    handleJournalImageDelete,
    showPlacesSearch,
    handlelocationClick,
    handleHomeLocationClick,
    locationLoading,
    homeLocation,
    handlePlaceSelect,
    brandName,
    name,
    vitola,
    isInProgress,
    placeSearch,
    setUseCurrentLocation,
    setPlaceSearch
}) => {
    return (

        <>

            {!isInProgress && (
                <>
                    <div className="mt-2">
                        <label className='block text-left text-sm font-medium text-white opacity-70 mb-1'>
                            Brand Name
                        </label>
                        <InputComp
                            label='Brand'
                            placeholder='Enter Brand'
                            value={brandName}
                            disabled={true}
                        />
                    </div>
                    <div className="mt-2">
                        <label className='block text-left text-sm font-medium text-white opacity-70 mb-1'>
                            Cigar Name
                        </label>
                        <InputComp
                            label='Name'
                            placeholder='Enter Name'
                            value={name}
                            disabled={true}
                        />
                    </div>
                    <div className="mt-2">
                        <label className='block text-left text-sm font-medium text-white opacity-70 mb-1'>
                            Vitola
                        </label>
                        <InputComp
                            label='Vitola'
                            placeholder='Enter Vitola'
                            value={vitola}
                            disabled={true}
                        />
                    </div>
                </>
            )}
            {/* Rating */}
            <div className='mt-2'>
                <p className='block text-left text-sm font-medium text-white opacity-70 mb-1'>
                    Rating
                </p>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>

                    {[
                        { label: 'Appearance', value: appearanceRating, setValue: setAppearanceRating },
                        { label: 'Draw', value: drawRating, setValue: setDrawRating },
                        { label: 'Aroma', value: aromaRating, setValue: setAromaRating },
                        { label: 'Burn', value: burnRating, setValue: setBurnRating },
                        { label: 'Flavor', value: flavourRating, setValue: setFlavourRating },
                        { label: 'Overall', value: overallRating, setValue: setOverallRating },
                    ].map((item, index) => (
                        <div
                            key={index}
                            className='flex justify-between bg-primary-blue-90 p-2 rounded-2xl'
                        >
                            <label className='text-left text-sm font-medium text-white opacity-70 mb-1'>
                                {item.label}
                            </label>
                            <RatingComp defaultRating={item.value} onChange={item.setValue} />
                        </div>
                    ))}
                </div>

            </div>

            {/* Smoking Time */}
            <div className='mt-2'>
                <label className='block text-left text-sm font-medium text-white opacity-70 mb-1'>
                    Smoking Time (Minutes)
                </label>
                <InputComp
                    placeholder='Enter Smoking time in minutes'
                    value={smokingTime}
                    onChange={setSmokingTime}
                    type="number"
                />
            </div>

            {/* Experience */}
            <div className='mt-2'>
                <label className='block text-left text-sm font-medium text-white opacity-70 mb-1'>
                    Experience
                </label>
                <TextareaComp
                    placeholder='Enter your experience'
                    value={experience}
                    onChange={setExperience}
                    rows={2}
                />
            </div>

            {/* Slider and questioons */}
            <p className='block text-left text-sm font-medium text-white opacity-70 mb-1 mt-2'>
                On a Scale of 1-5, with 1 being Extremely Unlikely and 5 being Extremely Likely, how likely are you to:
            </p>
            {[
                { label: 'Smoke this cigar again', onChange: setSmokeThisAgain, defaultValue: smokeThisAgain },
                { label: 'Recommend this cigar to a friend', onChange: setRecommendToFriend, defaultValue: recommendToFriend },
                { label: 'Buy a box of this cigar', onChange: setBuyInBox, defaultValue: buyInBox },
            ].map((item, index) => (
                <div key={index} className='mt-2 grid grid-cols-2'>
                    <div className='col-span-1 mt-2'>
                        <label className='block text-left text-sm font-medium text-white opacity-70 mb-1'>
                            {item.label}
                        </label>
                    </div>
                    <div className='col-span-1'>
                        <RangeSliderComp onChange={item.onChange} defaultValue={item.defaultValue} />
                    </div>
                </div>
            ))}

            {/* Location */}
            <div className='mt-2'>
                <p className='block text-left text-sm font-medium text-white opacity-70 mb-1'>
                    Where Are You Smoking?
                </p>
                <div className='col-span-1 md:mt-8'>
                    <div className='flex justify-between items-center bg-primary-blue-90 p-2 rounded-2xl'>
                        <div className='flex items-center space-x-4 text-white'>
                            <Locate />
                            <span>Show Location</span>
                        </div>
                        <SwitchComp
                            isOn={showLocation}
                            handleToggle={() => setShowLocation(!showLocation)}
                        />
                    </div>
                    {showLocation && (
                        <div className='mt-2'>
                            <div className='flex justify-between items-center bg-primary-blue-90 p-2 rounded-2xl'>
                                <span className='text-white'>

                                    {location && useCurrentLocation
                                        ? parseAddress(location)
                                        : locationLoading
                                            ? 'Loading...'
                                            : "Use Your Current Device Location"}
                                </span>
                                <SwitchComp
                                    isOn={useCurrentLocation}
                                    handleToggle={handlelocationClick}
                                />
                            </div>
                            {true && (
                                <>
                                    <p className='block text-left text-sm font-medium text-white opacity-70 mb-1 mt-2'>
                                        Add Location Manually
                                    </p>

                                    <p className='mt-2 text-red-500 text-sm'></p>
                                    <PlacesSearchComp onPlaceSelect={handlePlaceSelect} />
                                </>
                            )}
                            <div className='flex justify-between items-center bg-primary-blue-90 p-2 mt-2 rounded-2xl'>
                                <span className='text-white'>
                                    {location && homeLocation
                                        ? parseAddress(location)
                                        : (locationLoading && homeLocation)
                                            ? 'Loading...'
                                            : "Use Your Home Location"}
                                </span>
                                <SwitchComp
                                    isOn={homeLocation}
                                    handleToggle={handleHomeLocationClick}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>


            {/* Journal Images... */}
            <div className="mt-4">
                <label className="block text-left text-sm font-medium text-white opacity-70 mb-1">
                    Journal Images
                </label>
                <div className="flex flex-wrap gap-2">
                    {journalImages.map((image: any, index: number) => (
                        <div key={index} className="relative">
                            <img src={image.preview} alt={`Journal ${index + 1}`} className="w-24 h-24 object-cover" />
                            <button
                                onClick={() => handleJournalImageDelete(index)}
                                className="absolute top-0 right-0 bg-red-500 rounded-full p-1"
                            >
                                <X size={16} color="white" />
                            </button>
                        </div>
                    ))}
                    <button
                        onClick={handleJournalImageUpload}
                        className="w-24 h-24 flex items-center justify-center border-2 border-dashed border-gray-300 rounded-lg"
                    >
                        <Upload size={24} color="gray" />
                    </button>
                </div>
            </div>
        </>
    );
};

export default JournalDetail;
