import React from 'react';

interface TabButtonProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
}

const TabButton: React.FC<TabButtonProps> = ({ label, isActive, onClick }) => (
  <button
    className={`cursor-pointer [border:none] py-3 px-[59px] rounded-2xl flex flex-row items-start justify-start ${
      isActive ? 'bg-secondary-gray' : 'bg-primary-blue-90'
    }`}
    onClick={onClick}
  >
    <span
      className={`[text-decoration:none] relative text-sm leading-[20px] font-medium font-text-md-medium1 text-left ${
        isActive ? 'text-gray' : 'text-[#536A72]'
      }`}
    >
      {label}
    </span>
  </button>
);

export default TabButton;
