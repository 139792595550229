import React from 'react';
import { Album } from 'lucide-react';
import RatingComp from '../../../components/RatingComp';

interface JournalEntryDetails {
    selectedRating: any;
}

const JournalEntryDetails: React.FC<JournalEntryDetails> = ({ selectedRating }) => {
    return (
        <div>

            {/* Experience Section */}
            <div className='p-4' >
                <label className="mt-4 block text-left text-sm font-medium text-white opacity-70 mb-1">
                    Experience
                </label>
                <p className="!text-white bg-primary-blue-90 p-4 text-center mt-2 mb-4 rounded-2xl">
                    {selectedRating?.experience_note || 'No experience available'}
                </p>

                <div className='mt-2'>
                    <p className='block text-left text-sm font-medium text-white opacity-70 mb-1'>
                        Rating
                    </p>

                    <div className='grid grid-cols-1 md:grid-cols-1 gap-4'>
                        <div
                            className='flex justify-between bg-primary-blue-90 p-2 rounded-2xl'
                        >
                            <label className='text-left text-sm font-medium text-white opacity-70 mb-1'>
                                Flavour
                            </label>
                            <RatingComp defaultRating={selectedRating?.flavour_rating || 0} disabled={true} />
                        </div>
                        <div
                            className='flex justify-between bg-primary-blue-90 p-2 rounded-2xl'
                        >
                            <label className='text-left text-sm font-medium text-white opacity-70 mb-1'>
                                Aroma
                            </label>
                            <RatingComp defaultRating={selectedRating?.aroma_rating || 0} disabled={true} />
                        </div>
                        <div
                            className='flex justify-between bg-primary-blue-90 p-2 rounded-2xl'
                        >
                            <label className='text-left text-sm font-medium text-white opacity-70 mb-1'>
                                Aroma
                            </label>
                            <RatingComp defaultRating={selectedRating?.aroma_rating || 0} disabled={true} />
                        </div>
                        <div
                            className='flex justify-between bg-primary-blue-90 p-2 rounded-2xl'
                        >
                            <label className='text-left text-sm font-medium text-white opacity-70 mb-1'>
                                Burn
                            </label>
                            <RatingComp defaultRating={selectedRating?.burn_rating || 0} disabled={true} />
                        </div>
                        <div
                            className='flex justify-between bg-primary-blue-90 p-2 rounded-2xl'
                        >
                            <label className='text-left text-sm font-medium text-white opacity-70 mb-1'>
                                Appearance
                            </label>
                            <RatingComp defaultRating={selectedRating?.appearance_rating || 0} disabled={true} />
                        </div>

                        <div
                            className='flex justify-between bg-primary-blue-90 p-2 rounded-2xl'
                        >
                            <label className='text-left text-sm font-medium text-white opacity-70 mb-1'>
                                Overall rating
                            </label>
                            <RatingComp defaultRating={selectedRating?.rating || 0} disabled={true} />
                        </div>
                    </div>
                </div>

            </div>

            {/* Photos Section */}
            <div className='flex flex-col mt-4 p-4'>
                <div className='flex items-center justify-between'>
                    <h2 className='text-md font-semibold text-left text-gray-light'>
                        Photos
                    </h2>
                    <div className='flex items-center space-x-2'>
                        <span className='text-md font-semibold text-gray-light'>
                            {selectedRating?.journal_images?.length ?? 0}
                        </span>
                        <Album className='w-4 h-4 text-gray-light' />
                    </div>
                </div>
                {selectedRating?.journal_images?.length > 0 ?
                    <div className='flex flex-wrap mt-4 space-x-2 space-y-2 p-2'>
                        {selectedRating?.journal_images.map((image: any, index: number) => (
                            <img
                                key={index}
                                src={image.s3_url}
                                className='w-24'
                                alt='Journal Detail'
                            />
                        ))}
                    </div> : <p className='text-center text-gray-400 bg-primary-blue-80 py-2 px-4 rounded-lg mt-4'>
                        No photos available
                    </p>}
            </div>
        </div>
    );
};

export default JournalEntryDetails;