import React from 'react'
import SwitchComp from '../SwitchComp'
import { Plus } from 'lucide-react'
import ButtonComp from '../ButtonComp'
import SelectComp from '../SelectComp'
import { useNavigate } from 'react-router-dom'


interface AccountHeaderType {
  title?: string
  showNavigation?: boolean
  navigation?: string
  children?: any,
  onAddPress?: any,
  onMenuChange?: (selectedMenu: string) => void;
  menuSelected?: string;
}

const AccountHeader: React.FC<AccountHeaderType> = ({
  title = 'My Account',
  showNavigation = true,
  navigation = 'account',
  children,
  onAddPress,
  onMenuChange,
  menuSelected
}) => {
  let menu: any = [
    { value: 'account', label: 'Account' },
    { value: 'password', label: 'Password' },
    { value: 'followers', label: 'Followers' },
    { value: 'following', label: 'Following' }
  ]
  if (navigation == 'journal') {
    menu = [
      { value: '-created_at', 'label': 'Newest logs' },
      { value: 'created_at', 'label': 'Oldest logs' },
      { value: '-rating', label: 'Highest rating' },
      { value: 'rating', label: 'Lowest rating' }
    ]
  }
  const [selectedMenu, setSelectedMenu] = React.useState(menuSelected?.toString() || menu[0].value);

  const [initialLoad, setInitialLoad] = React.useState(true)

  const navigate = useNavigate()

  React.useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false)
    } else {
      performNavigation()
      if (onMenuChange) {
        onMenuChange(selectedMenu);
      }
    }
  }, [selectedMenu, onMenuChange])

  const performNavigation = () => {
    if (navigation != 'account') return;
    switch (selectedMenu) {
      case 'account':
        navigate('/dashboard/account')
        break
      case 'password':
        navigate('/dashboard/account/password')
        break
      case 'followers':
        navigate('/dashboard/account/followers')
        break
      case 'following':
        navigate('/dashboard/account/following')
        break
      default:
        break
    }
  }

  return (
    <header className='bg-primary-blue-80 mb-4'>
      <div className='p-4'>
        {/* Mobile layout */}
        <div className='sm:hidden space-y-4'>
          <div className='flex items-center justify-between'>
            <div className='flex items-center space-x-2'>
              <img
                src='/icons/cigar.svg'
                alt='Cigar Icon'
                className='w-12 h-12'
              />
              <h1 className='text-xl text-white font-semibold'>{title}</h1>
            </div>
            <div className='hidden md:block'>
              <ButtonComp
                fullWidthOnMobile={false}
                backgroundColor='bg-transparent text-secondary-gray border-1 border-secondary-gray'
                textColor='text-secondary-gray'
                onClick={onAddPress}
              >
                <Plus size={16} /> <span className='ml-1'>Add Cigar</span>
              </ButtonComp>
            </div>
            {/* Circle button */}
            <button className='border border-secondary-gray w-10 h-10 rounded-full flex items-center justify-center' onClick={onAddPress}>
              <Plus size={16} className='text-secondary-gray' />
            </button>
          </div>
          <div className='flex items-center space-x-4'>
            {children}
            {showNavigation && (
              <SelectComp
                value={selectedMenu}
                onChange={setSelectedMenu}
                options={menu}
              />
            )}
          </div>
        </div>

        {/* Desktop layout */}
        <div className='hidden sm:flex items-center justify-between'>
          <div className='flex-1'>
            <h1 className='text-xl text-left text-white font-semibold'>
              {title}
            </h1>
          </div>
          <div className='flex items-center space-x-4 justify-end'>
            {children}
            {showNavigation && (
              <SelectComp
                value={selectedMenu}
                onChange={setSelectedMenu}
                options={menu}
              />
            )}
            <div>
              <ButtonComp
                fullWidthOnMobile={false}
                backgroundColor='bg-secondary-gray'
                textColor='text-primary-blue-80'
                onClick={onAddPress}
              >
                <Plus /> <span className='ml-2'>Log Cigar</span>
              </ButtonComp>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default AccountHeader
