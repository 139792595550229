import React, { useEffect } from 'react';
import ModalComp from '../ModalComp';
import InputComp from '../InputComp';
import ButtonComp from '../ButtonComp';
import SelectComp from '../SelectComp';
import TextareaComp from '../TextareaComp';
import RatingComp from '../RatingComp';
import SwitchComp from '../SwitchComp';
import { Locate, Upload, Edit, X } from 'lucide-react';
import SpinnerComp from '../SpinnerComp';
import PlacesSearchComp from '../Google/PlacesSearchComp';
import RangeSliderComp from '../RangeSliderComp';
import { cigars } from '../../consts/home';
import ProductDetail from '../JournalEntry/ProductDetail';
import JournalDetail from '../JournalEntry/JournalDetail';

interface CigarModalProps {
  isOpen: boolean;
  onClose: any;
  cigar: any;
  onSave: any;
  useHomeModel: any;
}

const CigarModal: React.FC<CigarModalProps> = ({ isOpen, onClose, cigar, onSave, useHomeModel }) => {
  const {
    cigarCode,
    setCigarCode,
    selectedCigar,
    setSelectedCigar,
    experience,
    setExperience,
    isLocationPublic,
    setIsLocationPublic,
    flavourRating,
    setFlavourRating,
    aromaRating,
    setAromaRating,
    overallRating,
    setOverallRating,
    drawRating,
    setDrawRating,
    burnRating,
    setBurnRating,
    product,
    success,
    showLocation,
    setShowLocation,
    useCurrentLocation,
    location,
    loader,
    description,
    setDescription,
    picturePreview,
    fileInputRef,
    handleFileChange,
    handleUploadClick,
    handleDeletePicture,
    showTryAgain,
    setlocation,
    setLatitude,
    setLongitude,
    name,
    setName,
    appearanceRating,
    setAppearanceRating,
    smokingTime,
    setSmokingTime,
    smokeThisAgain,
    setSmokeThisAgain,
    recommendToFriend,
    setRecommendToFriend,
    buyInBox,
    setBuyInBox,
    journalImages,
    setJournalImages,
    handleJournalImageUpload,
    handleJournalImageDelete,
    brandName,
    setBrandName,
    vitola,
    setVitola,
    showPlacesSearch,
    setShowPlacesSearch,
    handlelocationClick,
    setUseCurrentLocation,
    handleHomeLocationClick,
    addCigar,
    locationLoading,
    homeLocation,
    handlePlaceSelect,
    setProduct,
    back,
    locationError,
    setPicturePreview,
    placeSearch,
  } = useHomeModel(isOpen, onClose, !!cigar);

  const [reload, setReload] = React.useState(false);
  const [isStoicCigar, setIsStoicCigar] = React.useState(true);
  useEffect(() => {
    if (cigar) {
      setPicturePreview(cigar.product_picture);
      setProduct({
        name: cigar.product_name,
        picture: cigar.product_picture,
        short_description: cigar.product_short_description,
        vitola: cigar.product_vitola,
        is_non_stoic: cigar.product_is_non_stoic,
        brand_name: cigar.product_brand_name
      });
      setSelectedCigar(cigar.product_name || "");
      setExperience(cigar.experience_note || "");
      setIsLocationPublic(cigar.is_location_public || false);
      setFlavourRating(cigar.flavour_rating);
      setAromaRating(cigar.aroma_rating);
      setDrawRating(cigar.draw_rating);
      setBurnRating(cigar.burn_rating);
      setAppearanceRating(cigar.appearance_rating);
      setOverallRating(cigar.overall_rating);
      setShowLocation(!!cigar.location);
      setUseCurrentLocation(!!cigar.latitude && !!cigar.longitude);
      setlocation(cigar.location || "");
      setLatitude(cigar.latitude || "");
      setLongitude(cigar.longitude || "");
      setDescription(cigar.product_short_description || "");
      setName(cigar.product_name || "");
      setSmokingTime(cigar.smoking_time || "");
      setSmokeThisAgain(cigar.smoke_this_again || 0);
      setRecommendToFriend(cigar.recommend_to_friend || 0);
      setBuyInBox(cigar.buy_in_box || 0);
      setBrandName(cigar.brand_name || "");
      setVitola(cigar.vitola || "");
      let non_stoic = product?.is_non_stoic ?? true
      setIsStoicCigar(non_stoic)
      // setPicturePreview(cigar.product_picture || null);
      const journalImageUrls = (cigar.journal_images || []).map((image: { s3_url: string }) => ({
        file: null,
        preview: image.s3_url
      }));
      setJournalImages(journalImageUrls);
      setReload(true)
    }
  }, [isOpen, cigar]);

  const handleSave = async () => {
    if (cigar) {
      await addCigar(cigar.id, cigar?.location?.id);
    } else {
      await addCigar();
    }
    if (onSave && typeof onSave === 'function') {
      onSave();
    }
  };

  return (
    <ModalComp
      isOpen={isOpen}
      onClose={() => onClose(false)}
      title={cigar ? 'Edit Journal Entry' : isStoicCigar ? 'Add Non-Stoic Cigar Entry' : 'Add Stoic Cigar Entry'}
    >

      {/* Product Detail/Edit */}
      <ProductDetail
        cigarCode={cigarCode}
        selectedCigar={selectedCigar}
        setSelectedCigar={setSelectedCigar}
        product={product}
        description={description}
        setDescription={setDescription}
        picturePreview={picturePreview}
        fileInputRef={fileInputRef}
        handleFileChange={handleFileChange}
        handleUploadClick={handleUploadClick}
        handleDeletePicture={handleDeletePicture}
        name={name}
        setName={setName}
        brandName={brandName}
        setBrandName={setBrandName}
        vitola={vitola}
        setVitola={setVitola}
        isStoicCigar={isStoicCigar}
      />
    
      {/* Journal Detail */}
      <JournalDetail
        experience={experience}
        setExperience={setExperience}
        flavourRating={flavourRating}
        setFlavourRating={setFlavourRating}
        aromaRating={aromaRating}
        setAromaRating={setAromaRating}
        overallRating={overallRating}
        setOverallRating={setOverallRating}
        drawRating={drawRating}
        setDrawRating={setDrawRating}
        burnRating={burnRating}
        setBurnRating={setBurnRating}
        appearanceRating={appearanceRating}
        setAppearanceRating={setAppearanceRating}
        smokingTime={smokingTime}
        setSmokingTime={setSmokingTime}
        smokeThisAgain={smokeThisAgain}
        setSmokeThisAgain={setSmokeThisAgain}
        recommendToFriend={recommendToFriend}
        setRecommendToFriend={setRecommendToFriend}
        buyInBox={buyInBox}
        setBuyInBox={setBuyInBox}
        journalImages={journalImages}
        handleJournalImageUpload={handleJournalImageUpload}
        handleJournalImageDelete={handleJournalImageDelete}
        locationLoading={locationLoading}
        showLocation={showLocation}
        setShowLocation={setShowLocation}
        useCurrentLocation={useCurrentLocation}
        location={location}
        handlePlaceSelect={handlePlaceSelect}
        showPlacesSearch={showPlacesSearch}
        handlelocationClick={handlelocationClick}
        handleHomeLocationClick={handleHomeLocationClick}
        homeLocation={homeLocation}
        brandName={brandName || product?.brand_name}
        name={name || product?.name}
        vitola={vitola || product?.vitola}
        placeSearch={placeSearch}
      />

      {/* Loader and Buttons */}
      <div className='flex gap-6 mt-4'>
        {
          loader && (
            <SpinnerComp />
          )
        }
        {
          !success && !loader && (
            <>
              {
                !cigar && (
                  <ButtonComp
                    onClick={back}
                    backgroundColor='bg-transparent'
                    textColor='text-secondary-gray'
                  >
                    Back
                  </ButtonComp>
                )}
              <ButtonComp onClick={handleSave} loading={locationLoading}>
                {
                  cigar ? 'Update' : 'Add'
                }
              </ButtonComp>
            </>
          )
        }
      </div>
    </ModalComp>
  );
};

export default CigarModal;
