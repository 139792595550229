import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../../components/layouts/DashboardLayout'
import AccountHeader from '../../../components/Account/AccountHeader'
import FollowerItem from '../../../components/Dashboard/Account/FollowerItem'
import SpinnerComp from '../../../components/SpinnerComp'
import useFollower from '../../../hooks/useFollower'
import EmptyScreenComp from '../../../components/EmptyScreenComp'
import { useNavigate } from 'react-router-dom';
import { useModalStore } from '../../../store/useModalStore'
import HomeModals from '../Modals/HomeModals'
import RightSide from '../../../components/Dashboard/RightSide'

const AccountFollowing: React.FC = () => {
  const { following, loading, fetchFollowing, handleRemoveFollowing, selected,
    setSelected,
    onSelectUser,
    selectedUser,
    setSelectedUser,
    cigarSelected,
    setCigarSelected,
    cigar,
    setCigar,
  } = useFollower()

  useEffect(() => {
    fetchFollowing()
  }, [])
  const navigate = useNavigate();
  const { modalStates, updateModalState } = useModalStore();

  const refreshData = () => {
    setSelectedUser(0)
    fetchFollowing()
  }

  return (
    <DashboardLayout title='Following' description=''>
      <div className='mb-48 md:mb-12'>
        {/* Add cigar */}
        <AccountHeader
          title="Following"
          onAddPress={() => updateModalState('isModalOpen', true)}
          menuSelected='following'
        />
        <HomeModals
          {...modalStates}
          setIsModalOpen={(value) => updateModalState('isModalOpen', value)}
          setIsCigarModalOpen={(value) => updateModalState('isCigarModalOpen', value)}
        />

        {/* Content Layout for Right Panel (Desktop) */}
        <div className="flex flex-col lg:flex-row lg:gap-4">
          {/* Main Content (left side on desktop) */}
          <div className={`${selectedUser ? 'lg:w-3/4' : 'lg:w-full'} lg:min-h-screen overflow-x-auto`}>
            {loading ? (
              <SpinnerComp />
            ) : (
                <div className="border border-primary-blue-90 rounded-2xl p-4 mb-4  overflow-x-auto min-w-[800px]">
                {following.map((follow: any, index) => (
                  <React.Fragment key={follow?.id}>
                    <FollowerItem
                      {...follow}
                      profile_picture={follow.picture}
                      showRemoveButton={true}
                      onRemove={() => handleRemoveFollowing(follow?.id)}
                      onCigarClick={() => { onSelectUser(follow.id) }}
                    />
                    {index !== following.length - 1 && (
                      <hr className="border-t border-primary-blue-90 mt-4 mb-4" />
                    )}
                  </React.Fragment>
                ))}
                {following.length === 0 && (
                  <EmptyScreenComp
                    title='Not Following Anyone'
                  />
                )}
              </div>
            )}
          </div>

          {/* Right Panel (Desktop, hidden on mobile) */}
          {selectedUser && (
            <div className="lg:block lg:w-1/4 lg:ml-auto lg:sticky lg:top-0">
              <RightSide
                selected={selected}
                user={selectedUser}
                setSelected={setSelectedUser}
                topCigars={cigar}
                isMobile={false}
                refreshData={refreshData}
              />
            </div>
          )}
          {/* Right Panel Content (visible on mobile) */}
          {selectedUser && (
            <div className="lg:hidden order-2 mb-4">
              <RightSide
                selected={selected}
                user={selectedUser}
                setSelected={setSelectedUser}
                topCigars={cigar}
                isMobile={true}
                title='User Cigars'
                refreshData={refreshData}
              />
            </div>
          )}
        </div>
      </div>

    </DashboardLayout>
  )
}

export default AccountFollowing
