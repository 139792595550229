import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../services/api";
import { ProductType } from "../types/product";
import { shouldNotEmpty } from "../utils/validator";
import useLocation from "./useLocation";
import { toast } from 'react-toastify';


interface JournalImage {
  file: File;
  preview: string;
}

const useHomeModel = (
  isModalOpen: boolean,
  setIsModalOpen: (open: boolean) => void,
  isCigarModalOpen: boolean,
  setIsCigarModalOpen: (open: boolean) => void,
  initialCigar: any = null
) => {
  const [cigarCode, setCigarCode] = useState("");
  const [selectedCigar, setSelectedCigar] = useState("");
  const [experience, setExperience] = useState("");
  const [appearanceRating, setAppearanceRating] = useState(0);
  const [overallRating, setOverallRating] = useState(0);
  const [flavourRating, setFlavourRating] = useState(0);
  const [aromaRating, setAromaRating] = useState(0);
  const [drawRating, setDrawRating] = useState(0);
  const [burnRating, setBurnRating] = useState(0);
  const [product, setProduct] = useState<ProductType | null>(null);
  const [success, setSuccess] = useState("");
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [smokingTime, setSmokingTime] = useState(5);
  const [smokeThisAgain, setSmokeThisAgain] = useState(1);
  const [recommendToFriend, setRecommendToFriend] = useState(1);
  const [buyInBox, setBuyInBox] = useState(1);
  const [picture, setPicture] = useState<File | null>(null);
  const [picturePreview, setPicturePreview] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [successModal, setSuccessModal] = useState(false);
  const [journalImages, setJournalImages] = useState<JournalImage[]>([]);
  const [brandName, setBrandName] = useState("");
  const [vitola, setVitola] = useState("");
  const [editingCigarId, setEditingCigarId] = useState(null);
  const [createdCigar, setCreatedCigar] = useState(null);
  const [step, setStep] = useState(2);
  const [locationId, setLocationId] = useState<number | null>(null);

  const navigate = useNavigate();

  const {
    isLocationPublic,
    setIsLocationPublic,
    showLocation,
    setShowLocation,
    location,
    setlocation,
    latitude,
    setLatitude,
    longitude,
    setLongitude,
    locationLoading,
    setLocationLoading,
    getlocation,
    getPlaceName,
    locationError,
    homeLocation,
    setHomeLocation,
    setShowPlacesSearch,
    handlelocationClick,
    showPlacesSearch,
    handlePlaceSelect,
    setShowTryAgain,
    showTryAgain,
    getHomeLocation,
    handleHomeLocationClick,
    useCurrentLocation,
    placeSearch,
    setUseCurrentLocation,
    setPlaceSearch
  } = useLocation();

  useEffect(() => {
    if (locationError) {
      setShowTryAgain(true);
      setShowPlacesSearch(true);
    }
  }, [locationError]);

  const next = async (skip = false) => {
    console.log("Skip", skip);
    if (skip) {
      setIsModalOpen(false);
      setIsCigarModalOpen(true);
      setSelectedCigar("non-stoic");
      setCigarCode("");
      return;
    }

    if (cigarCode && cigarCode !== "") {
      setLoader(true);
      try {
        const { is_valid, product } = await api.cigars.validateCode(cigarCode);
        if (is_valid) {
          setIsModalOpen(false);
          setIsCigarModalOpen(true);
          setProduct(product);
          setSelectedCigar(product.name);
          setName(product.name);
          setBrandName(product.brand_name || "Stoic");
          setVitola(product.vitola);
        } else {
          toast.error('Invalid cigar code');
        }
      } catch (error) {
        console.error("Error validating cigar code:", error);
        toast.error('Failed to validate cigar code');
      } finally {
        setLoader(false);
      }
    } else {
      toast.error('Please provide cigar code');
    }
  };

  const back = (close = false) => {
    setIsCigarModalOpen(false);
    if (close) {
      setIsModalOpen(false);
    } else {
      setIsModalOpen(true);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setPicture(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPicturePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleDeletePicture = () => {
    setPicture(null);
    setPicturePreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const addCigar = async (cigarId?: number, location_Id?: number) => {
    console.log("addCigar", cigarId, location_Id);


    if (!cigarId && !cigarCode && step !== 3) {
      if (shouldNotEmpty(name)) {
        toast.error("You have not entered the name");
        return;
      }
      if (shouldNotEmpty(description)) {
        toast.error("You have not entered the description");
        return;
      }
    }

    // first save address and get its id 
    // then save cigar with address id
    let id = null;
    if (step === 3 || cigarId) {
      if (location !== null) {
        if (cigarId == null) {
          const response = await api.rewards.addAddress(location.street, location.city, location.state, location.zip_code, location.country, location.latitude, location.longitude, "cigar");
          id = response.id
        }
          //ipdat ethe location data 
        else {
          if (location_Id == undefined) {
            const response = await api.rewards.addAddress(location.street, location.city, location.state, location.zip_code, location.country, location.latitude, location.longitude, "cigar");
            id = response.id;
          }
          else {
            const response = await api.rewards.updateAddress(location_Id, location.street, location.city, location.state, location.zip_code, location.country, location.latitude, location.longitude, "cigar");
            id = location_Id ? location_Id : response.id;
          }
        }
      }
    }

    const formData = new FormData();
    formData.append("cigar_code", cigarCode);
    formData.append("name", name);
    formData.append("experience_note", experience);
    formData.append("is_location_public", isLocationPublic.toString());
    formData.append("flavour_rating", flavourRating.toString());
    formData.append("aroma_rating", aromaRating.toString());
    formData.append("draw_rating", drawRating.toString());
    formData.append("burn_rating", burnRating.toString());
    if (id) {
      formData.append("location", id.toString());
    }
    formData.append("description", description);
    formData.append("appearance_rating", appearanceRating.toString());
    formData.append("overall_rating", overallRating?.toString() ?? "0");
    formData.append("smoking_time", smokingTime.toString());
    formData.append("smoke_this_again", smokeThisAgain.toString());
    formData.append("recommend_to_friend", recommendToFriend.toString());
    formData.append("buy_in_box", buyInBox.toString());
    formData.append("brand_name", brandName);
    formData.append("vitola", vitola);

    if (picture) {
      formData.append("picture", picture);
    }

    console.log("Journnal", journalImages);
    journalImages.forEach((image, index) => {
      if (image.file) {
        formData.append(`journal_image_${index}`, image.file);
      } else {
        formData.append(`journal_image_ex_${index}`, image.preview);
      }
    });
    setLoader(true);
    try {
      if (!cigarId) {
        const response = await api.cigars.addCigar(formData);
        setCreatedCigar(response);
      } else {
        await api.cigars.updateCigar(cigarId, formData);
      }
      // Reset form
      setCigarCode("");
      setSelectedCigar("");
      setExperience("");
      setIsLocationPublic(false);
      setFlavourRating(0);
      setAromaRating(0);
      setDrawRating(0);
      setBurnRating(0);
      setOverallRating(0);
      setAppearanceRating(0);
      setPicture(null);
      setPicturePreview(null);
      setDescription("");
      if (step === 2) {
        toast.success("Journal Entry added successfully");
      }
      if (step == 3) {
        setIsCigarModalOpen(false);
        setTimeout(() => {
            toast.success("Journal Entry added successfully");
            navigate("/dashboard/journal");
        }, 3000);
      }
      setStep(step + 1);
    } catch (error) {
      console.error("Error adding cigar:", error);
      toast.error("Failed to add cigar");
    } finally {
      setLoader(false);
    }
  };

  const handleJournalImageUpload = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.multiple = true;
    input.onchange = (e: Event) => {
      const files = (e.target as HTMLInputElement).files;
      if (files) {
        const newImages = Array.from(files).map((file) => ({
          file,
          preview: URL.createObjectURL(file),
        }));
        setJournalImages((prev) => [...prev, ...newImages]);
      }
    };
    input.click();
  };

  const handleJournalImageDelete = (index: number) => {
    setJournalImages((prev) => prev.filter((_, i) => i !== index));
  };

  const resetForm = () => {
    // Reset all form fields here
    setCigarCode("");
    setSelectedCigar("");
    setExperience("");
    setIsLocationPublic(false);
    setFlavourRating(0);
    setAromaRating(0);
    setDrawRating(0);
    setBurnRating(0);
    setPicture(null);
    setPicturePreview(null);
    setDescription("");
    // ... reset other fields
  };

  return {
    cigarCode,
    setCigarCode,
    selectedCigar,
    setSelectedCigar,
    experience,
    setExperience,
    isLocationPublic,
    setIsLocationPublic,
    flavourRating,
    setFlavourRating,
    aromaRating,
    setAromaRating,
    drawRating,
    setDrawRating,
    burnRating,
    setBurnRating,
    product,
    setProduct,
    success,
    setSuccess,
    showLocation,
    setShowLocation,
    useCurrentLocation,
    location,
    latitude,
    longitude,
    loader,
    setLoader,
    description,
    setDescription,
    picture,
    setPicture,
    picturePreview,
    setPicturePreview,
    fileInputRef,
    next,
    back,
    handleFileChange,
    handleUploadClick,
    handleDeletePicture,
    addCigar,
    showTryAgain,
    setlocation,
    setLatitude,
    setLongitude,
    successModal,
    setSuccessModal,
    name,
    setName,
    appearanceRating,
    setAppearanceRating,
    overallRating,
    setOverallRating,
    smokingTime,
    setSmokingTime,
    smokeThisAgain,
    setSmokeThisAgain,
    recommendToFriend,
    setRecommendToFriend,
    buyInBox,
    setBuyInBox,
    journalImages,
    setJournalImages,
    handleJournalImageUpload,
    handleJournalImageDelete,
    brandName,
    setBrandName,
    vitola,
    setVitola,
    showPlacesSearch,
    setShowPlacesSearch,
    handlelocationClick,
    setUseCurrentLocation,
    handleHomeLocationClick,
    editingCigarId,
    locationLoading,
    homeLocation,
    handlePlaceSelect,
    createdCigar,
    step,
    setStep,
    placeSearch,
    setPlaceSearch
  };
};

export default useHomeModel;
