import React, { useState, useEffect } from 'react'
import ButtonComp from '../../../components/ButtonComp'
import { Link, useNavigate } from 'react-router-dom'
import AuthLayout from '../../../components/layouts/AuthLayout'
import useAuth from '../../../hooks/useAuth'
import { toast } from 'react-toastify';
import useLocation from '../../../hooks/useLocation';
import PlacesSearchComp from '../../../components/Google/PlacesSearchComp';
import { useUserStore } from '../../../store/userStore';
import useAccount from '../../../hooks/useAccount';

const SetupAccount = () => {
  const user = useUserStore((state: any) => state.user);
  const navigate = useNavigate();
  const { handleSaveChanges, loading } = useAccount();

  const {
    location,
    handlePlaceSelect,
  } = useLocation()

  useEffect(() => {
    if (user.location != null) {
      navigate('/dashboard');
    }
  }, [user]);

  const [age, setAge] = useState(0)

  const handleSubmit = async () => {
    if (!age || age == 0) {
      toast.error('Please accept the terms and conditions.');
      return;
    }
    if (!location) {
      toast.error('Please select your home location.');
      return;
    }
    await handleSaveChanges(location!);
  }

  return (
    <AuthLayout
      title="Setup Account"
      subtitle="Please fill in the form below to setup your account."
    >
      <div className='w-full flex flex-col items-start justify-start gap-4'>
        {
          <>
            <label className='self-start text-sm md:text-base'>Home Location</label>
            <div className='w-full rounded-2xl overflow-hidden'>
              <PlacesSearchComp onPlaceSelect={handlePlaceSelect} />
            </div>
            <div className='w-full overflow-hidden flex items-start'>
              <input type="checkbox" className="mr-2" onChange={(e) => setAge(e.target.checked ? 1 : 0)} />
              <span className="text-left self-start text-sm md:text-base -mt-1">
                I accept the <Link to="https://stoiccigars.com/terms" target="_blank" rel="noopener noreferrer" className="text-secondary-gray font-semibold">Terms and Conditions</Link> and <Link to="https://stoiccigars.com/privacy" target="_blank" rel="noopener noreferrer" className="text-secondary-gray font-semibold">Privacy Policy</Link>.
              </span>
            </div>
            <div className='w-full overflow-hidden flex items-start'>
              <input type="checkbox" className="mr-2" onChange={(e) => setAge(e.target.checked ? 1 : 0)} />
              <span className="text-left self-start text-sm md:text-base -mt-1">
                I acknowledge that I am over 21 years old.
              </span>
            </div>
          </>
        }
        <ButtonComp
          onClick={handleSubmit}
          loading={loading}
        >Save</ButtonComp>
      </div>
    </AuthLayout>
  )
}

export default SetupAccount
