import React from 'react';
import ModalComp from '../../../components/ModalComp';
import HomeModalsType from '../../../types/Home/modal';
import useHomeModel from '../../../hooks/useHomeModal';
import AddCigarModal from '../../../components/Cigar/AddCigarModal';
import AddCode from '../../../components/JournalEntry/AddCode';

const HomeModals: React.FC<HomeModalsType> = ({
  isModalOpen,
  setIsModalOpen,
  isCigarModalOpen,
  setIsCigarModalOpen,
  onSave,
}) => {
  const homeModelProps = useHomeModel(
    isModalOpen,
    setIsModalOpen,
    isCigarModalOpen,
    setIsCigarModalOpen
  );

  const {
    cigarCode,
    setCigarCode,
    loader,
    next,
    addCigar,
    successModal,
    setSuccessModal,
    editingCigarId,
  } = homeModelProps;

  return (
    <>
      <ModalComp
        isOpen={successModal}
        onClose={() => setSuccessModal(false)}
        title='Success'
      >
        <p className='text-white text-center'>
          The cigar is added successfully, redirecting to the journal.
        </p>
      </ModalComp>
      <AddCode
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        cigarCode={cigarCode}
        setCigarCode={setCigarCode}
        loader={loader}
        next={next}
      />

      
      <AddCigarModal
        isOpen={isCigarModalOpen}
        onClose={() => setIsCigarModalOpen(false)}
        cigar={editingCigarId ? { id: editingCigarId } : null}
        onSave={onSave}
        useHomeModel={() => homeModelProps}
      />
    </>
  );
};

export default HomeModals;
