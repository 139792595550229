import { useState } from "react";
import { api } from "../services/api";
import { useUserStore } from "../store/userStore";
import { toast } from 'react-toastify';

const useFollower = (refreshData?: () => void) => {
  const [followers, setFollowers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [following, setFollowing] = useState([]);
  const [selected, setSelected] = useState(0)
  const [selectedUser, setSelectedUser] = useState<any>(null)
  const [cigarSelected, setCigarSelected] = useState(0)
  const [cigar, setCigar] = useState<any>(null)


  const user = useUserStore((state) => state.user);

  const fetchFollowing = async () => {
    try {
      setLoading(true);
      const response = await api.follower.getFollowing();
      setFollowing(response);
      console.log("FOLLOWING", response)

    } catch (error) {
      toast.error("Error fetching following");
    } finally {
      setLoading(false);
    }
  };

  const fetchFollowers = async () => {
    try {
      setLoading(true);
      const response = await api.follower.getFollowers();
      setFollowers(response);

    } catch (error) {
      toast.error("Error fetching followers");
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveFollowing = async (id: number) => {
    try {
      setLoading(true);
      await api.follower.unfollowUser(id);
      setFollowing(following.filter((f: any) => f.id !== id));
      toast.success("User unfollowed successfully");
      if (refreshData) {
       refreshData();
      }
    } catch (error) {
      toast.error("Error unfollowing user");
    } finally {
      setLoading(false);
    }
  };

  const handleAddFollowing = async (id: number) => {
    if (user.id === id) {
      toast.error("You cannot follow yourself");
      return;
    }
    try {
      setLoading(true);
      await api.follower.followUser(id);
      toast.success("User followed successfully");
      if (refreshData) {
        refreshData();
       }
    } catch (error) {
      toast.error("Error following user");
    } finally {
      setLoading(false);
    }
  };

  const onSelectUser = (id: number) => {
    let user: any = following.find((user: any) => user.id === id);
    console.log("User on Selected", user, id, following);
    setSelectedUser(user);
    setSelected(id);
    setCigarSelected(id)
    setCigar(user?.user_cigars)
  };

  const onSelectUserFollower = (id: number) => {
    let user: any = followers.find((user: any) => user.id === id);
    console.log("User on Selected", user, id, followers);
    setSelectedUser(user);
    setSelected(id);
    setCigarSelected(id)
    setCigar(user?.user_cigars)
  };

  return {
    followers,
    following,
    loading,
    fetchFollowing,
    fetchFollowers,
    handleRemoveFollowing,
    handleAddFollowing,
    selected,
    setSelected,
    onSelectUser,
    selectedUser,
    setSelectedUser,
    cigarSelected,
    setCigarSelected,
    cigar,
    setCigar,
    onSelectUserFollower
  };
};

export default useFollower;
