const badgeImages: { [key: string]: string } = {
  "StoicMaster": "stoic master",
  "WiseMaster": "stoic wisdom icon dark blue 2",
  "JustMaster": "stoic justice icon dark blue 2",
  "ModerateMaster": "stoic moderation icon dark blue 2",
  "CourageMaster": "stoic courage icon light blue 2",
  "StoicPhilosopher": "stoic philosopher",
  "StoicApprentice": "stoic icon dark blue 2",
  "Tutor": "tutor icon",
  "Disciple": "disciple",
  "WiseStoic": "stoic wisdom icon light blue",
  "JustStoic": "stoic justice icon light blue",
  "ModerateStoic": "stoic moderation icon light blue",
  "CourageStoic": "stoic courage icon dark blue 2"
};

const badgeTooltips: { [key: string]: { description: string; color: string; score: number, name: string, position: number } } = {
  "StoicMaster": {
    description: "You must log 500 stoic cigars to earn this badge.",
    color: "dark blue filled in",
    score: 3000,
    name: "Stoic Master",
    position: 22,
  },
  "CourageStoic": {
    description: "You must log your first courage stoic cigar to earn this badge.",
    color: "light blue outline",
    score: 45,
    name: "Courageous Stoic",
    position: 1,
  },
  "WiseMaster": {
    description: "You must log 50 wisdom stoic cigars to earn this badge.",
    color: "dark blue filled in",
    score: 250,
    name: "Wise Master",
    position: 23,
  },
  "JustMaster": {
    description: "You must log 50 justice stoic cigars to earn this badge.",
    color: "dark blue filled in",
    score: 250,
    name: "Just Master",
    position: 24,
  },
  "ModerateMaster": {
    description: "You must log 50 moderation stoic cigars to earn this badge.",
    color: "dark blue filled in",
    score: 250,
    name: "Moderate Master",
    position: 25,
  },
  "CourageMaster": {
    description: "You must log 50 courage stoic cigars to earn this badge.",
    color: "dark blue filled in",
    score: 250,
    name: "Courageous Master",
    position: 26,
  },
  "StoicPhilosopher": {
    description: "You must log 100 stoic cigars to earn this badge.",
    color: "dark blue filled in",
    score: 500,
    name: "Stoic Philosopher",
    position: 27,
  },
  "StoicApprentice": {
    description: "You must log 10 stoic cigars to earn this badge.",
    color: "dark blue filled in",
    score: 50,
    name: "Stoic Apprentice",
    position: 9,
  },
  "Tutor": {
    description: "You must receive your first follower to earn this badge.",
    color: "light blue outline",
    score: 50,
    name: "Tutor",
    position: 8,
  },
  "Disciple": {
    description: "You must follow your first member to earn this badge.",
    color: "light blue outline",
    score: 50,
    name: "Disciple",
    position: 7,
  },
  "WiseStoic": {
    description: "You must log your first wisdom stoic cigar to earn this badge.",
    color: "light blue outline",
    score: 55,
    name: "Wise Stoic",
    position: 6,
  },
  "JustStoic": {
    description: "You must log your first justice stoic cigar to earn this badge.",
    color: "light blue outline",
    score: 50,
    name: "Just Stoic",
    position: 5,
  },
  "ModerateStoic": {
    description: "You must log your first moderation stoic cigar to earn this badge.",
    color: "light blue outline",
    score: 45,
    name: "Moderate Stoic",
    position: 4,
  },
  "Create Member Account": {
    description: "You must create a member account to earn this badge.",
    color: "light blue outline",
    score: 50,
    name: "Create Member Account",
    position: 14,
  },
};


export const getBadgeImage = (badge: string) => {
  return badgeImages[badge];
}

export default badgeImages;
export {
  badgeTooltips
}
