import React from 'react'
import { ChevronUp, ChevronDown, Minus } from 'lucide-react'
import ProgressBar from '../../../components/ProgressBar'

interface StoreItemType {
  key: number
  id: number
  name: string
  acquiredOn: string
  points: string
  index: number
  length: number
  picture?: string
}

const HistoryItem: React.FC<StoreItemType> = ({
  key,
  id,
  name,
  acquiredOn,
  points,
  index,
  length,
  picture
}) => {
  return (
    <div key={id} className='rounded-2xl p-4 mb-4 last:mb-0 relative'>
      <div className='flex flex-row md:flex-row items-start md:items-center'>
        <div className='relative'>
          <div
            className={`w-14 h-14 rounded-full flex items-center justify-center`}
          >
            <img src={picture != '' ? picture : "/icons/reward2.svg"} alt={name} className='w-12 h-12' />
          </div>
        </div>
        <div className='flex-grow ml-4 md:ml-4'>
          <div className='flex justify-between mb-2'>
            <h3 className='text-white text-lg font-semibold text-left'>
              {name}
            </h3>
            <button className='text-[#4E5D66] text-sm'>{points}</button>
          </div>
          <p className='text-gray-light text-sm mt-2 text-left'>Aquired on{acquiredOn}</p>
        </div>
      </div>
    </div>
  )
}

export default HistoryItem
