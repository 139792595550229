import React from 'react';
import { useUserStore } from '../../../store/userStore';
import badgeImages, { badgeTooltips } from '../../../utils/badges';
import TooltipComp from '../../../components/TooltipComp';

const UserBadge: React.FC = () => {
  const user = useUserStore((state) => state.user);

  return (
    <>
      <div className="grid grid-cols-3 md:grid-cols-4 gap-2 mt-4 mb-2 w-full">
        {/* Static Badge */}
        <div key={-1} className="flex flex-col items-center">
          <img
            src={`/badges/stoic icon light on dark round.svg`}
            className="w-10 h-10"
            alt="badge"
          />
          <div className="text-center mt-2" style={{ fontSize: '8px' }}>
            Stoic Novice
          </div>
        </div>
        {Object.entries(badgeImages)
          .filter(([badgeKey]) => badgeTooltips[badgeKey])
          .sort(([badgeKeyA], [badgeKeyB]) => {
            const positionA = badgeTooltips[badgeKeyA]?.position || 999;
            const positionB = badgeTooltips[badgeKeyB]?.position || 999;
            return positionA - positionB;
          })
          .map(([badgeKey, badgeImage], index) => {
            const userHasBadge = user?.badges?.includes(badgeKey);
            const tooltip = badgeTooltips[badgeKey];

            return (
              <TooltipComp
                key={index}
                text={
                  tooltip
                    ? `${tooltip.description}`
                    : 'No tooltip available'
                }
              >
                <div className="flex flex-col items-center">
                  <div className={`${userHasBadge ? 'opacity-100' : 'opacity-30'}`}>
                    <img
                      src={`/badges/${badgeImage}.svg`}
                      className="w-10 h-10"
                      alt={`${badgeKey} badge`}
                    />
                  </div>
                  {/* name of badge */}
                  <div className={`text-center mt-2 ${userHasBadge ? 'opacity-100' : 'opacity-30'}`} style={{ fontSize: '8px' }}>
                    {tooltip.name}
                  </div>
                </div>
              </TooltipComp>
            );
          })}
      </div>
    </>
  );
};

export default UserBadge;
