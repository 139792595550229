import React, { useEffect, useMemo } from 'react';
import { Info } from 'lucide-react';
import DashboardLayout from '../../../components/layouts/DashboardLayout';
import InputComp from '../../../components/InputComp';
import TextareaComp from '../../../components/TextareaComp';
import ButtonComp from '../../../components/ButtonComp';
import AccountHeader from '../../../components/Account/AccountHeader';
import InfoSection from '../../../components/Dashboard/InfoSection';
import { formatDate } from '../../../utils/date';
import SpinnerComp from '../../../components/SpinnerComp';
import useAccount from '../../../hooks/useAccount';
import { Locate, X } from 'lucide-react';
import PlacesSearchComp from '../../../components/Google/PlacesSearchComp';
import FormField from '../../../components/Dashboard/Account/FormField';
import useLocation from '../../../hooks/useLocation';
import { useNavigate } from 'react-router-dom';
import { useModalStore } from '../../../store/useModalStore'
import HomeModals from '../Modals/HomeModals'
import { parseAddress } from '../../../utils/parseAddress';
import UserBadge from '../../../components/Dashboard/Account/UserBadge';
import ProfileUserIcon from '../../../assets/profile-user.svg';

const Account = () => {
  const {
    user,
    fileInputRef,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    description,
    setDescription,
    isMemberSinceVisible,
    setIsMemberSinceVisible,
    isCigarsSmokedVisible,
    setIsCigarsSmokedVisible,
    isLocationVisible,
    setIsLocationVisible,
    loading,
    handleProfilePictureChange,
    handleProfilePictureDelete,
    handleSaveChanges,
    handleUploadButtonClick
  } = useAccount();

  const {
    showLocation,
    setShowLocation,
    useCurrentLocation,
    handlelocationClick,
    location,
    locationLoading,
    showPlacesSearch,
    handlePlaceSelect,
    locationError,
    setShowTryAgain,
    setShowPlacesSearch,
    latitude,
    longitude,
    homeLocation
  } = useLocation();
  const { modalStates, updateModalState } = useModalStore();

  const navigate = useNavigate();

  useEffect(() => {
    if (locationError) {
      setShowTryAgain(true);
      setShowPlacesSearch(true);
    }
  }, [locationError]);

  useEffect(() => {
    setIsLocationVisible(showLocation);
  }, [useCurrentLocation]);

  useEffect(() => {
    if (user.privacies.location_visible) {
      setShowLocation(true);
    }
  }, []);


  useEffect(() => {
    setFirstName(user.first_name || '');
    setLastName(user.last_name || '');
    setDescription(user.bio || '');
    setIsMemberSinceVisible(user.privacies.member_since_visible);
    setIsCigarsSmokedVisible(user.privacies.cigars_visible);
    setIsLocationVisible(user.privacies.location_visible);
  }, [user]);

  const handleSave = () => {
    handleSaveChanges(location);
  }

  const hasChanges = useMemo(() => {
    const initialValues = {
      firstName: user.first_name || '',
      lastName: user.last_name || '',
      description: user.bio || '',
      memberSinceVisible: user.privacies.member_since_visible,
      cigarsVisible: user.privacies.cigars_visible,
      locationVisible: user.privacies.location_visible,
      location: user.location
    };

    const currentValues = {
      firstName,
      lastName,
      description,
      memberSinceVisible: isMemberSinceVisible,
      cigarsVisible: isCigarsSmokedVisible,
      locationVisible: isLocationVisible,
      location
    };

    return JSON.stringify(initialValues) !== JSON.stringify(currentValues);
  }, [firstName, lastName, description, isMemberSinceVisible, isCigarsSmokedVisible, isLocationVisible, location, user]);

  return (
    <DashboardLayout title='Account' description=''>
      <div className="mb-12 md:mb-12 text-white rounded-lg pb-16 md:pb-24">
        {/* Add cigar */}
        <AccountHeader
          title="My Account"
          onAddPress={() => updateModalState('isModalOpen', true)}
          menuSelected='account'
        />
        <HomeModals
          {...modalStates}
          setIsModalOpen={(value) => updateModalState('isModalOpen', value)}
          setIsCigarModalOpen={(value) => updateModalState('isCigarModalOpen', value)}
        />
        {hasChanges && (
          <div className='w-full flex justify-end p-4 md:p-6'>
            <div className='w-full md:w-auto md:mr-12'>
              <ButtonComp
                fullWidthOnMobile={true}
                onClick={handleSave}
                loading={loading}
              >
                <span className='text-center'>Save Changes</span>
              </ButtonComp>
            </div>
          </div>
        )}
        <div className="block md:grid md:grid-cols-3 gap-6">
          <FormField label="Your Avatar" description="Update your profile picture">
            <div className="flex items-center space-x-4">
              {loading ? (
                <SpinnerComp />
              ) : (
                <>
                  <img
                    src={user.profile_picture || ProfileUserIcon}
                    alt="Profile"
                    className="w-24 h-24 rounded-full"
                  />
                  <div className="flex space-x-4">
                    <input
                      className="hidden"
                      type="file"
                      ref={fileInputRef}
                      onChange={handleProfilePictureChange}
                    />
                    <button
                      className="text-teal-400 hover:text-teal-300 transition-colors"
                      onClick={handleUploadButtonClick}
                    >
                      Upload {user.profile_picture && 'New'}
                    </button>
                    {user.profile_picture && (
                      <button
                        className="text-red-500 hover:text-red-400 transition-colors"
                        onClick={handleProfilePictureDelete}
                        disabled={!user.profile_picture || loading}
                      >
                        Delete
                      </button>
                    )}
                  </div>
                </>
              )}

            </div>

          </FormField>

          <FormField label="Full Name" description="Update your full name">
            <div className="grid grid-cols-2 gap-4">
              <InputComp
                placeholder="Phoenix"
                value={firstName}
                onChange={setFirstName}
              />
              <InputComp
                placeholder="Baker"
                value={lastName}
                onChange={setLastName}
              />
            </div>
          </FormField>

          <FormField label="Email Address" description="Update your email address">
            <InputComp
              placeholder="email@example.com"
              disabled={true}
              value={email}
              onChange={setEmail}
            />
          </FormField>

          <FormField label="About you" description="Update your bio">
            <TextareaComp
              placeholder="Enter description"
              value={description}
              rows={3}
              onChange={setDescription}
            />
          </FormField>

          <FormField label="Member Since" description="Update your member since date">
            <InfoSection
              icon={<Info size={16} />}
              text={formatDate(user.created_at)}
              isOn={isMemberSinceVisible}
              onToggle={() => setIsMemberSinceVisible(!isMemberSinceVisible)}
            />
          </FormField>

          <FormField label="Cigars Smoked" description="Update your cigars smoked">
            <InfoSection
              icon={<Info size={16} />}
              text={user.cigars}
              isOn={isCigarsSmokedVisible}
              onToggle={() => setIsCigarsSmokedVisible(!isCigarsSmokedVisible)}
            />
          </FormField>

          <FormField label="Show location" description="Update your location visibility">
            <InfoSection
              isOn={showLocation}
              onToggle={() => setShowLocation(!showLocation)}
              icon={<Info size={16} />}
              text="Show Location"
            />
          </FormField>

          {showLocation && (
            <FormField label="Current location" description="Update your current location">
              <InfoSection
                isOn={useCurrentLocation}
                onToggle={handlelocationClick}
                icon={useCurrentLocation ? <Locate /> : <X />}
                text=
                {location
                  ? parseAddress(location)
                  : (locationLoading ? 'Loading...' : "Current Location")}
              />
            </FormField>)}
          {((showPlacesSearch && location == null) || (!homeLocation && location == null) && !locationLoading) && (
            <>
              <FormField label="Place search" description="Search for a place">

                <p className='mt-2 text-red-500 text-sm'>
                  Failed to get current location. Please search for a place.
                </p>
                <PlacesSearchComp onPlaceSelect={handlePlaceSelect} />
              </FormField>
            </>
          )}
          <FormField label="User Badges" description="The Badges you have earned">
            <div className="flex items-center space-y-4 gap-4">
              <UserBadge />
            </div>
          </FormField>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Account;
