import { create } from 'zustand';

interface ModalStates {
    isModalOpen: boolean;
    isCigarModalOpen: boolean;
}

interface ModalState {
    modalStates: ModalStates;
    updateModalState: (key: keyof ModalStates, value: boolean) => void;
}

export const useModalStore = create<ModalState>((set) => ({
    modalStates: {
        isModalOpen: false,
        isCigarModalOpen: false,
    },
    updateModalState: (key, value) => {
        set((state) => ({
            modalStates: {
                ...state.modalStates,
                [key]: value,
            },
        }));
    },
}));
