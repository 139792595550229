import React, { useState, useEffect } from 'react'
import DashboardLayout from '../../components/layouts/DashboardLayout'
import AccountHeader from '../../components/Account/AccountHeader'
import Item from '../../components/Dashboard/Store/Item'
import TabButton from '../../components/Dashboard/Store/TabButton'
import RewardPoints from '../../components/Dashboard/Store/RewardPoints'
import SpinnerComp from '../../components/SpinnerComp'
import InputComp from '../../components/InputComp'
import SelectComp from '../../components/SelectComp'
import ConfirmationModal from '../../components/ConfirmationModalComp'
import RewardHistory from '../../components/Dashboard/Store/RewardHistory';
import useStore from '../../hooks/useStore';
import { useModalStore } from '../../store/useModalStore'
import HomeModals from './Modals/HomeModals'
import EmptyScreenComp from '../../components/EmptyScreenComp';
import states from '../../consts/states';




const Store: React.FC = () => {
  const {
    rewards,
    rewardHistory,
    loading,
    lessPoints,
    currentPage,
    totalPages,
    showModal,
    selectedRewardId,
    tab,
    handleRedeemReward,
    showModalPopup,
    handlePageChange,
    setTab,
    setShowModal,
    setLessPoints,
    user,
    rewardHistoryLoading,
    street,
    addedAddresses,
    showInputAddress,
    setStreet,
    setAddedAddresses,
    setShowInputAddress,
    addressId,
    setAddressId,
    addressRelatedError,
    setAddressRelatedError,
    selected,
    setSelected,
    setCity,
    setCountry,
    setState,
    setZip,
    city,
    country,
    state,
    zip
  } = useStore();

  const { modalStates, updateModalState } = useModalStore();

  const renderTabContent = () => {
    if (tab === 'available') {
      return rewards.map((reward: any, index) => (
        <Item
          key={reward.id}
          {...reward}
          index={index}
          length={rewards.length}
          onRedeem={() => showModalPopup(reward.id)}
          selected={selected}
          onSelect={setSelected}
        />
      ));
    } else {
      return <RewardHistory
        rewardHistory={rewardHistory}
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        loading={loading}
      />
    }
  };
  const getOptions = (): { value: string; label: string; }[] => {
    const options = addedAddresses.map((address: { id: number, address: string }) => ({
      value: address.id.toString(), 
      label: address.address,
    }));

    options.push({
      value: 'showInput',
      label: 'Add new address',
    });

    return options;
  };
  return (

    <DashboardLayout title='Store' description=''>
      <div className='mb-48 md:mb-12'>
        {/* Account header */}
        <AccountHeader
          title='Rewards'
          showNavigation={false}
          onAddPress={() => updateModalState('isModalOpen', true)}
        />
        {/* Add Cigar */}
        <HomeModals
          {...modalStates}
          setIsModalOpen={(value) => updateModalState('isModalOpen', value)}
          setIsCigarModalOpen={(value) => updateModalState('isCigarModalOpen', value)}
        />
        {loading && (
          <SpinnerComp />
        )}


        {/* Confirm model for reward redmptions. */}
        {showModal && !lessPoints && (
          <ConfirmationModal
            title='Redeem Reward'
            message='Enter your shipping address or choose existing address and Redeem your Reward.'
            onConfirm={() => handleRedeemReward(selectedRewardId)}
            onClose={() => setShowModal(false)}
            isOpen={showModal}
            confirmButtonText='Redeem'
            cancelButtonText='Cancel'
          >
            {/* ask for address */}
            <div className='mb-4'>
              {showInputAddress && (
                <>
                  <div className="mt-2">
                    <label className='block text-left text-sm font-medium text-white opacity-70 mb-1'>
                      Street Address
                    </label>
                    <InputComp
                      label='Address'
                      type='text'
                      placeholder='Enter your Street Address'
                      onChange={(value: string) => setStreet(value)}
                    />
                  </div>
                  <div className="mt-2">
                    <label className='block text-left text-sm font-medium text-white opacity-70 mb-1'>
                      City
                    </label>
                    <InputComp
                      label='City'
                      type='text'
                      placeholder='Enter your city'
                      onChange={(value: string) => setCity(value)}
                    />
                  </div>
                  <div className="mt-2">
                    <SelectComp
                      label='State'
                      options={states}
                      value={state}
                      onChange={(value: string) => setState(value)}
                    />
                  </div>

                  <div className="mt-2">
                    <label className='block text-left text-sm font-medium text-white opacity-70 mb-1'>
                      Zip/Postal Code
                    </label>
                    <InputComp
                      label='Zip/Postal Code'
                      type='text'
                      placeholder='Enter your zip/postal code'
                      onChange={(value: string) => setZip(value)}
                    />
                  </div>
                  <div className="mt-2">
                    <label className='block text-left text-sm font-medium text-white opacity-70 mb-1'>
                      Country
                    </label>
                    <InputComp
                      label='Country'
                      type='text'
                      placeholder='Enter your address'
                      value={country}
                      onChange={(value: string) => setCountry(value)}
                      disabled={true}
                    />
                  </div>
                </>
              )}
              <div className='mt-4'>
                <SelectComp
                  label='Existing Addresses'
                  options={getOptions()}
                  value={!showInputAddress ? getOptions()[0].label : 'Add new address'}
                  onChange={(value: string) => {
                    setAddressRelatedError("");
                    if (value === 'showInput') {
                      setShowInputAddress(true);
                      setAddressId(-1);
                    } else {
                      setAddressId(parseInt(value));
                      setShowInputAddress(false);
                    }
                  }
                  }
                />
              </div>
            </div>
          </ConfirmationModal>
        )}

        {/* User Reward points for mobile */}
        <div className='md:hidden mb-2'>
          <RewardPoints current={user.balance} total={user.total_points} />
        </div>

        {/* Tab's in mpbile */}
        <div className='flex flex-row ml-1 mt-4 md:hidden'>
          <TabButton label="Available" isActive={tab === 'available'} onClick={() => setTab('available')} />
          <TabButton label="Redeemed" isActive={tab === 'history'} onClick={() => setTab('history')} />
        </div>

        {!loading && rewards.length === 0 &&
          <div className='col-span-9'>
            <EmptyScreenComp
              title='No rewards available'
              description='Check back later for more rewards'
            />
          </div>}
        {/* Rewards. */}
        {rewards.length > 0 &&
        <div className='grid grid-cols-1 md:grid-cols-12 lg:grid-cols-12 gap-4 mt-4 md:mt-0'>
            {!loading && rewards.length > 0 &&
              <div className='col-span-9 rounded-2xl p-4 mb-4'>
              {renderTabContent()}
            </div>}


          {/* Reward points and history. */}
          {!loading && <div className='hidden md:block col-span-3 bor`der border-primary-blue-90 rounded-2xl mb-4'>
            <RewardPoints current={user.balance} total={user.total_points} />

            {/* Pass rewardHistory and pagination props to RewardHistory */}
            <RewardHistory
              rewardHistory={rewardHistory}
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              loading={loading}
            />
          </div>}
        </div>
        }
      </div>
    </DashboardLayout>
  )
}

export default Store
