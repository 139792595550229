import React, { useEffect, useState, useCallback, useRef } from "react";
import { api } from "../services/api";
import { CigarType } from "../types/cigar";
import { LocationType } from '../utils/parseAddress';
import { toast } from 'react-toastify';

const useCigar = () => {
  const [tab, setTab] = useState("list");
  const [selected, setSelected] = useState(0);
  const [ratings, setRatings] = useState<CigarType[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [locations, setLocations] = useState<LocationType[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState<number | null>(null);
  const [sortBy, setSortBy] = useState("-created_at");

  const loadingRef = useRef(false);

  const fetchRatings = useCallback(async (page: number) => {
    if (loadingRef.current) return;
    loadingRef.current = true;
    setLoading(true);
    setSelected(0);
    try {
      console.log("TAB", tab)
      const is_paginated = tab === "list";
      let response;
      if (is_paginated) {
        setRatings([]);
        response = await api.cigars.listCigars(
          page,
          itemsPerPage,
          sortBy
        );
      }
      else {
        response = await api.cigars.listCigars(
          undefined,
          undefined,
          sortBy
        );
      }
      if (tab === "map") {
        // Extract locations from the cigar data
        const cigarsWithLocation = response
          .filter((cigar: any) => cigar.location)
          .map((cigar: any) => ({ ...cigar }));

        setLocations(cigarsWithLocation);

        setLocations(cigarsWithLocation);
      } else {
        setRatings(response.results);
        console.log("Response FRom journal", response);

        setTotalPages(Math.ceil(response.count / itemsPerPage));
      }
    } catch (error) {
      toast.error("Error fetching ratings " );
      console.log ("Error fetching ratings", error);
    } finally {
      setLoading(false);
      loadingRef.current = false;
    }
  }, [tab, itemsPerPage, sortBy]);

  useEffect(() => {
    fetchRatings(currentPage);
  }, [fetchRatings, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [tab, sortBy]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleDeleteItem = (itemId: number) => {
    setSelected(0);
    setShowModal(true);
    setItemIdToDelete(itemId);
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    try {
      await api.cigars.deleteCigar(itemIdToDelete as number);
      fetchRatings(currentPage);
      toast.success("Journal entry deleted successfully");
    } catch (error) {
      toast.error("Error deleting Journal entry");
      console.log ("Error deleting item", error);
    } finally {
      setShowModal(false);
      setItemIdToDelete(null);
      setLoading(true);
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
    setItemIdToDelete(null);
  };

  const setSort = (sort: string) => {
    setSortBy(`${sort}`);
  };

  const handleMarkerClick = (id: number) => {
    setSelected(id);
  };


  return {
    tab,
    setTab,
    selected,
    ratings,
    loading,
    currentPage,
    totalPages,
    locations,
    showModal,
    onActive: setSelected,
    handlePageChange,
    handleDeleteItem,
    handleConfirmDelete,
    handleCancelDelete,
    setSelected,
    setSort,
    fetchRatings,
    handleMarkerClick
  };
};

export default useCigar;
