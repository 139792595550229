import React from 'react'
import { LucideIcon, Loader2 } from 'lucide-react'

interface ButtonProps {
  backgroundColor?: string
  textColor?: string
  icon?: LucideIcon
  children: React.ReactNode
  onClick?: () => void
  fullWidthOnMobile?: boolean
  loading?: boolean
}

const ButtonComp: React.FC<ButtonProps> = ({
  backgroundColor = 'bg-secondary-gray',
  textColor = 'text-primary-blue-60',
  icon: Icon,
  children,
  onClick,
  fullWidthOnMobile = true,
  loading = false
}) => {
  return (
    <button
      className={`${backgroundColor} ${textColor} border-2 border-secondary-gray rounded-xl font-bold py-2 px-4 rounded inline-flex items-center hover:opacity-80 transition-opacity ${
        fullWidthOnMobile
          ? 'w-full flex justify-center items-center text-center'
          : ''
      }`}
      onClick={onClick}
      disabled={loading}
    >
      {Icon && <Icon className='w-4 h-4 mr-2' />}
      <span className='flex justify-center items-center text-center'>
      {loading ? (
        <span className="flex items-center justify-center">
          <Loader2 className="animate-spin mr-2 h-4 w-4" />
          {children}
        </span>
      ) : (
        children
      )}
      </span>{' '}
    </button>
  )
}

export default ButtonComp
