import { useState, useRef, useEffect } from "react";
import { api } from "../services/api";
import { useUserStore } from "../store/userStore";
import { getErrorMessage } from "../utils/error";
import { toast } from 'react-toastify';
import { Address } from "../types/address";
const useAccount = () => {
    // Account page.
    const fileInputRef = useRef<HTMLInputElement>(null);
    const user = useUserStore((state) => state.user);
    const setUser = useUserStore((state) => state.setUser);
    const [firstName, setFirstName] = useState(user.first_name);
    const [lastName, setLastName] = useState(user.last_name);
    const [email, setEmail] = useState(user.email);
    const [description, setDescription] = useState(user.bio);
    const [isEmailPublic, setIsEmailPublic] = useState(user.isEmailPublic);
    const [isMemberSinceVisible, setIsMemberSinceVisible] = useState(
      user.privacies.member_since_visible
    );
    const [isCigarsSmokedVisible, setIsCigarsSmokedVisible] = useState(
      user.privacies.cigars_smoked_visible
    );
    const [isLocationVisible, setIsLocationVisible] = useState(
      user.privacies.location_visible
    );
    const [loading, setLoading] = useState(false);
    const [profilePicture, setProfilePicture] = useState<File | null>(null);
    
    // Account Passwords.
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
  
    // Account Password Function.
    const handlePasswordChange = async () => {
    
        // password length must be 6
        if (newPassword.length < 6) {
          toast.error('Password must be at least 6 characters long.');
          return;
        }
        if (currentPassword.length < 6) {
          toast.error('Current password must be at least 6 characters long.');
          return;
        }
        if (newPassword !== repeatPassword) {
          toast.error('New password and repeat password do not match.');
          return;
        }
    
        try {
          setLoading(true);
          const data = {
            old_password: currentPassword,
            new_password: newPassword,
          };
    
          await api.profile.updatePassword(data);
          setCurrentPassword('');
          setNewPassword('');
          setRepeatPassword('');
          toast.success('Password updated successfully.');
        } catch (error) {
          toast.error(getErrorMessage(error));
        } finally {
          setLoading(false);
        }
      };

    // Account Page functions.
    const handleProfilePictureChange = async (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      if (e.target.files && e.target.files.length > 0) {
        const selectedFile = e.target.files[0];

  
        try {
          setLoading(true);

          const croppedBlob = await autoCropImage(selectedFile, 300);

          const croppedFile = new File([croppedBlob], selectedFile.name, {
            type: 'image/jpeg',
          });

          setProfilePicture(croppedFile);

          const formData = new FormData();
          formData.append('profile_picture', croppedFile);
  
          const response = await api.profile.updateProfilePicture(formData);
          user.profile_picture = response.profile_picture;
          console.log ("User", user)
          setUser(user);
          toast.success('Profile picture updated successfully.');
        } catch (error) {
          toast.error(getErrorMessage(error));
        } finally {
          setLoading(false);
          setProfilePicture(null);
        }
      }
    };
  
    const handleProfilePictureDelete = async () => {
      try {
        setLoading(true);
  
        await api.profile.deleteProfilePicture();
        setUser({ ...user, profile_picture: null });
        toast.success('Profile picture deleted successfully.');
      } catch (error) {
        toast.error(getErrorMessage(error));
      } finally {
        setLoading(false);
        setProfilePicture(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }  
      }
    };
  
  const handleSaveChanges = async (location?: Address | null) => {
      try {
        setLoading(true);
        console.log("Current location on Save", location)
        const location_ = {
          street: location?.street,
          city: location?.city,
          state: location?.state,
          zip_code: location?.zip_code,
          country: location?.country,
          latitude: location?.latitude,
          longitude: location?.longitude,
          type: "user",
        }

        const updatedData = {
          first_name: firstName,
          last_name: lastName,
          bio: description,
          privacies: {
            location_visible: isLocationVisible,
            cigars_smoked_visible: isCigarsSmokedVisible,
            remember_since_visible: isMemberSinceVisible,
          },
          location: location ? location_ : null
        };
  
        const response = await api.auth.update(updatedData);
        setUser(response);
        toast.success('Profile updated successfully.');
      } catch (error) {
        toast.error(getErrorMessage(error));
      } finally {
        setLoading(false);
      }
    };
  
    const handleUploadButtonClick = () => {
      fileInputRef.current?.click();
    };


  const autoCropImage = (file: File, size: number = 300): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = size;
        canvas.height = size;
        const ctx = canvas.getContext('2d');
        const aspectRatio = img.width / img.height;
        let sx: number, sy: number, sWidth: number, sHeight: number;

        if (aspectRatio > 1) {
          sWidth = img.height;
          sHeight = img.height;
          sx = (img.width - img.height) / 2;
          sy = 0;
        } else {
          sWidth = img.width;
          sHeight = img.width;
          sx = 0;
          sy = (img.height - img.width) / 2;
        }

        ctx?.drawImage(img, sx, sy, sWidth, sHeight, 0, 0, size, size);

        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Failed to create blob from canvas'));
          }
        }, 'image/jpeg');
      };
      img.onerror = (error) => reject(error);
    });
  };




  return {
    user,
    fileInputRef,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    description,
    setDescription,
    isEmailPublic,
    setIsEmailPublic,
    isMemberSinceVisible,
    setIsMemberSinceVisible,
    isCigarsSmokedVisible,
    setIsCigarsSmokedVisible,
    isLocationVisible,
    setIsLocationVisible,
    loading,
    setLoading,
    profilePicture,
    setProfilePicture,
    handleProfilePictureChange,
    handleProfilePictureDelete,
    handleSaveChanges,
    handleUploadButtonClick,

    // Account password.
    currentPassword,
    setCurrentPassword,
    newPassword,
    setNewPassword,
    repeatPassword,
    setRepeatPassword,
    handlePasswordChange,
  };
};

export default useAccount;
