import React from 'react'

type TextareaProps = {
  value: string
  onChange: (value: string) => void
  placeholder?: string
  rows?: number
} & Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'>

const TextareaComp: React.FC<TextareaProps> = ({
  value,
  onChange,
  placeholder,
  rows = 4,
  ...props
}) => {
  return (
    <div className='relative'>
      <textarea
        className='w-full bg-primary-blue-90 border-none outline-none bg-primary-blue-90 rounded-2xl flex flex-col items-start justify-start py-3 px-5 box-border font-text-md-medium text-sm text-white resize-none'
        placeholder={placeholder}
        value={value}
        onChange={e => onChange(e.target.value)}
        rows={rows}
        {...props}
      />
    </div>
  )
}

export default TextareaComp
