import React, { useState } from 'react';
import ButtonComp from '../../components/ButtonComp';
import InputComp from '../../components/InputComp';
import { Link } from 'react-router-dom';
import AuthLayout from '../../components/layouts/AuthLayout';
import useAuth from '../../hooks/useAuth';
import { toast } from 'react-toastify';

const PasswordReset = () => {
  const {
    sendResetPasswordCode,
    validateResetPasswordCode,
    resetPassword,
    loading,
    resetCodeSent,
    codeValid,
    step,
  } = useAuth();

  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleEmailSubmit = async () => {
    await sendResetPasswordCode(email);
  };

  const handleCodeSubmit = async () => {
    await validateResetPasswordCode({ email, code });
  };

  const handlePasswordSubmit = async () => {
    if (newPassword !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    try {
      await resetPassword({ email, code, new_password: newPassword });
    } catch (err: any) {
      toast.error(err.message || 'Failed to reset password');
    }
  };

  return (
    <AuthLayout
      title="Reset your password"
      subtitle="Forgotten password?"
      description=""
      bottomLink={
        <>
          Back to{' '}
          <Link to="/login" className="text-secondary-gray font-semibold">
            Login
          </Link>
        </>
      }
    >
    <div className='w-full flex flex-col items-start justify-start gap-4'>
        {step === 1 &&  (
          <>
            <label className="self-start text-sm md:text-base">
              Email Address
            </label>
            <div className="w-full rounded-2xl overflow-hidden">
              <InputComp
                type="email"
                value={email}
                onChange={setEmail}
                placeholder="Enter your email"
                required
              />
            </div>
            <ButtonComp loading={loading} onClick={handleEmailSubmit}>
              Send Reset Code
            </ButtonComp>
          </>
        )}

        {step === 2 && (
          <>
            <label className="self-start text-sm md:text-base">
              Reset Code
            </label>
            <div className="w-full rounded-2xl overflow-hidden">
              <InputComp
                type="text"
                value={code}
                onChange={setCode}
                placeholder="Enter reset code"
                required
              />
            </div>
            <ButtonComp loading={loading} onClick={handleCodeSubmit}>
              Validate Code
            </ButtonComp>
          </>
        )}

        {step === 3 && codeValid && (
          <>
            <label className="self-start text-sm md:text-base">
              New Password
            </label>
            <div className="w-full rounded-2xl overflow-hidden">
              <InputComp
                type="password"
                value={newPassword}
                onChange={setNewPassword}
                placeholder="New password"
                required
              />
            </div>
            <label className="self-start text-sm md:text-base">
              Confirm Password
            </label>
            <div className="w-full rounded-2xl overflow-hidden">
              <InputComp
                type="password"
                value={confirmPassword}
                onChange={setConfirmPassword}
                placeholder="Confirm password"
                required
              />
            </div>
            <ButtonComp loading={loading} onClick={handlePasswordSubmit}>
              Reset Password
            </ButtonComp>
          </>
        )}
      </div>
    </AuthLayout>
  );
};

export default PasswordReset;
