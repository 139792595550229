import React from 'react';
import { ChevronUp, ChevronDown, Minus } from 'lucide-react';
import ProgressBar from '../../components/ProgressBar';
import { parseAddress } from '../../utils/parseAddress';
import ProfileIcon from '../../assets/profile-user.svg'

interface UserItemProps {
  name: string;
  cigars: number;
  location: string;
  progress: number;
  // arrow: 'up' | 'down' | 'straight';
  value: number;
  valueLabel?: string;
  picture: string,
  onClick?: any,
}

const UserItem: React.FC<UserItemProps> = ({
  name,
  cigars,
  location,
  progress,
  //arrow,
  value,
  valueLabel,
  picture,
  onClick
}) => {
  return (
    <div className='flex items-center justify-between cursor-pointer' onClick={onClick}>
      <div className='flex items-center space-x-2 w-1/5'>
        <img src={picture ?? ProfileIcon} className='w-8 h-8 rounded-full' />
      </div>
      <div className='flex flex-col w-4/5'>
        <p className='font-semibold text-left'>{name}</p>
        <ProgressBar percentage={progress} />
        <p className='text-xs text-gray-400 text-left'>
          {cigars} Cigars • {parseAddress(location)}
        </p>
      </div>
      <div
        className='flex items-center justify-end w-1/5'
        style={{ minWidth: '60px' }}
      >
        {/* {arrow === 'up' && (
          <ChevronUp size={18} className='text-green-500' />
        )}
        {arrow === 'down' && (
          <ChevronDown size={18} className='text-red-500' />
        )}
        {arrow === 'straight' && (
          <Minus size={18} className='text-gray-400' />
        )} */}
        <p className='font-semibold ml-2'>{value}{valueLabel}</p>
      </div>
    </div>
  );
};

export default UserItem;
