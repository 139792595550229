import React from 'react';
import { Cigarette, X, MapPin } from 'lucide-react';
import { parseAddress } from '../../../utils/parseAddress';
import ButtonComp from '../../ButtonComp';
import ProfileUserIcon from '../../../assets/profile-user.svg';

interface FollowerItemProps {
  id: number;
  first_name: string;
  last_name: string;
  profile_picture?: string | null;
  location?: string;
  cigars?: number;
  showRemoveButton?: boolean;
  onRemove?: (id: number) => void;
  onCigarClick?: (id: number) => void;
  onFollow?: (id: number) => void;
  onUnfollow?: (id: number) => void;
  allMembersPage?: boolean;
  isFollower?: boolean;
  onClick?: (id: number) => void;
  loading?: boolean;
}

const FollowerItem: React.FC<FollowerItemProps> = ({
  id,
  first_name,
  last_name,
  profile_picture,
  location,
  cigars,
  showRemoveButton = false,
  onRemove,
  onCigarClick,
  onFollow,
  onUnfollow,
  allMembersPage,
  isFollower,
  onClick,
  loading,
}) => {
  return (
    <div className="w-full px-4 py-4">
      <div className="grid grid-cols-12 items-center gap-6">
        {/* Avatar */}
        <div
          className="col-span-1 cursor-pointer"
        >
          <img
            src={profile_picture ?? ProfileUserIcon}
            className="w-10 h-10 rounded-full"
            alt="Profile"
          />
        </div>

        {/* Name */}
        <div className="col-span-2">
          <span
            className={`font-medium text-base leading-6 text-white
              ${onClick ? 'cursor-pointer' : ''}
            `}
            onClick={() => onClick && onClick(id)}
          >
            {first_name} {last_name}
          </span>
        </div>

        {/* Empty Columns for Spacing */}
        <div className="col-span-2"></div>

        {/* Location */}
        <div className="col-span-2">
          <div className="flex items-center text-base leading-6 text-gray-400">
            <MapPin size={16} className=" mr-2 flex-shrink-0" />
            <span className="truncate">
              {parseAddress(location) || 'Unknown Location'}
            </span>
          </div>
        </div>

        {/* Cigars */}
        <div className="col-span-2">
          <div
            className={`flex items-center rounded-full px-3 py-1 bg-gray-700 ${onCigarClick ? 'cursor-pointer' : ''
              }`}
            onClick={(e) => {
              e.stopPropagation();
              onCigarClick?.(id);
            }}
          >
            <Cigarette size={16} className="hidden md:block mr-2 flex-shrink-0" />
            <span className="text-base leading-6 text-gray-400">
              {cigars} Cigars
            </span>
          </div>
        </div>

        {/* Buttons */}
        <div className="col-span-3 flex items-center justify-end space-x-4">
          {showRemoveButton && (
            <X
              className="w-5 h-5 text-red-500 cursor-pointer"
              onClick={() => onRemove && onRemove(id)}
            />
          )}
          {allMembersPage && (
            <div className="w-24">
              <ButtonComp
                onClick={() =>
                  isFollower ? onUnfollow?.(id) : onFollow?.(id)
                }
                loading={loading}
              >
                {isFollower ? 'Unfollow' : 'Follow'}
              </ButtonComp>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FollowerItem;
