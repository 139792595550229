import React, { useState } from 'react'
import { Trash, MessageSquareQuote } from 'lucide-react'
import RatingComp from '../RatingComp'
import { formatDate } from '../../utils/date'
import { parseAddress } from '../../utils/parseAddress'
import CigarIcon from "../../assets/cigaricon.svg";

interface CigarItemType {
  id: number
  product_name: string
  count: number
  location: string
  created_at: string
  rating: number
  product_picture: string
  index: number
  length: number
  selected: number
  onActive?: any
}

const CigarItem: React.FC<CigarItemType> = ({
  id,
  product_name,
  count,
  location,
  created_at,
  rating,
  product_picture,
  index,
  length,
  selected,
  onActive
}) => {
  const [currentRating, setCurrentRating] = useState(rating)
  const [active, setActive] = useState(false)
  const handleRatingChange = (newRating: any) => {
    setCurrentRating(newRating)
  }
  const isShow = selected === id
  return (
    <div
      key={id}
      className={`cursor-pointer rounded-2xl p-4 mb-4 last:mb-0 relative ${
        isShow ? 'bg-primary-blue-90' : ''
      }`}
      onClick={() => onActive(id)}
    >
      {' '}
      <div className='flex flex-row md:flex-row items-start md:items-center'>
        <div className='relative'>
          <div
            className={`w-14 h-14 rounded-full flex items-center justify-center`}
          >
            {product_picture !== '' ? (
              <img src={product_picture} alt={product_name} className='w-16 h-16' />
            ) : (
                <img src={CigarIcon} alt={product_name} className="w-12 h-12 text-red" />
            )
            }
          </div>
        </div>
        <div className='flex-grow ml-4 md:ml-4'>
          <div className='flex justify-between mb-2'>
            <h3 className='!text-white text-lg font-semibold text-left'>
              {product_name}
            </h3>
            <p className='!text-[#4E5D66] text-sm'>{count}</p>
          </div>
          <p className='!text-gray-light text-sm text-left'>
            {formatDate(created_at)} - {parseAddress(location)}
          </p>
          <div className='mt-4 md:mt-0'>
              <RatingComp disabled={true} defaultRating={rating} />
            </div>
        </div>
      </div>
    </div>
  )
}

export default CigarItem
